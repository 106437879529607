import { useMemo } from 'react';
import { Col, Row, Typography, Divider, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Route as IRoute } from 'utils/flight';
import { normalizeCountry } from 'utils/countries';

import './Route.css';

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(localizedFormat);

export interface RouteProps {
    route: IRoute | IRoute[];
    flightDate: string;
    mode: 'dark' | 'white';
    showTime?: boolean;
    type?: string,
}

export default function Route({ route, flightDate, mode, showTime = false, type }: RouteProps) {
    const { t, i18n } = useTranslation();
    dayjs.locale(i18n.language);

    const isTransit = Array.isArray(route) && route.length > 1;

    const durationFlight = (date: string, departure: string, arrival: string) => {
        const a = dayjs(`${dayjs(date).format('YYYY-MM-DD')}T${departure}`);
        const b = dayjs(`${dayjs(date).format('YYYY-MM-DD')}T${arrival}`);
        const diff = dayjs.duration(b.diff(a)).asMinutes();
        return dayjs.duration(diff, 'minutes').format(`H [${t('hrs')}] mm [${t('min')}]`);
    }

    const { origin, destination, origin_name, destination_name, flight_number, departure_time, arrival_time, duration } = useMemo(() => {
        if (!route) {
            return {
                origin: t('na'), destination: t('na'), origin_name: t('na'), destination_name: t('na'), flight_number: t('na')
            };
        }

        if (isTransit) {
            return ({
                origin: route[0].origin,
                destination: route[route.length - 1].destination,
                origin_name: route[0].origin_name,
                destination_name: route[route.length - 1].destination_name,
                flight_number: `${route.map((item) => item.flight_number).join(', ')}`,
                departure_time: route[0].flight_departure_time,
                arrival_time: route[route.length - 1].flight_arrival_time,
                duration: durationFlight(flightDate, route[0].flight_departure_time as string, route[route.length - 1].flight_arrival_time as string),
            });
        } else {
            return {
                origin: (route as IRoute).origin,
                destination: (route as IRoute).destination,
                origin_name: (route as IRoute).origin_name,
                destination_name: (route as IRoute).destination_name,
                flight_number: (route as IRoute).flight_number,
                departure_time: (route as IRoute).flight_departure_time,
                arrival_time: (route as IRoute).flight_arrival_time,
                duration: durationFlight(flightDate, (route as IRoute).flight_departure_time as string, (route as IRoute).flight_arrival_time as string),
            };
        }
    }, [flightDate, isTransit, route, t]);

    return (
        <Row className={`Route ${mode}`}>
            <Col className='Route-diraction' span={24}>
                <Row>
                    <Col md={4} xs={24}>
                        <Typography>{t('SearchFlight.from')}</Typography>
                        <Row align="middle" gutter={10}>
                            <Col>
                                <Typography.Title>{origin}</Typography.Title>
                            </Col>
                            <Col>
                                {normalizeCountry(origin) !== 'N/a' && (
                                    <Typography.Text className='Text' ellipsis>
                                        {normalizeCountry(origin)}
                                    </Typography.Text>
                                )}
                                <Typography className='Text'>
                                    {origin_name}
                                </Typography>
                                {showTime && (
                                    <Typography className='Text'>
                                        {departure_time}
                                    </Typography>
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12} xs={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '15px 0' }}>
                        {isTransit ? (
                            route.length - 1 > 0 ? (
                                <Divider className='Route-divider' style={{ borderColor: '#c1c1c1', margin: 0 }}>
                                    <Flex vertical>
                                        <span>{route.length - 1}</span>
                                        <span>{t('PassengerForm.stops')}</span>
                                    </Flex>
                                </Divider>
                            ) : (
                                <Flex vertical>
                                    <span className="Route-icon" />
                                    <span>{t('PassengerForm.noStops')}</span>
                                </Flex>
                            )
                        ) : (
                            <Flex vertical>
                                <span className="Route-icon" />
                                <span style={{ fontSize: 16, marginTop: 10, marginLeft: -35 }}>{t('PassengerForm.noStops')}</span>
                            </Flex>
                        )}
                    </Col>
                    <Col md={4} xs={24}>
                        <Typography>{t('SearchFlight.to')}</Typography>
                        <Row align="middle" gutter={10}>
                            <Col>
                                <Typography.Title>{destination}</Typography.Title>
                            </Col>
                            <Col>
                                {normalizeCountry(destination) !== 'N/a' && (
                                    <Typography.Text className='Text' ellipsis>
                                        {normalizeCountry(destination)}
                                    </Typography.Text>
                                )}
                                <Typography className='Text'>
                                    {destination_name}
                                </Typography>
                                {showTime && (
                                    <Typography className='Text'>
                                        {arrival_time}
                                    </Typography>
                                )}
                            </Col>
                        </Row>
                    </Col>
                    {showTime && (
                        <Col md={4} xs={24}>
                            <Typography>{t('SearchFlight.onTheWay')}</Typography>
                            <Typography className='Text'>
                                {duration}
                            </Typography>
                        </Col>
                    )}
                </Row>
            </Col>
            <Col span={24} className='Route-date'>
                <Row>
                    <Col span={12}>
                        <Typography>{type && type === 'out' ? t('SearchFlight.outboundFlight') : t('SearchFlight.inboundFlight')}</Typography>
                        <Typography className='Text'>
                            {dayjs(flightDate).format('dddd, MMMM D, YYYY')}
                        </Typography>
                    </Col>
                    <Col span={12}>
                        <Typography>{isTransit ? t('PassengerForm.flightNumbers') : t('PassengerForm.flightNumber')}</Typography>
                        <Typography style={{ fontSize: 16, fontWeight: 'bold', textTransform: 'uppercase' }}>
                            {flight_number}
                        </Typography>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
