import dayjs, { Dayjs } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { Flex, theme, Typography } from 'antd';
import { useContextSelector } from 'use-context-selector';
import { useTranslation } from 'react-i18next';
import InitBookingScreen from 'screens/InitBookingScreen/index';
import { AppContext, AppContextType } from 'AppContext';
import MonthsSlider from 'components/MonthsSlider';
import useMonths from 'hooks/useMonths';
import BoardingPassDriver from "components/BoardingPassDriver";
import { STEPS } from 'utils/utilits';
import './LaunchScreen.css';

dayjs.extend(isSameOrBefore);

const { useToken } = theme;

function LaunchScreen() {
    const { t } = useTranslation();
    const { token } = useToken();
    const months: Dayjs[] = useMonths();
    const showCalendar = useContextSelector(AppContext, (c: AppContextType) => c.booking.showCalendar);

    return (
        <div className="LaunchScreen">
            <Flex
                align='start'
                style={{
                    background: token.colorPrimary,
                    width: '100%',
                    minHeight: '180px'
                }}
            >
                <InitBookingScreen />
            </Flex>
            {
                showCalendar ? (
                    <Flex vertical style={{ maxWidth: '950px', width: '100%', margin: '0 auto', marginTop: 20 }}>
                        <Typography.Title level={3}>{t('LaunchScreen.departureDate')}</Typography.Title>
                        <MonthsSlider months={months} />
                    </Flex>
                ) : null
            }
            <BoardingPassDriver nextStep="/outbound" step={STEPS.launch} />
        </div>
    );
}

export default LaunchScreen;
