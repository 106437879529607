import { useCallback, useMemo } from 'react';
import { Row, Col, Typography, Flex, Button } from 'antd';
import { useContextSelector } from 'use-context-selector';
import { CaretRightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppContext, AppContextType } from 'AppContext';
import { Route as IRoute } from 'utils/flight';
import { Pax } from "../../utils/booking";
import './FlightInformation.css';
import Route from './components';
import { Dayjs } from "dayjs";

export interface FlightInformationProps {
  routes: IRoute[];
  originCountryName?: string;
  destinationCountryName?: string;
  date: string | number | Dayjs | null;
  counts?: {
    adults: number;
    childs: number;
  };
  passengers?: Record<'adults' | 'childs' | 'infants', Pax[]>;
  mode?: 'dark' | 'white';
  showNavigation?: boolean;
  showTime?: boolean;
  type?: string;
}

export default function FlightInformation({
  routes,
  originCountryName,
  destinationCountryName,
  date,
  counts,
  passengers,
  mode = 'white',
  showNavigation = true,
  showTime = false,
  type = 'out',
}: FlightInformationProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const setDate = useContextSelector(AppContext, (c: AppContextType) => c.booking.setDate);

  const handleBack = useCallback(() => {
    navigate('/');
  }, [setDate]); //eslint-disable-line

  const passengersInfo = useMemo(() => {
    if (passengers?.adults?.[0]?.type || passengers?.childs?.[0]?.type || passengers?.infants?.[0]?.type) {
      return [passengers.adults, passengers.childs, passengers.infants].map((passengers, idx) => {
        const list = passengers.map((passenger, jdx) => {
          return (
            <Row key={idx + jdx + passenger.type} gutter={5}>
              <Col>
                <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>{passenger.seat_number}</Typography>
              </Col>
              <Col>
                <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>{passenger.price}</Typography>
              </Col>
            </Row>
          )
        });
        return (<Row key={idx} gutter={5}>{list}</Row>);
      });
    } else {
      return Object.entries(counts || {}).filter(([, value]) => value > 0).map((count, idx) => (
        <Row key={idx} gutter={5}>
          <Col>
            <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>{count[1]}</Typography>
          </Col>
          <Col>
            <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>{count[0]}</Typography>
          </Col>
        </Row>
      ));
    }
  },
    [counts, passengers]
  );

  return (
    <>
      {
        mode === 'white' && (
          <Flex justify='space-between' align='center'>
            <Typography.Title level={5} style={{ marginBottom: 0 }}>{t('travelData')}</Typography.Title>
            {showNavigation && <Button onClick={handleBack} type='link'>{<CaretRightOutlined />}{t('changeTravelData')}</Button>}
          </Flex>
        )
      }
      <div className={`FlightInformation ${mode}`}>
        <div className='FlightInformation-routes'>
          {!!routes?.length && <Route showTime={showTime} flightDate={date as string} route={routes.length > 1 ? routes : routes[0]} mode={mode} type={type} />}
        </div>
        <Row style={{ paddingLeft: 15, borderLeft: `1px solid ${mode === 'dark' ? '#c1c1c1' : '#FFF'}` }}>
          <Col span={24}>
            <Typography>{t('SearchFlight.passengers')}</Typography>
            <div>{passengersInfo}</div>
          </Col>
        </Row>
      </div>
    </>
  );
}