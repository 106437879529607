import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { LogoutOutlined } from '@ant-design/icons';
import { useContextSelector } from 'use-context-selector';
import { AppContext, AppContextType } from 'AppContext';


function LogoutButton({ isMobile }: { isMobile: boolean }) {
    const { t } = useTranslation()
    const { onLogout, token } = useContextSelector(AppContext, (c: AppContextType) => c)

    if (!token) {
        return null;
    }

    return (
        <Button
            icon={<LogoutOutlined />}
            type="primary"
            size="small"
            onClick={onLogout}
        >
            {isMobile ? t('logout') : ''}
        </Button>
    )
}

export default LogoutButton;
