import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography, Flex, Button, Row, Col, Form, Input, notification } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { useContextSelector } from 'use-context-selector';
import { AppContext, AppContextType } from 'AppContext';
import './SearchBooking.css';
import Portal from 'components/Portal';
import { IDS } from 'helpers/constants';
import planeLoader from 'assets/images/plane-loader.gif';
import { useSearchParams } from 'react-router-dom';
import config from 'config';

const NOTIFICATION_KEY = 'booking_notif';

export default function SearchBooking() {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [searchParams] = useSearchParams();
    const [api, contextHolder] = notification.useNotification();
    const { key, provider } = useContextSelector(AppContext, (c: AppContextType) => c.configAirline);
    const { searchBookingsInCheckin } = useContextSelector(AppContext, (c: AppContextType) => c.booking);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!key && !provider) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [key, provider])

    const { airline, flightPrefix } = useMemo(() => {
        const origin = window.location.origin
        if (origin === config.EASY_JET_DOMAIN) {
            return {
                airline: 'EasyJet',
                flightPrefix: 'EZY'
            }
        } else {
            return {
                airline: 'Edelweiss',
                flightPrefix: 'LX'
            }
        }
    }, []);

    const handleSubmit = useCallback(() => {
        form
            .validateFields()
            .then((values) => {
                console.log('[SearchBooking] form values', values);
                setLoading(true);
                searchBookingsInCheckin(values.pnl, values.last_name)
                    .then((res) => {
                        console.log('[SearchBooking] search result', res)
                        setLoading(false);
                    })
                    .catch((err) => {
                        console.log('[SearchBooking] search error', err)
                        setLoading(false);
                        if (err.message === 'SESSION_NOT_FOUND') {
                            api.warning({
                                key: NOTIFICATION_KEY,
                                message: t('warning'),
                                description: t(err.message),
                                placement: 'topRight',
                            });
                        } else {
                            api.error({
                                key: NOTIFICATION_KEY,
                                message: t('error'),
                                description: t([err.message, 'error_oops']),
                                placement: 'topRight',
                            });
                        }
                    })
            })
    }, [form]) //eslint-disable-line

    useEffect(() => {
        const lastName = searchParams.get('ln');
        const pnl = searchParams.get('pnl');
        if (lastName && pnl) {
            form.setFieldValue('last_name', lastName);
            form.setFieldValue('pnl', pnl)
            handleSubmit();
        }
    }, []);

    return (
        <>
            <Flex className='SearchBooking' vertical>
                <Flex vertical>
                    <Flex className='SearchBooking-Header'>
                        <Typography.Title>{t('MyBookingScreen.myBooking')}</Typography.Title>
                    </Flex>
                    <Flex vertical>
                        <Typography.Paragraph className='SearchBooking-Desc'>
                            <Trans
                                i18nKey='MyBookingScreen.bookedDescription'
                                values={{ host: window.location.host }}
                                components={{ bold: <strong /> }}
                            />
                        </Typography.Paragraph>
                        <Typography.Paragraph className='SearchBooking-Desc'>
                            <Trans
                                i18nKey='MyBookingScreen.bookedAfterDate'
                                values={{ host: window.location.host, airline }}
                                components={{ bold: <strong />, CustomLink: <a href='' onClick={(e) => e.preventDefault()} /> }}
                            />
                        </Typography.Paragraph>
                        <Typography.Paragraph className='SearchBooking-Desc'>
                            {t('MyBookingScreen.faqDescriptionLink')}
                        </Typography.Paragraph>
                        <Button onClick={() => { }} type='link' className='SearchBooking-Link'>
                            {<CaretRightOutlined />}{t('MyBookingScreen.faqLink')}
                        </Button>
                        <Typography.Paragraph className='SearchBooking-Desc'>
                            {t('MyBookingScreen.bookedSwiss', { flight_prefix: flightPrefix })}
                        </Typography.Paragraph>
                    </Flex>
                </Flex>
            </Flex>
            <Flex className='SearchBooking-Form-Container'>
                <Row gutter={[16, 16]} style={{ flex: 1 }} justify='center'>
                    <Col md={12} sm={24}>
                        <Flex className='SearchBooking-Form' vertical>
                            <Typography.Title level={3}>
                                {t('MyBookingScreen.viewMyBooking')}
                            </Typography.Title>
                            <Typography.Paragraph className='SearchBooking-Desc'>
                                {t('MyBookingScreen.viewMyBookingDesc')}
                            </Typography.Paragraph>
                            <Form
                                form={form}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                style={{ width: '100%' }}
                                onFinish={handleSubmit}
                            >
                                <Form.Item
                                    label={t('MyBookingScreen.bookingReference')}
                                    name="pnl"
                                    rules={[{ required: true, message: t('MyBookingScreen.inputReference') }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={t('MyBookingScreen.lastName')}
                                    name="last_name"
                                    rules={[{ required: true, message: t('MyBookingScreen.inputLastName') }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" size='large'>
                                        {t('MyBookingScreen.viewBooking')}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Flex>
                    </Col>
                    <Col md={12} sm={24} style={{ height: '100%' }}>
                        <Flex className='SearchBooking-Tips' vertical align='center' justify='flex-end'>
                            <Typography.Title level={2} style={{ color: '#FFF', textAlign: 'center' }}>
                                {t('MyBookingScreen.holidays')}
                            </Typography.Title>
                            <Typography.Paragraph style={{ color: '#FFF', fontSize: 17 }} className='SearchBooking-Desc'>
                                {t('MyBookingScreen.edelweissTips', {airline: provider === '94@' ? 'EasyJet' : 'Edelweiss'})}
                            </Typography.Paragraph>
                        </Flex>
                    </Col>
                </Row>
            </Flex>
            {
                loading && <Portal id={IDS.loader}>
                    <Flex vertical align="center" justify="center" style={{ height: '100%', position: 'fixed', top: 0, left: 0, bottom: 0, right: 0, zIndex: '10000', background: '#FFF' }}>
                        <img src={planeLoader} alt="" />
                        <Typography style={{ fontWeight: 500, fontSize: 16 }}>
                            {t('LaunchScreen.holidayBegin')}
                        </Typography>
                    </Flex>
                </Portal>
            }
            {contextHolder}
        </>
    )
}
