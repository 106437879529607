import { Card, Typography } from 'antd';
import React from 'react'

import './Option.css';

export interface OptionProps {
    title: string;
    img: string;
    content: React.ReactNode,
    actions: React.ReactNode[]
}

export default function Option({ title, img, content, actions }: OptionProps) {
    return (
        <Card
            className='Option'
            cover={
                <img src={img} alt='' />
            }
            actions={actions}
        >
            <Typography.Title level={4}>{title}</Typography.Title>
            {content}
        </Card>
    )
}
