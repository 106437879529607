import React from 'react';
import { ConfigProvider } from 'antd';
import { I18nextProvider } from 'react-i18next'
import { Route, Routes, useMatch, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import i18n from 'services/i18n'
import { AppContext, useAppContextProvider } from 'AppContext';
import LaunchScreen from 'screens/LaunchScreen';
import TokenScreen from 'screens/TokenScreen';
import Wrapper from 'components/Wrapper';
import enEN from 'antd/locale/en_US';
import deDE from 'antd/locale/de_DE';
import OutboundScreen from "./screens/OutboundScreen";
import PassengerScreen from 'screens/PassengerScreen';
import PaymentScreen from 'screens/PaymentScreen';
import OptionsScreen from 'screens/OptionsScreen';
import MyOptionsRetrieveScreen from 'screens/MyOptionsRetrieveScreen';
import NonceScreen from 'screens/NonceScreen';
import MyBookingScreen from 'screens/MyBookingScreen';
import usePrimaryColorAndLink from 'hooks/usePrimaryColorAndLink';
import { setValueToStorage } from 'utils/storage';
import 'dayjs/locale/de';
import "moment/locale/de";
import './App.css';
import './theme/variables.css';

function App() {
    const navigate = useNavigate();
    const { colorPrimary } = usePrimaryColorAndLink();
    const isBookingFlightPath = useMatch('/booking/flights/*');
    const isTicketsPath = useMatch('/tickets');
    const isCheckIn = useMatch('/check-in');
    const isNonce = useMatch('/nonce');
    const isMyBooking = useMatch('/my-booking');
    const isRetrive = useMatch('/retrive');

    const [theme] = React.useState({
        token: {
            colorPrimary: colorPrimary,
            fontFamily: 'Gotham Narrow',
        },
        components: {
            Tabs: {
                cardBg: '#b5b5b5',
                itemColor: '#000',
                itemActiveColor: '#3651fe',
                horizontalMargin: '0',
                cardGutter: 5
            },
            Button: {
                borderRadius: 0,
                borderRadiusLG: 0,
                borderRadiusSM: 0
            },
        }
    });

    const context = useAppContextProvider();

    React.useEffect(() => {
        if (!context.token) {
            if (!!isMyBooking) {
                !!window.location.search && setValueToStorage('my-booking', `${window.location.pathname}${window.location.search}`);
            }
            navigate('/auth');
        } else if (
            !context.booking.flight &&
            !isCheckIn && !isNonce && !isMyBooking && !isRetrive
        ) {
            navigate('/');
        }
    }, [context, isBookingFlightPath, isMyBooking, isTicketsPath, isCheckIn, isNonce, navigate, isRetrive]);

    React.useEffect(() => {
        i18n.changeLanguage(context.language);
        dayjs.locale(context.language);
    }, [context.language]);

    const locale = React.useMemo(() => {
        switch (context.language) {
            case 'de':
                return deDE
            case 'en':
                return enEN
            default:
                return enEN
        }
    }, [context.language]);

    return (
        <ConfigProvider
            locale={locale}
            theme={theme}>
            <I18nextProvider i18n={i18n}>
                <AppContext.Provider value={context}>
                    <Wrapper header={!!context.token}>
                        {
                            context.token ? (
                                <Routes>
                                    <Route
                                        path="/"
                                        Component={LaunchScreen}
                                    />
                                    <Route
                                        path='/outbound'
                                        Component={OutboundScreen}
                                    />
                                    <Route
                                        path='/options'
                                        Component={OptionsScreen}
                                    />
                                    <Route
                                        path='/passenger-data'
                                        Component={PassengerScreen}
                                    />
                                    <Route
                                        path='/payment'
                                        Component={PaymentScreen}
                                    />
                                    <Route
                                        path='/check-in'
                                        Component={NonceScreen}
                                    />
                                    <Route
                                        path='/my-booking'
                                        Component={MyBookingScreen}
                                    />
                                    <Route
                                        path='/retrive'
                                        Component={MyOptionsRetrieveScreen}
                                    />
                                </Routes>
                            ) : (
                                <Routes>
                                    <Route
                                        path="/auth"
                                        Component={TokenScreen}
                                    />
                                </Routes>
                            )
                        }
                    </Wrapper>
                </AppContext.Provider>
            </I18nextProvider>
        </ConfigProvider>
    );
}

export default App;
