import { useCallback } from 'react';
import { Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Pax } from 'utils/booking';

import './PassengersInfo.css';

export interface PassengersInfoProps {
    passengers: Pax[];
}

export default function PassengersInfo({ passengers }: PassengersInfoProps) {
    const { t } = useTranslation();

    const renderPassenger = useCallback((passenger: Pax, index: number) => {
        return (
            <Row key={index} justify="space-between" gutter={[16, 2]}>
                <Col xs={24} sm={8}>
                    <Typography>{t('name')}</Typography>
                    <Typography.Title level={5} className="PassengersInfo-field">
                        {`${passenger.sex ?? ''} ${passenger.ln} ${passenger.fn}`}
                    </Typography.Title>
                </Col>
                <Col xs={24} sm={8}>
                    <Typography>{t('PaymentScreen.seatOutboundFlight')}</Typography>
                    <Typography.Title level={5} className="PassengersInfo-field">
                        {passenger.seat_number ?? t('na')}
                    </Typography.Title>
                </Col>
                <Col xs={24} sm={8}>
                    <Typography>{t('PaymentScreen.specialMeal')}</Typography>
                    <Typography.Title level={5} className="PassengersInfo-field">Standard meal</Typography.Title>
                </Col>
            </Row>
        );
    }, [t]);

    return (
        <div className="PassengersInfo">
            {passengers.map(renderPassenger)}
        </div>
    );
}
