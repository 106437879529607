import React, { useCallback } from 'react'
import { Flex, Button, Typography } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
export interface PassengerCounterProps {
    title: string;
    counter: number;
    minValue?: number;
    onChange: React.Dispatch<React.SetStateAction<number>>;
}

export default function PassengerCounter({ title, counter, minValue, onChange }: PassengerCounterProps) {
    const onAddPassenger = useCallback(() => {
        onChange(prev => prev + 1)
    }, [onChange]);

    const onRemovePassenger = useCallback(() => {
        onChange(prev => prev - 1)
    }, [onChange]);

    return (
        <Flex justify='space-between' align='center' style={{ margin: '5px 0' }}>
            <Typography>{title}</Typography>
            <Flex justify='space-between' align='center' style={{minWidth: '100px'}}>
                <Button icon={<MinusOutlined />} size='small' type="primary" disabled={counter === 0 || counter === minValue} onClick={onRemovePassenger} />
                <span>{counter}</span>
                <Button icon={<PlusOutlined />} size='small' type="primary" onClick={onAddPassenger} />
            </Flex>
        </Flex>
    )
}
