import React from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';

import './InfoBlock.css';

interface Props {
    data: Record<string, any>[];
}

const InfoBlock: React.FC<Props> = ({ data }) => {
    const {t} = useTranslation();
    if (!data || data.length === 0) {
        return null;
    }

    const columns = Object.keys(data[0]).map((key) => ({
        title: t(`InfoBlock.${key}`),
        dataIndex: key,
        key,
        render: (text: string) => <div>{text}</div>,
    }));

    return (
        <Table
            className='InfoBlock'
            dataSource={data}
            columns={columns}
            pagination={false}
            rowHoverable={false}
            rowKey={(record) => Object.values(record).join('_')}
        />
    );
};

export default InfoBlock;
