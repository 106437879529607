import React from 'react';
import { useContextSelector } from 'use-context-selector';
import { useMatch } from 'react-router-dom';
import { AppContext, AppContextType } from 'AppContext';

function usePaxCount() {
    const {
        counts,
    } = useContextSelector(AppContext, (c: AppContextType) => c.booking)
    const match = useMatch('/booking/flights/:direction/:adults/:childs/:infants')

    return React.useMemo(() => {
        if (match?.params) {
            return {
                adults: parseInt(match.params.adults || '0', 10),
                childs: parseInt(match.params.childs || '0', 10),
                infants: parseInt(match.params.infants || '0', 10),
            }
        }

        return counts;
    }, [match, counts]);
}

export default usePaxCount;
