import React, { useState, ReactNode, useCallback } from 'react';
import { Drawer as AntDrawer, Typography, Button, Flex, theme } from 'antd';
import { t } from 'i18next';
import planeLoader from '../../assets/images/plane-loader.gif';

import './Drawer.css';

const { useToken } = theme;

interface DrawerProps {
  open: boolean;
  children?: ReactNode;
  footer?: ReactNode;
  disabled?: boolean;
  noShowLoading?: boolean;
  title?: string;
  mask?: boolean;
  okBtnText?: string;
  onClose?: () => void | undefined;
  onContinue: () => void | null;
}

export const Drawer: React.FC<DrawerProps> = ({
  open,
  children,
  title,
  mask = true,
  disabled = false,
  noShowLoading: noLoading = false,
  footer,
  okBtnText,
  onClose,
  onContinue,
}) => {
  const { token } = useToken();
  const [loading, setLoading] = useState<boolean>(false);
  const [drawerHeight, setDrawerHeight] = useState<string>('auto');

  const handleContinue = useCallback(async () => {
    if (noLoading) {
      return onContinue();
    }
    setLoading(true);
    setDrawerHeight('100vh');
    setTimeout(() => {
      setLoading(false);
      setDrawerHeight('auto');
      onContinue();
    }, 300);
  }, [onContinue, noLoading]);

  const getContainer = useCallback(() => {
    const container = document.getElementById('Wrapper');
    return container ? container : document.body;
  }, []);

  return (
    <AntDrawer
      rootClassName='Drawer'
      open={open}
      mask={mask}
      onClose={onClose}
      placement="bottom"
      closable={false}
      height={drawerHeight}
      getContainer={getContainer}
      maskClosable={false}
      title={
        (!loading || noLoading) && title ? (<Typography.Title level={4} style={{ margin: 0, color: '#FFF' }}>
          {title}
        </Typography.Title>) : null
      }
      footer={
        !loading || noLoading ? (
          <Flex justify='space-between' gap={10} align='center'>
            <Flex justify='flex-end' gap={5} align='center'>
              {footer ? footer : <div />}
            </Flex>
            <Flex justify='flex-end' gap={5} align='center'>
              {
                onClose && (
                  <Button
                    type="link"
                    onClick={onClose}
                  >
                    {t('back')}
                  </Button>
                )
              }
              <Button
                onClick={handleContinue}
                style={{ minHeight: 50, fontSize: 16 }}
                disabled={disabled}
              >
                {okBtnText ? okBtnText : t('continue')}
              </Button>
            </Flex>
          </Flex>
        ) : null
      }
      styles={{ header: { color: "#FFF" } }}
      style={{ backgroundColor: loading && !noLoading ? '#fff' : token.colorPrimary }}
    >
      {loading && !noLoading ? (
        <Flex vertical align="center" justify="center" style={{ height: '100%' }}>
          <img src={planeLoader} alt="" />
          <Typography style={{ fontWeight: 500, fontSize: 16 }}>
            {t('LaunchScreen.holidayBegin')}
          </Typography>
        </Flex>
      ) : (
        children && children
      )}
    </AntDrawer>
  );
};
