import { useCallback, useMemo } from 'react';
import { Row, Col, Typography, Flex, Button, Divider } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FlightV2, Route } from 'utils/flight';
import { Pax } from "../../utils/booking";
import { useContextSelector } from 'use-context-selector';
import { Currency, getCorrectPrice, getCurrencyIcon } from 'utils/currency';
import { AppContext, AppContextType } from 'AppContext';
import { getOneSeatPrice } from 'utils/serviceClass';
import Flight from './components/Flight';
import './OutboundInfo.css';
import { Dayjs } from "dayjs";

export interface OutboundInfoProps {
    flight: FlightV2;
    originCountryName?: string;
    destinationCountryName?: string;
    date: string | number | Dayjs | null;
    counts: {
        adults: number;
        childs: number;
    };
    passengers?: Record<'adults' | 'childs' | 'infants', Pax[]>;
    fullCost?: number;
    type?: "outbound" | "inbound";
    mode?: 'dark' | 'white';
    showNavigation?: boolean;
    hideSeats?: boolean;
}

export default function OutboundInfo({
    flight,
    date,
    counts,
    passengers,
    type = "outbound",
    mode = 'dark',
    showNavigation = true,
    hideSeats = false
}: OutboundInfoProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const currency: Currency = useContextSelector(AppContext, (c: AppContextType) => c.wallet.currency);
    const classes = useContextSelector(AppContext, (c: AppContextType) => c.serviceClass.classes);

    const getPrice = useCallback((type: string, passenger: 'adult' | 'child' | 'infant') => {
        const flightClasses = type === 'ECONOMY'
            ? classes.find((it) => it.id.includes('standard'))
            : classes.find((it) => it.id.includes('flex'))

        if (flightClasses) {
            const price = getOneSeatPrice(flightClasses, currency, passenger, flight?.route?.length ?? 1)
            const correctPrice = getCorrectPrice((price?.[`${passenger}s`] + price.tax));
            return correctPrice
        } else {
            return 0
        }
    }, [classes, currency, flight?.route?.length]);

    const totalSum = useMemo(() => {
        let total = 0;
        let economyTotal = 0;
        let businessTotal = 0;
        console.log('-----------------', passengers);

        [passengers?.adults, passengers?.childs, passengers?.infants].forEach((passengerGroup) => {

            passengerGroup?.forEach((item) => {
                if (item?.flyClass) {
                    const price = getPrice(item.flyClass, item.type as 'adult' | 'child' | 'infant');
                    total += price;

                    if (item.flyClass === 'ECONOMY') {
                        economyTotal += price;
                    } else if (item.flyClass === 'BUSINESS') {
                        businessTotal += price;
                    }
                }
            });
        });

        return {
            total: getCorrectPrice(total),
            economyTotal: getCorrectPrice(economyTotal),
            businessTotal: getCorrectPrice(businessTotal),
        };
    }, [getPrice, passengers?.adults, passengers?.childs, passengers?.infants]);

    const passengersInfo = useMemo(() => {
        if (passengers?.adults?.[0]?.type || passengers?.childs?.[0]?.type || passengers?.infants?.[0]?.type) {
            return [passengers.adults, passengers.childs, passengers.infants].map((passengers, idx) => {
                const list = passengers.map((passenger, jdx) => {
                    return (
                        <Row key={idx + jdx + passenger.type} gutter={5}>
                            <Col>
                                <Typography
                                    style={{ fontSize: 16, fontWeight: 'bold' }}>{passenger.seat_number}</Typography>
                            </Col>
                            <Col>
                                <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
                                    {getCurrencyIcon(currency)} {getPrice(passenger.flyClass as string, passenger.type as 'adult' | 'child' | 'infant')}
                                </Typography>
                            </Col>
                        </Row>
                    )
                });
                return (<Row key={idx} gutter={5}>{list}</Row>);
            });
        } else {
            return Object.entries(counts).filter(([, value]) => value > 0).map((count, idx) => (
                <Row key={idx} gutter={5}>
                    <Col>
                        <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>{count[1]}</Typography>
                    </Col>
                    <Col>
                        <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>{count[0]}</Typography>
                    </Col>
                </Row>
            ));
        }
    },
        [counts, currency, getPrice, passengers]
    );

    const renderFlight = useCallback((route: Route, idx: number) => {
        return (
            <Flight
                key={idx}
                route={route}
                mode={mode}
                date={date as Dayjs}
                economyTotal={getCorrectPrice((totalSum.economyTotal / flight.route.length))}
                businessTotal={getCorrectPrice((totalSum.businessTotal / flight.route.length))}
                currency={currency}
            />
        )
    }, [currency, date, flight?.route?.length, mode, totalSum.businessTotal, totalSum.economyTotal]);

    const handleBack = useCallback(() => {
        setTimeout(() => {
            navigate('/outbound')
        }, 0)
    }, []); //eslint-disable-line

    return (
        <>
            {
                mode === 'white' && (
                    <Flex justify='space-between' align='center'>
                        <Typography.Title level={5} style={{ marginBottom: 0 }}>
                            {t(`OutboundDurationCard.${type === 'outbound' ? 'outboundFlight' : 'inboundFlight'}`,
                                { direction: `${flight?.route[0].origin}-${flight?.route[flight.route.length - 1].destination}` })}
                        </Typography.Title>
                        {
                            showNavigation && (
                                <Button onClick={handleBack} type='link'>
                                    {<CaretRightOutlined />}
                                    {t(`OutboundDurationCard.${type === 'outbound' ? 'changeOutbound' : 'changeInbound'}`)}
                                </Button>
                            )
                        }

                    </Flex>
                )
            }
            <div className={`OutboundInfo ${mode} ${hideSeats ? 'full' : ''}`}>
                <Row>
                    <div className='OutboundInfo-flights'>
                        {
                            !!flight?.route?.length && flight.route.map(renderFlight)
                        }
                    </div>
                    {
                        !hideSeats && (
                            <Row className='OutboundInfo-seats'>
                                <Col span={24}>
                                    <Typography>{t('OutboundDurationCard.seats')}</Typography>
                                    <div>{passengersInfo}</div>
                                </Col>
                            </Row>
                        )
                    }
                </Row>
                {
                    !!totalSum.total && (
                        <>
                            <Divider style={{ borderColor: mode === 'dark' ? '#333333' : '#FFF', marginBottom: 5, marginTop: 0 }} />
                            <div style={{ width: '100%', paddingLeft: 10 }}>

                                <Typography.Title level={4}>
                                    {`${t('OutboundDurationCard.subtotal')}: ${getCurrencyIcon(currency)} ${totalSum.total}`}
                                </Typography.Title>
                            </div>
                        </>
                    )
                }
            </div>
        </>
    );
}