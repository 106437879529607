import React from 'react';
import { getPrice } from 'utils/currency';
import { ServiceClass, SERVICE_CLASS_TYPE, ServiceExtra } from 'utils/serviceClass';

export interface ServiceClassContext {
    classes: ServiceClass[];
    extras: ServiceExtra[];
}

export const INIT_SERVICE_CLASS_CONTEXT: ServiceClassContext = {
    classes: [],
    extras: [],
};

export function useServiceClassContextProvider(): ServiceClassContext {
    const [classes] = React.useState<ServiceClass[]>([
        {
            id: 'standard',
            classname: 'Standard',
            services: {
                [SERVICE_CLASS_TYPE.checkedinbaggage]: {
                    active: true,
                    max_weight: 23,
                },
                [SERVICE_CLASS_TYPE.flexibleamendment]: {
                    active: true,
                    rebookinPrice: getPrice(80),
                },
                [SERVICE_CLASS_TYPE.flexiblecancellation]: {
                    active: false,
                },
                [SERVICE_CLASS_TYPE.foodonboard]: {
                    active: true,
                },
                [SERVICE_CLASS_TYPE.handbaggage]: {
                    active: true,
                    max_weight: 5,
                    max_length: 55,
                    max_width: 40,
                    max_height: 20,
                },
                [SERVICE_CLASS_TYPE.prioritycheckin]: {
                    active: false
                },
                [SERVICE_CLASS_TYPE.refundableticket]: {
                    active: false,
                },
                [SERVICE_CLASS_TYPE.seatselection]: {
                    active: false,
                },
            },

            fare: {
                tax: 0.077,
                adult: getPrice(276.66),
                child: getPrice(276.66),
                infant: getPrice(0),
            },
        },
        {
            id: 'flex',
            classname: 'Flex',
            services: {
                [SERVICE_CLASS_TYPE.checkedinbaggage]: {
                    active: true,
                    max_weight: 23,
                },
                [SERVICE_CLASS_TYPE.flexibleamendment]: {
                    active: true,
                    price: getPrice(80),
                },
                [SERVICE_CLASS_TYPE.flexiblecancellation]: {
                    active: false,
                },
                [SERVICE_CLASS_TYPE.foodonboard]: {
                    active: true,
                },
                [SERVICE_CLASS_TYPE.handbaggage]: {
                    active: true,
                    max_weight: 5,
                    max_length: 55,
                    max_width: 40,
                    max_height: 20,
                },
                [SERVICE_CLASS_TYPE.prioritycheckin]: {
                    active: false
                },
                [SERVICE_CLASS_TYPE.refundableticket]: {
                    active: true,
                    price: getPrice(120),
                    hours: 48,
                },
                [SERVICE_CLASS_TYPE.seatselection]: {
                    active: true,
                },
            },

            fare: {
                adult: getPrice(310.27),
                child: getPrice(310.27),
                infant: getPrice(0),
                tax: 0.077,
            },
        }
    ]);
    const [extras] = React.useState<ServiceExtra[]>([
        {
            id: 'increase_baggage_weight',
            name: 'ServiceExtra.increase_baggage_weight.name',
            description: 'ServiceExtra.increase_baggage_weight.description',
            options: [
                {
                    id: 'increase_32',
                    text: 'ServiceExtra.increase_baggage_weight.increase_baggage_allowance_up_to',
                    meta: {
                        weight: 32,
                    },
                    price: getPrice(80)
                },
                {
                    id: 'increase_46',
                    text: 'ServiceExtra.increase_baggage_weight.increase_baggage_allowance_up_to',
                    meta: {
                        weight: 46,
                    },
                    price: getPrice(150)
                },
            ],
        },
        {
            id: 'sport_equipment',
            name: 'ServiceExtra.sport_equipment.name',
            description: 'ServiceExtra.sport_equipment.description',
            options: [
                {
                    id: 'golf_equipment',
                    text: 'ServiceExtra.sport_equipment.golf_equipment',
                    meta: {},
                    price: getPrice(60)
                },
                {
                    id: 'surfboard_up_to',
                    text: 'ServiceExtra.sport_equipment.surfboard_up_to',
                    meta: {
                        height: 150
                    },
                    price: getPrice(60)
                },
                {
                    id: 'diving_equipment',
                    text: 'ServiceExtra.sport_equipment.diving_equipment',
                    meta: {},
                    price: getPrice(60)
                },
                {
                    id: 'ski_snowboard_equipment',
                    text: 'ServiceExtra.sport_equipment.ski_snowboard_equipment',
                    meta: {},
                    price: getPrice(60)
                },
                {
                    id: 'others',
                    text: 'ServiceExtra.sport_equipment.others',
                    meta: {},
                    price: getPrice(60)
                },
            ],
        },
        {
            id: 'bicycle',
            name: 'ServiceExtra.bicycle.name',
            description: 'ServiceExtra.bicycle.description',
            options: [
                {
                    id: 'bicycle',
                    text: 'ServiceExtra.sport_equipment.bicycle',
                    meta: {},
                    price: getPrice(60)
                },
            ],
        },
        {
            id: 'special_menu',
            name: 'ServiceExtra.special_menu.name',
            description: 'ServiceExtra.special_menu.description',
            options: [
                {
                    id: 'diabetic',
                    text: 'ServiceExtra.special_menu.diabetic',
                    meta: {},
                    price: getPrice(0)
                },
                {
                    id: 'gluten_free',
                    text: 'ServiceExtra.special_menu.gluten_free',
                    meta: {},
                    price: getPrice(0)
                },
                {
                    id: 'vegan_meal_non_diary',
                    text: 'ServiceExtra.special_menu.vegan_meal_non_diary',
                    meta: {},
                    price: getPrice(0)
                },
                {
                    id: 'vegetarian',
                    text: 'ServiceExtra.special_menu.vegetarian',
                    meta: {},
                    price: getPrice(0)
                },
            ],
        },
        {
            id: 'big_sport_equipment',
            name: 'ServiceExtra.big_sport_equipment.name',
            description: 'ServiceExtra.big_sport_equipment.description',
            options: [
                {
                    id: 'surfboard',
                    text: 'ServiceExtra.big_sport_equipment.surfboard',
                    meta: {},
                    price: getPrice(120)
                },
                {
                    id: 'others',
                    text: 'ServiceExtra.big_sport_equipment.others',
                    meta: {},
                    price: getPrice(120)
                },
            ],
        },
        {
            id: 'free_winter_sports_equipment',
            name: 'ServiceExtra.free_winter_sports_equipment.name',
            description: 'ServiceExtra.free_winter_sports_equipment.description',
            options: [
                {
                    id: 'winter_equipment',
                    text: 'ServiceExtra.free_winter_sports_equipment.winter_equipment',
                    meta: {},
                    price: getPrice(0)
                },
                {
                    id: 'big_winter_equipment',
                    text: 'ServiceExtra.free_winter_sports_equipment.big_winter_equipment',
                    meta: {},
                    price: getPrice(0)
                },
            ],
        },
        {
            id: 'winter_sports_equipment',
            name: 'ServiceExtra.winter_sports_equipment.name',
            description: 'ServiceExtra.winter_sports_equipment.description',
            options: [
                {
                    id: 'ski_snowboard_equipment',
                    text: 'ServiceExtra.free_winter_sports_equipment.ski_snowboard_equipment',
                    meta: {},
                    price: getPrice(60)
                },
                {
                    id: 'others',
                    text: 'ServiceExtra.free_winter_sports_equipment.others',
                    meta: {},
                    price: getPrice(60)
                },
            ],
        },
    ])

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return React.useMemo(() => ({
        classes,
        extras,
    }), [
        classes,
        extras,
    ]);
}
