import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useContextSelector } from 'use-context-selector';
import { AppContext, AppContextType } from 'AppContext';
import logoRed from 'assets/images/air_red.svg';
import './Header.css'
import config from 'config';
import usePrimaryColorAndLink from 'hooks/usePrimaryColorAndLink';


function Header() {
    const { t } = useTranslation();
    const { logo } = usePrimaryColorAndLink();
    const navigate = useNavigate();
    const location = useLocation()
    const clear = useContextSelector(AppContext, (c: AppContextType) => c.clear);

    const onCancel = React.useCallback(() => {
        if (location.pathname === '/') {
            return
        }
        clear();
        setTimeout(() => {
            navigate('/');
        }, 0)
    }, [clear, navigate, location]);

    return (
        <div className="Header">
            <div className="Header-logoContainer" onClick={onCancel}>
                {
                    config.IS_EDELWEISS === 'true' ? (
                        <img src={logo} alt={t('Wrapper.plane')} className="Header-logo-edelweiss" />
                    ) : (
                        <>
                            <img src={logoRed} alt={t('Wrapper.plane')} className="Header-logo" />
                            <div className="Header-headerCol">
                                <span>CompPass</span>
                                <span>Airways</span>
                            </div>
                        </>
                    )
                }

            </div>
        </div>
    );
}

export default Header;
