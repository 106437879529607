import React from 'react';
import {SeatKlass} from "contexts/PlaneSeatContext";
import './SeatIcon.css'


function SeatIcon({klass, seat}: { klass: SeatKlass, seat?: { flag: boolean; id?: number; name?: string } | undefined }) {
  return (
    <div className={'SeatIcon'} title={seat?.name}>
      <i className={`SeatIcon-${klass}`}/>
      {seat?.flag && <span className="SeatIcon-id">{seat?.id}</span>}
    </div>
  );
}

export default SeatIcon;
