import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import Calendar from './components/Calendar';
import './MonthsSlider.css';

export interface MonthsSliderProps {
    months: Dayjs[];
    active?: Dayjs;
    onSetActive?: (month: Dayjs) => void;
}

function MonthsSlider({ months, active, onSetActive }: MonthsSliderProps) {
    const { i18n } = useTranslation();
    const [activeMonth, setActiveMonth] = useState<Dayjs>(months[0]);
    const [showButtons, setShowButtons] = useState({ left: false, right: true });

    const scrollRef = useRef<HTMLDivElement>(null);

    const scrollLeft = useCallback(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: -150, behavior: 'smooth' });
        }
    }, []);

    const scrollRight = useCallback(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: 150, behavior: 'smooth' });
        }
    }, []);

    const handleScroll = useCallback(() => {
        if (scrollRef.current) {
            const scrollLeft = scrollRef.current.scrollLeft;
            const scrollWidth = scrollRef.current.scrollWidth;
            const clientWidth = scrollRef.current.clientWidth;

            setShowButtons({
                left: scrollLeft > 0,
                right: scrollLeft < scrollWidth - clientWidth,
            });
        }
    }, []);

    useEffect(() => {
        handleScroll();
        window.addEventListener('resize', handleScroll);
        return () => {
            window.removeEventListener('resize', handleScroll);
        };
    }, [handleScroll]);

    useEffect(() => {
        const currentScrollRef = scrollRef.current;

        if (currentScrollRef) {
            currentScrollRef.addEventListener('scroll', handleScroll);
            handleScroll();
        }

        return () => {
            if (currentScrollRef) {
                currentScrollRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll]);

    return (
        <div className='MonthsSlider'>
            <div className="MonthsSlider-container">
                <button
                    className={`MonthsSlider-button left ${showButtons.left ? 'visible' : 'hidden'}`}
                    onClick={scrollLeft}
                >
                    <LeftOutlined />
                </button>
                <div className="MonthsSlider-wrapper">
                    <div className="MonthsSlider-content" ref={scrollRef}>
                        {
                            months.map((month: Dayjs, idx: number) => (
                                <div
                                    key={idx}
                                    className={`MonthsSlider-item ${activeMonth === month ? 'active' : ''}`}
                                    onClick={() => setActiveMonth(month)}
                                >
                                    <span className='MonthsSlider-month'>{dayjs(month).locale(i18n.language).format('MMM')}</span>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <button
                    className={`MonthsSlider-button right ${showButtons.right ? 'visible' : 'hidden'}`}
                    onClick={scrollRight}
                >
                    <RightOutlined />
                </button>
            </div>
            <Calendar autoFocus availableMonth={activeMonth} />
        </div>
    )
}

export default memo(MonthsSlider)
