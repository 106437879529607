import { useMemo, useCallback, useState } from 'react';
import { get, post } from 'utils/rest';
import { ACTIONS } from 'utils/flight';
import { DocumentRequest, RequirementsRequestParams } from 'utils/documentRequest';
import config from 'config';
import { Document, Requirements, ValidationResult } from 'utils/checkin';

export interface DocRequestContext {
    requirements: Requirements[];
    initSession: DocumentRequest | null;
    sessionDocuments: Document[] | null;
    resultValidation: ValidationResult | null;
    onDocumentRequest: (userId: string, sessionId: string, ad: string, controller?: AbortController | null) => Promise<DocumentRequest>;
    onDocumentRequestInit: (userId: string, sessionId: string, ad: string, controller?: AbortController | null) => Promise<DocumentRequest | null>;
    onGetDocumentRequest: (userId: string, sessionId: string, ad: string, controller?: AbortController | null) => Promise<Document[]>;
    onDocRequestDocumentRecognize: (
        files: File[],
        stepId: string,
        sessionId: string,
        userId: string,
        ad: string,
        upload_id?: string | null
    ) => Promise<Document | null>;
    onDocRequestManualRecognize: (
        stepId: string,
        sessionId: string,
        userId: string,
        ad: string,
        form: Record<string, boolean | number | string>,
    ) => Promise<Document | null>;
    onGetValidationStatus: (sessionId: string) => Promise<any>;
    onValidation: (
        sessionId: string,
        userId: string,
        ad: string
    ) => Promise<Document | null>;
}

export const INIT_DOCREQUEST_CONTEXT: DocRequestContext = {
    requirements: [],
    initSession: null,
    sessionDocuments: null,
    resultValidation: null,
    onDocumentRequest: () => Promise.resolve({} as DocumentRequest),
    onDocumentRequestInit: () => Promise.resolve(null),
    onGetDocumentRequest: () => Promise.resolve([]),
    onDocRequestDocumentRecognize: () => Promise.resolve(null),
    onDocRequestManualRecognize: () => Promise.resolve(null),
    onGetValidationStatus: () => Promise.resolve(),
    onValidation: () => Promise.resolve(null)
}

export function useDocRequestContextProvider(getToken: (action: string) => Promise<string | null>): DocRequestContext {
    const [documentRequest, setDocumentRequest] = useState<DocumentRequest | {}>({});
    const [initSession, setInitSession] = useState<DocumentRequest | null>(null);
    const [sessionDocuments, setSessionDocuments] = useState<Document[] | null>(null);
    const [requirements, setRequirements] = useState<Requirements[]>([]);
    const [resultValidation, setResultValidation] = useState<ValidationResult | null>(null);

    const onGetRequirements = useCallback((params: RequirementsRequestParams) => {
        return getToken(ACTIONS.getRequirements)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return post(`${config.REQUIREMENTS_EP}/get_requirements`, { ...params }, {
                    'x-api-key': config.CHECKIN_SERVER_API_KEY,
                    'recaptcha-token': tkn
                })
            })
            .then((res) => {
                setRequirements(res);
            })
    }, [getToken]);

    const onDocumentRequest = useCallback((userId: string, sessionId: string, ad: string, controller?: AbortController | null) => {
        return getToken(ACTIONS.documentRequest)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return post(`${config.DOCUMENT_REQUEST_EP}`, {
                    user_id: userId,
                    session_id: sessionId,
                    ad
                }, {
                    'x-api-key': config.CHECKIN_SERVER_API_KEY,
                    'recaptcha-token': tkn
                }, controller)
            })
            .then((documents: DocumentRequest) => {
                const {
                    flight_number,
                    flight_date,
                    passenger_document,
                    passenger_firstname,
                    passenger_lastname,
                    ticket_number
                } = documents.flight_ticket;
                onGetRequirements({
                    flight_number,
                    ...flight_date && { flight_date },
                    ...passenger_document && { passenger_document },
                    ...passenger_firstname && { passenger_firstname },
                    ...passenger_lastname && { passenger_lastname },
                    ticket_number
                }).catch((err) => console.log('[DocRequestContext] onGetRequirements', err))
                setDocumentRequest(documents);
                return documents
            })
            .catch((err) => {
                console.log('[DocRequestContext] onDocumentRequest error:', err);
                throw (err)
            })
    }, [getToken, onGetRequirements]);

    const onDocumentRequestInit = useCallback((userId: string, sessionId: string, ad: string, controller?: AbortController | null) => {
        return getToken(ACTIONS.documentRequestInit)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return post(`${config.DOCUMENT_REQUEST_EP}/init`, {
                    user_id: userId,
                    session_id: sessionId,
                    ad
                }, {
                    'x-api-key': config.CHECKIN_SERVER_API_KEY,
                    'recaptcha-token': tkn
                }, controller)
            })
            .then((documents: DocumentRequest) => {
                setInitSession(documents);
                return documents
            })
            .catch((err) => {
                console.log('[DocRequestContext] onDocumentRequestInit error:', err);
                throw (err)
            })
    }, [getToken]);

    const onGetDocumentRequest = useCallback((userId: string, sessionId: string, ad: string, controller?: AbortController | null) => {
        return getToken(ACTIONS.getDocumentRequest)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return get(`${config.DOCUMENT_EP}/document_request`, {
                    user_id: userId,
                    session_id: sessionId,
                    ad
                }, {
                    'x-api-key': config.CHECKIN_SERVER_API_KEY,
                    'recaptcha-token': tkn
                }, controller)
            })
            .then((documents: Document[]) => {
                setSessionDocuments(documents);
                return documents
            })
            .catch((err) => {
                console.log('[DocRequestContext] onGetDocumentRequest error:', err);
                throw (err)
            })
    }, [getToken]);

    const onDocRequestDocumentRecognize = useCallback(async (
        files: File[],
        stepId: string,
        sessionId: string,
        userId: string,
        ad: string,
        upload_id?: string | null
    ) => {
        return new Promise(async (resolve: (value: Document) => void, reject) => {
            const formData = new FormData();
            const request = new XMLHttpRequest();

            files.length > 0 && files.forEach((file: File) => {
                formData.append('files', file);
            });

            upload_id && formData.append('upload_id', upload_id);
            const tknRecaptcha = await getToken(ACTIONS.docRequestRecognize);

            formData.append('step_id', stepId);
            formData.append('session_id', sessionId);
            formData.append('user_id', userId);
            formData.append('ad', ad);

            request.open('POST', `${config.DOCUMENT_EP}/document_request/recognize`);
            request.setRequestHeader('x-api-key', config.CHECKIN_SERVER_API_KEY);
            !!tknRecaptcha && request.setRequestHeader('recaptcha-token', tknRecaptcha)

            request.onload = () => {
                try {
                    if ((request.status === 200) || (request.status === 201)) {
                        resolve(JSON.parse(request.response) as Document);
                    } else if (request.status === 401) {
                        reject(new Error('wrong_token'));
                    } else if ((request.status === 404) || (request.status === 405) || (request.status >= 500)) {
                        reject(new Error('service_is_temporarily_unavailable'));
                    } else {
                        const data = JSON.parse(request.response);
                        reject(data);
                    }
                } catch (err) {
                    console.log('[CheckInContext] onDocumentRecognize failed:', err);
                    reject(err);
                }
            }

            request.onerror = (e) => {
                console.log('[CheckInContext] onDocumentRecognize failed:', e);
                reject(new Error('service_is_temporarily_unavailable'))
            }
            request.send(formData);
        })
    }, [getToken]);

    const onDocRequestManualRecognize = useCallback((
        stepId: string,
        sessionId: string,
        userId: string,
        ad: string,
        form: Record<string, boolean | number | string>,
    ) => {
        return getToken(ACTIONS.docRequestManualRecognize)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return post(`${config.DOCUMENT_EP}/document_request/manual`, {
                    step_id: stepId,
                    session_id: sessionId,
                    user_id: userId,
                    ad,
                    data: form
                }, {
                    'x-api-key': config.CHECKIN_SERVER_API_KEY,
                    'recaptcha-token': tkn
                })
            })
    }, [getToken]);

    const onGetValidationStatus = useCallback((sessionId: string) => {
        return getToken(ACTIONS.docRequestValidationStatus)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return post(`${config.VALIDATION_EP}/get_validation_result`, {
                    session_id: sessionId,
                }, {
                    'x-api-key': config.CHECKIN_SERVER_API_KEY,
                    'recaptcha-token': tkn
                })
            })
    }, [getToken]);

    const onValidation = useCallback((
        sessionId: string,
        userId: string,
        ad: string
    ) => {
        return getToken(ACTIONS.docRequestValidation)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return post(`${config.VALIDATION_EP}/document_request`, {
                    session_id: sessionId,
                    user_id: userId,
                    ad: ad,
                    all_documents_added: true
                }, {
                    'x-api-key': config.CHECKIN_SERVER_API_KEY,
                    'recaptcha-token': tkn
                })
            })
            .then((res) => {
                setResultValidation(res);
                return res;
            })
    }, [getToken]);

    return useMemo(() => ({
        requirements,
        initSession,
        sessionDocuments,
        resultValidation,
        onDocumentRequest,
        onDocumentRequestInit,
        onGetDocumentRequest,
        onDocRequestDocumentRecognize,
        onDocRequestManualRecognize,
        onGetValidationStatus,
        onValidation
    }), [
        requirements,
        initSession,
        sessionDocuments,
        resultValidation,
        onDocumentRequest,
        onDocumentRequestInit,
        onGetDocumentRequest,
        onDocRequestDocumentRecognize,
        onDocRequestManualRecognize,
        onGetValidationStatus,
        onValidation
    ])
}