import random from 'lodash.random';
import dayjs from 'dayjs';
import config from "config";

export interface FlightPoint {
    iata: string;
    name: string;
    country: string;
}

export interface Flight {
    airline: string;
    number: string;
    departure_time: string;
    arrival_time: string;
    classes: string[];
    destination: string;
    destination_name: string;
    origin: string;
    origin_name: string;
}

export interface Route {
    destination_name: string;
    origin_name: string;
    destination: string;
    origin: string;
    flight_number: string;
    airline: string;
    flight_departure_time?: string;
    flight_arrival_time?: string;
    flight_date?: string;
    id: string,
}

export interface FlightV2 {
    id: string;
    classes: string[];
    date: string;
    route: Route[];
}

export interface FlightParams {
    origin: string;
    destination: string;
}

export const ACTIONS = {
    arrivalsList: 'arrivals_list',
    departuresList: 'departures_list',
    create: 'create',
    token: 'token',
    getTickets: 'get_tickets',
    getTicketStatus: 'get_ticket_status',
    bindTicket: 'bind_ticket',
    initSession: 'init_session',
    cancelSession: 'cancel_session',
    documentRecognize: 'document_recognize',
    manualRecognize: 'manual_recognize',
    getValidationStep: 'get_validation_step',
    bucketValidation: 'bucket_validation',
    standardValidation: 'standard_validation',
    getJsonByType: 'get_json_by_type',
    getJsonById: 'get_json_by_id',
    getDictionary: 'get_dictionary',
    getRequirements: 'get_requirements',
    documentRequest: 'document_request',
    documentRequestInit: 'document_request_init',
    getDocumentRequest: 'get_document_request',
    docRequestRecognize: 'document_request_recognize',
    docRequestManualRecognize: 'document_request_manual_recognize',
    docRequestValidationStatus: 'document_request_validation_status',
    docRequestValidation: 'document_request_validation',
    getUploadUrlAndId: 'get_upload_url_and_id',
    deleteDocument: 'delete_document',
    getUploadStatus: 'get_upload_status',
    getNonce: 'get_nonce',
}

export function generateSeatNumber() {
    const col = config.FLIGHT.COLS[random(0, config.FLIGHT.COLS.length - 1)];
    return `${random(['D', 'E', 'F'].includes(col) ? 1 : 0, config.FLIGHT.ROWS)}${col}`;
}

export function getSeatsCount() {
    return (config.FLIGHT.COLS.length * config.FLIGHT.ROWS) - Math.floor(config.FLIGHT.COLS.length / 2);
}

export function getUniqSeatNumbers(paxs: number, reserved: string[]) {
    if (!paxs) {
        return [];
    }

    const result: string[] = [];
    let attempt = 0;
    const maxAttempt = getSeatsCount();

    while (result.length !== paxs) {
        if (attempt > maxAttempt) {
            throw new Error('seat_number');
        }

        const seat = generateSeatNumber();
        if (!result.includes(seat) && !reserved.includes(seat)) {
            result.push(seat);
        }

        attempt += 1;
    }

    return result;
}

function getRandomArbitrary(min: number, max: number) {
    return Math.random() * (max - min) + min;
}

function roundToNearestFiveMinutes(time: dayjs.Dayjs): dayjs.Dayjs {
    const minutes = time.minute();
    const roundedMinutes = Math.round(minutes / 5) * 5;
    return time.minute(roundedMinutes).second(0);
}

export function generateFlightTimes(flights: Route[]): Route[] {
    let currentDepartureTime = dayjs().hour(getRandomArbitrary(0, 12)).minute(0).second(0);

    return flights.map((flight, index) => {
        let arrivalTime = currentDepartureTime.add(getRandomArbitrary(1, 3), 'hour').add(getRandomArbitrary(0, 60), 'minute');
        arrivalTime = roundToNearestFiveMinutes(arrivalTime);

        const updatedFlight = {
            ...flight,
            flight_departure_time: roundToNearestFiveMinutes(currentDepartureTime).format('HH:mm'),
            flight_arrival_time: arrivalTime.format('HH:mm'),
        };

        currentDepartureTime = arrivalTime.add(30, 'minute');
        currentDepartureTime = roundToNearestFiveMinutes(currentDepartureTime);

        return updatedFlight;
    });
};