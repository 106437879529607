import dayjs from 'dayjs';
import { useState } from 'react';

const useMonths = () => {
    const currentMonth = dayjs().month();
    const currentYear = dayjs().year();

    const months = Array.from({ length: 12 }, (_, i) => {
        const newMonth = (currentMonth + i) % 12;
        const newYear = currentYear + Math.floor((currentMonth + i) / 12);

        return dayjs().year(newYear).month(newMonth)
    });

    const [monthsState] = useState(months);

    return monthsState;
};

export default useMonths