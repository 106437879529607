import React, {useCallback} from 'react';
import {useContextSelector} from "use-context-selector";
import {AppContext, AppContextType} from "../AppContext";


function useFlightSeating() {
   const context = useContextSelector(AppContext, (c: AppContextType) => c.seats);
   return context;
}

export default useFlightSeating;
