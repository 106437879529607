import { useMemo } from "react";
import { AppContext, AppContextType } from "AppContext";
import { useContextSelector } from "use-context-selector";
import { useMatch } from "react-router-dom";
import beijing from '../assets/destinations/beijing.webp';
import delhi from '../assets/destinations/delhi.jpg';
import dublin from '../assets/destinations/dublin.jpg';
import hurgada from '../assets/destinations/hurghada.webp';
import sanJose from '../assets/destinations/san_josé.jpg';
import shanghai from '../assets/destinations/shanghai.jpg';
import sharmElSheikh from '../assets/destinations/sharm_el_sheikh.jpg';
import capeTown from '../assets/destinations/cape_town.webp';
import doha from '../assets/destinations/doha.jpeg';
import lasVegas from '../assets/destinations/las_vegas.jpg';
import londonHeathrow from '../assets/destinations/london.webp';
import bucharest from '../assets/destinations/bucharest.jpg';
import cancun from '../assets/destinations/cancun.jpg';
import johannesburg from '../assets/destinations/johannesburg.jpg';
import nairobi from '../assets/destinations/nairobi.webp';
import rio from '../assets/destinations/rio.jpg';
import kittila from '../assets/destinations/kittila.jpg';
import jfk from '../assets/destinations/jfk.jpg';
import algier from '../assets/destinations/algier.webp';
import manchester from '../assets/destinations/manchester.jpg';
import laranca from '../assets/destinations/larnaca.webp';
import stockholm from '../assets/destinations/stockholm.webp';
import krakow from '../assets/destinations/krakow.webp';
import berlin from '../assets/destinations/berlin.webp';
import geneva from '../assets/destinations/geneva.webp';
import tbilisi from '../assets/destinations/tbilisi.webp';

import seychelles from '../assets/images/seychelles.webp';

const destinationImages: Record<string, string> = {
    'DUB': dublin,
    'PEK': beijing,
    'DEL': delhi,
    'HRG': hurgada,
    'SJO': sanJose,
    'PVG': shanghai,
    'SSH': sharmElSheikh,
    'DOH': doha,
    'LHR': londonHeathrow,
    'OTP': bucharest,
    'CPT': capeTown,
    'LAS': lasVegas,
    'CUN': cancun,
    'JNB': johannesburg,
    'NBF': nairobi,
    'GIG': rio,
    'KTT': kittila,
    'JFK': jfk,
    'ALG': algier,
    'MAN': manchester,
    'LCA': laranca,
    'ARN': stockholm,
    'KRK': krakow,
    'BER': berlin,
    'GVA': geneva,
    'TBS': tbilisi
};

function useBackground() {
    const isRetrieve = useMatch('/retrive');
    const { booking, arrival } = useContextSelector(AppContext, (c: AppContextType) => c.booking);

    return useMemo(() => {
        let destination;

        if (isRetrieve && booking) {
            const bookingDate = booking?.flight_tickets[0]?.routes[0]?.flight_date;

            destination = booking?.flight_tickets[0]?.routes
                .filter(route => route.flight_date === bookingDate)
                .pop()?.flight_destination ?? '';
        } else {
            destination = arrival?.iata ?? ''
        }

        return destinationImages[destination] || seychelles;
    }, [arrival, booking, isRetrieve]);
}

export default useBackground