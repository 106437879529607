import React, {useCallback} from 'react';


export enum FlyKlass {
  economy = "ECONOMY",
  business = "BUSINESS",
}

export enum SeatKlass {
  economy = "ECONOMY",
  mySeat = "MY_SEAT",
  busy = "BUSY",

  economy2 = "ECONOMYSMALL",
  busy2 = "ECONOMYSMALLBUSY",
  mySeat2 = "MY_SEAT_SMALL",

  business = "BSEMPTY",
  businessBusy = "BSBUSY",
  businessSeat = "BSSEAT",

  exitLeft = "EXITLEFT",
  exitRight = "EXITRIGHT",
}

export type Seats = {
  [column: string]: {
    [row: string]: {
      type: string;
      flag: boolean,
      id?: number,
      name?: string,
      flyClass?: FlyKlass,
      seatNumber?: string
    }
  }
};

export interface IPlaneSchemeTemplateSection {
  klass: FlyKlass,
  name: string,
  baseStyle: { [name: string]: string | number },
  mapHeaderStyle: { [name: string]: string | number },
  startRow: number,
  rowsCount: number,
  distance: number,
  spaces: number[],
  mapHeader: [string, number][],
  rowHeaderStyle: { [name: string]: string | number },
  rows: [number, SeatKlass, boolean][][]

  getSpace(rowIndex: number): number;
}

export interface SeatsContext {
  template: { [section: string]: IPlaneSchemeTemplateSection },
  seats: Seats,
  check: (row: number, column: number) => void,
  setSeatRequests: (counts: { adults: number, childs: number, infants: number }) => void,
  clearSeats: () => void,
  seatsRequests: { id: number, name: string }[],
}

const range = (start: number, end: number) => {
  const length = end - start;
  return Array.from({length}, (_, i) => start + i);
}

const templates: { [plane: string]: { [section: string]: IPlaneSchemeTemplateSection } } = {
  'A340': {
    "first": {
      name: "first",
      klass: FlyKlass.economy,
      startRow: 1,
      rowsCount: 10,
      distance: 45,
      spaces: [],
      baseStyle: {
        background: `url(${require('../assets/images/planes/A340_empty@1x.png')}) center 0 no-repeat`,
        height: '1000px',
        minWidth: '360px',
        maxWidth: '360px',
      },
      mapHeaderStyle: {
        top: '400px',
        left: '55px'
      },
      mapHeader: [
        ['A', 0], ['B', 30], ['D', 90], ['E', 115],
        ['F', 140], ['G', 160], ['J', 210], ['K', 240]
      ],
      rowHeaderStyle: {
        top: '420px',
        left: '45px'
      },
      getSpace(rowIndex: number) {
        return 55;
      },
      rows: range(0, 10).map(index => {
        if (index === 9) {
          return (
            [
              [84, SeatKlass.economy, true],
              [110, SeatKlass.economy, true],
              [136, SeatKlass.economy, true],
              [162, SeatKlass.economy, true],
            ]
          )
        }

        return (
          [
            [0, SeatKlass.economy, true], [26, SeatKlass.economy, true],
            [84, SeatKlass.economy, true], [110, SeatKlass.economy, true], [136, SeatKlass.economy, true], [162, SeatKlass.economy, true],
            [214, SeatKlass.economy, true], [240, SeatKlass.economy, true]
          ]
        );
      })
    },
    "second": {
      name: "first",
      klass: FlyKlass.economy,
      startRow: 18,
      rowsCount: 27,
      distance: 39,
      spaces: [100],
      baseStyle: {
        background: `url(${require('../assets/images/planes/A340_empty@1x.png')}) center -1400px no-repeat`,
        height: '1500px',
        minWidth: '360px',
        maxWidth: '360px',
      },
      mapHeaderStyle: {
        top: '20px',
        left: '55px'
      },
      mapHeader: [
        ['A', 0], ['B', 30], ['D', 90], ['E', 115],
        ['F', 140], ['G', 160], ['J', 210], ['K', 240]
      ],
      rowHeaderStyle: {
        top: '20px',
        left: '45px'
      },
      getSpace(rowIndex: number) {
        if (rowIndex > 9) {
          return 120;
        } else {
          return 55;
        }
      },
      rows: range(0, 27).map(index => {
        if (index === 12) {
          return (
            [
              [0, SeatKlass.exitLeft, true],
              [84, SeatKlass.economy2, true], [110, SeatKlass.economy2, true],
              [136, SeatKlass.economy2, true], [162, SeatKlass.economy2, true],
              [214, SeatKlass.exitRight, true],
            ]
          );
        }

        return (
          [
            [0, SeatKlass.economy2, true], [26, SeatKlass.economy2, true],
            [84, SeatKlass.economy2, true], [110, SeatKlass.economy2, true], [136, SeatKlass.economy2, true], [162, SeatKlass.economy2, true],
            [214, SeatKlass.economy2, true], [240, SeatKlass.economy2, true]
          ]
        );
      })
    },
    "business": {
      name: "business",
      klass: FlyKlass.business,
      startRow: 11,
      rowsCount: 6,
      distance: 57,
      spaces: [100],
      baseStyle: {
        background: `url(${require('../assets/images/planes/A340_empty@1x.png')}) center -850px no-repeat`,
        height: '650px',
        minWidth: '360px',
        maxWidth: '360px',
      },
      mapHeaderStyle: {
        top: '190px',
        left: '55px'
      },
      mapHeader: [
        ['A', 20], ['D', 90], ['G', 160], ['K', 220]
      ],
      rowHeaderStyle: {
        top: '210px',
        left: '45px'
      },
      getSpace(rowIndex: number) {
        return 55;
      },
      rows: range(0, 6).map(index => {
        if (index % 2 === 0) {
          return (
            [
              [26, SeatKlass.business, true],
              [94, SeatKlass.business, true], [156, SeatKlass.business, true],
              [220, SeatKlass.business, true]
            ]
          );
        } else {
          return (
            [
              [0, SeatKlass.business, true], [52, SeatKlass.business, true],
              // [104, SeatKlass.business, true],
              [156, SeatKlass.business, true],
              [240, SeatKlass.business, true]
            ]
          );
        }
      })
    },
  }
}

export const INIT_SEATS_CONTEXT: SeatsContext = {
  template: templates.A340,
  seats: {},
  check: (row: number, column: number) => null,
  setSeatRequests: (counts: { adults: number, childs: number, infants: number }) => null,
  clearSeats: () => null,
  seatsRequests: [],
};

export function usePlaneSeatContext(plane: string = 'A340'): SeatsContext {
  const template = templates[plane];
  const [seats, setSeats] = React.useState<Seats>({} as Seats);

  const [seatsRequests, setRequests] = React.useState<{ id: number, type: string, name: string }[]>([
    // {id: 1, name: 'iam'},
    // {id: 2, name: 'Sun'},
    // {id: 3, name: 'Wife'},
  ]);

  const setSeatRequests = useCallback((counts: {
    adults: number;
    childs: number;
    infants: number;
  }) => {
    const seatRequests = [];
    for (let adult = 0; adult < counts.adults; adult++) {
      seatRequests.push({
        id: adult + 1,
        name: 'Adult ' + adult + 2,
        type: 'adult'
      });
    }

    for (let child = 0; child < counts.childs; child++) {
      seatRequests.push({
        id: counts.adults + child + 1,
        name: 'Adult ' + child + 1,
        type: 'child'
      });
    }

    for (let infant = 0; infant < counts.infants; infant++) {
      seatRequests.push({
        id: counts.adults + counts.infants + infant + 1,
        name: 'Infant ' + infant + 1,
        type: 'infant'
      });
    }

    setRequests(seatRequests);
  }, [setRequests]);

  const check = useCallback((row: number, column: number) => {
    const [seatsRequest] = seatsRequests;
    const seat = seats[column]?.[row];

    if (seat?.flag && seat.id && seat.name) {
      setRequests(s => ([...s, {id: seat.id as number, type: seat.type as string, name: seat.name as string}]));
      setSeats(s => ({
        ...s,
        [column]: {
          ...s[column],
          [row]: {flag: false}
        }
      }));
    } else if (seatsRequest) {
      const {id, name, type} = seatsRequest;
      let seatNumber = '';
      let segmentClass: string;

      Object.keys(template).forEach(key => {
        const segment = template[key];
        if (row >= segment.startRow && row < segment.startRow + segment.rowsCount) {
          seatNumber = `${row}${segment.mapHeader[column][0]} (${segment.klass})`;
          segmentClass = segment.klass;
        }
      });

      setSeats(s => ({
        ...s,
        [column]: {
          ...s[column],
          [row]: {flag: !s?.[column]?.[row]?.flag, id, name, type, flyClass: segmentClass, seatNumber}
        }
      }));
      setRequests(s => s.filter(param => param.id !== id));
    }
  }, [template, seatsRequests, seats]);

  const clearSeats = useCallback(() => {
    setSeats({});
    // setRequests([]);
  }, []);

  return React.useMemo(() => {
    return {template, seats, check, seatsRequests, setSeatRequests, clearSeats};
  }, [template, seats, check, seatsRequests, setSeatRequests, clearSeats]);
}

