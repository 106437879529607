export function getArray<T>(value: string | T[] | undefined): T[] {
    if (!value) {
      return []
    }
  
    if (Array.isArray(value)) {
      return value
    }
  
    try {
      return JSON.parse(value)
    } catch (err) {
      return []
    }
  }

export function getTicketPNR(ticket: { airline: string; identifiers: string | string[] }) {
    const identifiers = getArray<string>(ticket.identifiers)
    let identifier = identifiers.find((it: string) =>
      it.toLowerCase().includes(`${ticket.airline}:`.toLowerCase()),
    )
  
    if (!identifier) {
      identifier = identifiers[0]
    }
  
    return identifier ? identifier.split(':')[1] : 'VIRTUAL'
  }