import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useContextSelector } from 'use-context-selector';
import { Button, Input, notification } from 'antd';
import { AppContext, AppContextType } from 'AppContext';
import airlineIcon from '../../assets/images/air.svg';
import { getValueFromStorage, removeValueFromStorage } from 'utils/storage';
import config from 'config';

import './TokenScreen.css';

const NOTIFICATION_KEY = 'auth_notif';

export default function TokenScreen() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const ready = useContextSelector(AppContext, (c: AppContextType) => c.reCaptcha.ready);
    const onLogin = useContextSelector(AppContext, (c: AppContextType) => c.onLogin);

    const [code, setCode] = React.useState<string>('');
    const [codeFromUrl, setCodeFromUrl] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    const onHandleChange = React.useCallback((evt: any) => {
        setCode(evt.target.value);
    }, []);

    const onSubmit = React.useCallback(() => {
        setLoading(true);
        onLogin(code)
            .then(() => {
                const myBooking = getValueFromStorage('my-booking')
                if (!!myBooking) {
                    navigate(myBooking);
                    removeValueFromStorage('my-booking');
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.error('[TokenScreen] failed to login:', err);
                api.error({
                    key: NOTIFICATION_KEY,
                    message: t('error'),
                    description: t([err.message, 'failedToCheckToken']),
                    placement: 'topRight',
                });
            })
    }, [code, onLogin, api, t, navigate]);

    useEffect(() => {
        const urlParam = new URLSearchParams(window.location.search);
        const token = urlParam.get('token');

        const isValidToken = token && /^[A-Z0-9]{1,6}$/.test(token);

        if (isValidToken) {
            setCode(token);
            setCodeFromUrl(true);
        }
    }, []);

    useEffect(() => {
        if (code && code.length === 6 && codeFromUrl && ready) {
            onSubmit()
        }
    }, [code, ready]); //eslint-disable-line

    const isDisabled = loading || !code || code.length < 6;

    return (
        <div className='TokenScreen'>
            {contextHolder}
            <div>
                <div className="TokenScreen-header">
                    <span>Matchbox</span>
                    <span>demo sandbox</span>
                </div>
                <Input
                    placeholder={t('LoginScreen.accessToken')}
                    value={code}
                    onChange={onHandleChange}
                />
                <Button
                    // disabled={!code || code.length < 6}
                    className={isDisabled ? 'TokenScreen-disabledBtn' : ''}
                    block
                    loading={loading}
                    onClick={isDisabled ? undefined : onSubmit}
                >
                    {t('submit')}
                </Button>
                <div className="TokenScreen-helpBlock">
                    <p className="TokenScreen-contactUs">{t('LoginScreen.contactUs')}</p>
                    <p>
                        {t('LoginScreen.email')}:
                        <a
                            href={`mailto:${config.SUPPORT_EMAIL}`}
                            className="TokenScreen-email"
                        >
                            {config.SUPPORT_EMAIL}
                        </a>
                    </p>

                </div>
            </div>
            <div className='flex-center homeimg'>
                <div className='circle flex-center'>
                    <div className='img'>
                        <img
                            src={airlineIcon}
                            width='100px'
                            height='200px'
                            alt=''
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
