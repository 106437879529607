import React from 'react'
import { useTranslation } from 'react-i18next';
import { Flex, Typography, Row, Col, Button } from 'antd';

import './SecurityStatement.css';

export default function SecurityStatement({ onConfirm, onExit }: { onConfirm: () => void, onExit: () => void }) {
    const { t } = useTranslation();
    return (
        <div className='SecurityStatement'>
            <Typography.Title level={2}>{t('SecurityStatement.title')}</Typography.Title>
            <Flex vertical className='SecurityStatement-Warning'>
                <Typography.Paragraph className='SecurityStatement-Paragraph'>
                    {t('SecurityStatement.carryingAndDangerous')}
                </Typography.Paragraph>
                <Row gutter={[16, 16]} justify='center'>
                    <Col className="SecurityStatement-Col" xs={24} sm={12} md={8} lg={4}>
                        <Flex vertical>
                            <span className='poisons-toxins' />
                            <Typography className='icon-description'>
                                {t('SecurityStatement.poisons')}
                            </Typography>
                        </Flex>
                    </Col>
                    <Col className="SecurityStatement-Col" xs={24} sm={12} md={8} lg={4}>
                        <Flex vertical>
                            <span className='oxidizing-agents' />
                            <Typography className='icon-description'>
                                {t('SecurityStatement.batteries')}
                            </Typography>
                        </Flex>
                    </Col>
                    <Col className="SecurityStatement-Col" xs={24} sm={12} md={8} lg={4}>
                        <Flex vertical>
                            <span className='corrosives' />
                            <Typography className='icon-description'>
                                {t('SecurityStatement.corrosives')}
                            </Typography>
                        </Flex>
                    </Col>
                    <Col className="SecurityStatement-Col" xs={24} sm={12} md={8} lg={4}>
                        <Flex vertical>
                            <span className='fireworks' />
                            <Typography className='icon-description'>
                                {t('SecurityStatement.fireworks')}
                            </Typography>
                        </Flex>
                    </Col>
                    <Col className="SecurityStatement-Col" xs={24} sm={12} md={8} lg={4}>
                        <Flex vertical>
                            <span className='lighter-fuel' />
                            <Typography className='icon-description'>
                                {t('SecurityStatement.lighterFuel')}
                            </Typography>
                        </Flex>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} justify='center'>
                    <Col className="SecurityStatement-Col" xs={24} sm={12} md={8} lg={4}>
                        <Flex vertical>
                            <span className='compressed-gas' />
                            <Typography className='icon-description'>
                                {t('SecurityStatement.compressedGas')}
                            </Typography>
                        </Flex>
                    </Col>
                    <Col className="SecurityStatement-Col" xs={24} sm={12} md={8} lg={4}>
                        <Flex vertical>
                            <span className='flammable-gas' />
                            <Typography className='icon-description'>
                                {t('SecurityStatement.flammableGas')}
                            </Typography>
                        </Flex>
                    </Col>
                    <Col className="SecurityStatement-Col" xs={24} sm={12} md={8} lg={4}>
                        <Flex vertical>
                            <span className='readioactive-materials' />
                            <Typography className='icon-description'>
                                {t('SecurityStatement.readioactiveMaterials')}
                            </Typography>
                        </Flex>
                    </Col>
                    <Col className="SecurityStatement-Col" xs={24} sm={12} md={8} lg={4}>
                        <Flex vertical>
                            <span className='infectious-substance' />
                            <Typography className='icon-description'>
                                {t('SecurityStatement.infectiousSubstance')}
                            </Typography>
                        </Flex>
                    </Col>
                    <Col className="SecurityStatement-Col" xs={24} sm={12} md={8} lg={4}>
                        <Flex vertical>
                            <span className='magnetic-materials' />
                            <Typography className='icon-description'>
                                {t('SecurityStatement.magneticMaterials')}
                            </Typography>
                        </Flex>
                    </Col>
                </Row>
                <br />
                <Typography.Paragraph className='SecurityStatement-Paragraph'>
                    {t('SecurityStatement.pleaseConfirm')}
                </Typography.Paragraph>
                <ul className='SecurityStatement-List'>
                    <li>
                        <Typography.Paragraph className='SecurityStatement-Paragraph'>
                            {t('SecurityStatement.no_prohibited_articles')}
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph className='SecurityStatement-Paragraph'>
                            {t('SecurityStatement.no_unattended_baggage')}
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph className='SecurityStatement-Paragraph'>
                            {t('SecurityStatement.no_items_added_without_knowledge')}
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph className='SecurityStatement-Paragraph'>
                            {t('SecurityStatement.no_articles_given_to_carry')}
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph className='SecurityStatement-Paragraph'>
                            {t('SecurityStatement.holding_valid_documents')}
                        </Typography.Paragraph>
                    </li>
                </ul>
            </Flex>
            <Typography.Paragraph className='SecurityStatement-Paragraph'>
                {t('SecurityStatement.confirmStatements')}
            </Typography.Paragraph>
            <Flex justify='space-between'>
                <Button
                    size='large'
                    onClick={onExit}
                >
                    {t('exit')}
                </Button>
                <Button
                    size='large'
                    type='primary'
                    onClick={onConfirm}
                >
                    {t('confirm')}
                </Button>
            </Flex>
        </div>
    )
}
