import { useState, useMemo, useCallback, Dispatch, SetStateAction, useRef, useEffect } from 'react';
import {
    PassengerForm,
    Ticket,
    Document,
    Session,
    TicketStatus,
    ValidationResult,
    Form,
    CountryDictionary,
    VaccineDictionary,
    Requirements,
    UploadRequest,
    UploadStatus,
    ValidationInfo,
    GroupedTicket, Airport,
    ValidationInfoData,
} from 'utils/checkin';
import { get, post, del } from 'utils/rest';
import config from 'config';
import { ACTIONS } from 'utils/flight';
import { getTicketPNR } from 'utils/ticket';
import { useContextSelector } from 'use-context-selector';
import { AppContext, AppContextType } from 'AppContext';

function filteredParams(params: Record<string, any>) {
    return Object.fromEntries(
        Object.entries(params).filter(([key, value]) => value != null && value !== "")
    )
}

export interface CheckInContext {
    passenger: PassengerForm | null;
    initData: { ticket_number: string | null, session_id: string | null };
    tickets: Ticket[];
    groupedTicket: GroupedTicket[];
    ticketsStatuses: Record<string, TicketStatus>;
    resultValidation: ValidationResult | null;
    form: Record<string | number, Form>;
    dictionaries: Record<string, any>;
    requirements: Requirements[] | null;
    isValidation: boolean;
    activeTicket: string;
    iframeValidationResult: ValidationInfoData[];
    currentStep: number;
    validationDirection: 'outbound' | 'inbound' | null;
    setCurrentStep: Dispatch<SetStateAction<number>>;
    setPassenger: Dispatch<SetStateAction<PassengerForm | null>>;
    setTickets: Dispatch<SetStateAction<Ticket[]>>;
    setGroupedTicket: Dispatch<SetStateAction<GroupedTicket[]>>;
    setResultValidation: Dispatch<SetStateAction<ValidationResult | null>>;
    onGetAirport: (code: string[]) => Promise<Airport[]>;
    onGetTickets: (passenger: PassengerForm, firstRequest?: boolean, isIframe?: boolean, offset?: number, controller?: AbortController | null) => Promise<Ticket[]>;
    onGetTicketsGroups: (passenger: PassengerForm, firstRequest?: boolean, controller?: AbortController | null) => Promise<GroupedTicket[]>;
    onBindTicket: (ticketNumber: string, controller?: AbortController | null) => Promise<any>;
    onGetTicketStatus: (ticketNumber: string, controller?: AbortController | null) => Promise<TicketStatus>;
    onInitSession: (ticketNumber: string, controller?: AbortController | null) => Promise<Session | null>;
    onCancelSession: (ticketNumber: string, controller?: AbortController | null) => Promise<any>;
    onDocumentRecognize: (ticketNumber: string, files: File[], upload_id?: string | null, stepId?: string | null, sessionId?: string,) => Promise<any>;
    onDeleteDocument: (docId: string, ticketNumber: string, reservationNumber: string) => Promise<any>;
    onManualRecognize: (ticketNumber: string, data: Record<string, boolean | number | string>, stepId: string, sessionId?: string,) => Promise<Document | null>;
    onGetValidationStep: (ticketNumber: string, sessionId?: string) => Promise<ValidationResult | null>;
    onBucketValidation: (ticketNumber: string) => Promise<ValidationResult | null>;
    onStandardValidation: (ticketNumber: string, skipId: string | null, sessionId?: string) => Promise<ValidationResult | null>;
    onGetJsonFormByType: (type: string, id: string, updateState?: boolean) => Promise<Form | null>;
    onGetJsonFormById: (id: string) => Promise<any>;
    onLoadDictionary: (type: string) => Promise<CountryDictionary | VaccineDictionary | null>;
    onGetRequirements: (ticketNumber: string, controller: AbortController | null) => Promise<Requirements | null>;
    setIsValidation: Dispatch<SetStateAction<boolean>>;
    setActiveTicket: Dispatch<SetStateAction<string>>;
    onGetUrlAndIdForUpload: (controller?: AbortController | null) => Promise<UploadRequest>;
    onGetUploadStatus: (id: string, controller?: AbortController | null) => Promise<UploadStatus>;
    setIframeValidationResult: Dispatch<SetStateAction<ValidationInfoData[]>>;
    setValidationDirection: Dispatch<SetStateAction<'outbound' | 'inbound' | null>>;
    clear: (force?: boolean) => void;
}

export const INIT_CHECKIN_CONTEXT: CheckInContext = {
    passenger: null,
    initData: { ticket_number: null, session_id: null },
    tickets: [],
    groupedTicket: [],
    ticketsStatuses: {},
    resultValidation: null,
    form: {},
    dictionaries: {},
    requirements: null,
    isValidation: false,
    activeTicket: '',
    iframeValidationResult: [],
    currentStep: 0,
    validationDirection: null,
    setCurrentStep: () => { },
    setPassenger: () => { },
    setTickets: () => { },
    setGroupedTicket: () => { },
    setResultValidation: () => { },
    onGetAirport: (code: string[]) => Promise.resolve([] as Airport[]),
    onGetTickets: () => Promise.resolve([]),
    onGetTicketsGroups: () => Promise.resolve([]),
    onBindTicket: () => Promise.resolve(),
    onGetTicketStatus: () => Promise.resolve({
        result: false,
        can_validate: false,
        confirmation_is_required: false,
        session: {
            short_id: null,
            id: null
        },
        validation_count: 0,
        reasons: [],
        validation_info: null
    }),
    onInitSession: () => Promise.resolve(null),
    onCancelSession: () => Promise.resolve(null),
    onDocumentRecognize: () => Promise.resolve(),
    onDeleteDocument: () => Promise.resolve(),
    onManualRecognize: () => Promise.resolve(null),
    onGetValidationStep: () => Promise.resolve(null),
    onBucketValidation: () => Promise.resolve(null),
    onStandardValidation: () => Promise.resolve(null),
    onGetJsonFormByType: () => Promise.resolve(null),
    onGetJsonFormById: () => Promise.resolve(null),
    onLoadDictionary: () => Promise.resolve(null),
    onGetRequirements: () => Promise.resolve(null),
    setIsValidation: () => { },
    setActiveTicket: () => { },
    onGetUrlAndIdForUpload: () => Promise.resolve({} as UploadRequest),
    onGetUploadStatus: () => Promise.resolve({} as UploadStatus),
    setIframeValidationResult: () => { },
    setValidationDirection: () => { },
    clear: () => { }
}

export function useCheckInContextProvider(getToken: (action: string) => Promise<string | null>, key: string | null, provider: string | null): CheckInContext {
    const [passenger, setPassenger] = useState<PassengerForm | null>(null);
    const [tickets, setTickets] = useState<Ticket[] | []>([]);
    const [groupedTicket, setGroupedTicket] = useState<GroupedTicket[] | []>([]);
    const [activeTicket, setActiveTicket] = useState<string>('');
    const [initData, setInitData] = useState<{ ticket_number: string | null, session_id: string | null }>({ ticket_number: null, session_id: null });
    const [resultValidation, setResultValidation] = useState<ValidationResult | null>(null);
    const [form, setForm] = useState<Record<string | number, Form>>({});
    const [dictionaries, setDictionaries] = useState<CountryDictionary | VaccineDictionary | {}>({});
    const [requirements, setRequirements] = useState<Requirements[] | null>(null);
    const [isValidation, setIsValidation] = useState<boolean>(false);
    const [ticketsStatuses, setTicketsStatuses] = useState<Record<string, TicketStatus>>({});
    const [iframeValidationResult, setIframeValidationResult] = useState<ValidationInfoData[]>([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [validationDirection, setValidationDirection] = useState<'outbound' | 'inbound' | null>(null);

    const keyRef = useRef<string | null>(key);

    useEffect(() => {
        keyRef.current = key;
    }, [key]);

    const clear = useCallback((force?: boolean) => {
        setInitData({ ticket_number: null, session_id: null });
        setResultValidation(null);
        setForm({});
        setDictionaries({});
        setRequirements(null);
        setIsValidation(false);
        setValidationDirection(null);
        if (force) {
            setTickets([]);
            setGroupedTicket([]);
            setTicketsStatuses({});
            setActiveTicket('');
            setIframeValidationResult([]);
            setPassenger(null);
            setCurrentStep(0);
        }
    }, []);

    const onGetAirport = useCallback((codes: string[]) => {
        return getToken(ACTIONS.getNonce)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return get(config.AIRPORTS_EP, { 'iata[]': codes }, {
                    'x-api-key': config.CHECKIN_SERVER_API_KEY,
                    'recaptcha-token': tkn,
                })
            })
            .then((res: Airport[]) => {
                return res;
            })
            .catch((err) => {
                console.log('[CheckInContext] onGetAirport error:', err);
                throw (err)
            })
    }, [getToken]);

    const onGetTicketStatus = useCallback((ticketNumber: string, controller?: AbortController | null) => {
        const { passenger_phone, ticket_number, passenger_lastname, ...requestParam } = passenger as PassengerForm;
        return getToken(ACTIONS.getTicketStatus)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return post(`${config.TICKETS_EP}/get_status`, {
                    ...filteredParams(requestParam),
                    ticket_number: ticketNumber,
                }, {
                    'x-api-key': config.CHECKIN_SERVER_API_KEY,
                    'recaptcha-token': tkn
                }, controller)
            })
            .then((status: TicketStatus) => {
                const ticket = tickets.find(ticket => ticket.ticket_number === ticketNumber) as Ticket
                setTicketsStatuses(prevStatuses => ({
                    ...prevStatuses,
                    [ticket.id]: status
                }))
                return status
            })
            .catch((err) => {
                console.log('[CheckInContext] onGetTicketStatus error:', err);
                throw (err)
            })
    }, [getToken, passenger, tickets]);

    const onGetTicketStatuses = useCallback((tickets: Ticket[]) => {
        return getToken(ACTIONS.getTicketStatus)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return Promise.all(tickets.map((ticket: Ticket) => {
                    return post(`${config.TICKETS_EP}/get_status`, {
                        ticket_number: ticket.ticket_number,
                        flight_number: ticket.flight_number,
                        flight_date: ticket.flight_date,
                        reservation_number: getTicketPNR(ticket)
                    }, {
                        'x-api-key': config.CHECKIN_SERVER_API_KEY,
                        'recaptcha-token': tkn
                    })
                        .then((res) => {
                            setTicketsStatuses(prevStatuses => ({
                                ...prevStatuses,
                                [ticket.id]: res
                            }))
                        })
                        .catch(err => {
                            if (err.message === 'FLIGHT_TICKET_NOT_FOUND') {
                                setTicketsStatuses(prevStatuses => ({
                                    ...prevStatuses,
                                    [ticket.id]: {
                                        result: false,
                                        can_validate: false,
                                        confirmation_is_required: false,
                                        session: {
                                            short_id: null,
                                            id: null
                                        },
                                        validation_count: 0,
                                        reasons: [],
                                        validation_info: null
                                    }
                                }))
                            }
                        })
                }))
            })
    }, [getToken]);

    const onGetTickets = useCallback((
        passenger: PassengerForm,
        firstRequest = false,
        isIframe = false,
        offset = 0,
        controller?: AbortController | null
    ) => {
        const { passenger_phone, ...requestParam } = passenger;
        if (firstRequest) {
            setTickets([]);
            setTicketsStatuses({});
            clear();
        }
        return getToken(ACTIONS.getTickets)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return post(`${config.TICKETS_EP}/get_list`, filteredParams({ ...requestParam, offset, limit: 80 }), {
                    'x-api-key': config.CHECKIN_SERVER_API_KEY,
                    'recaptcha-token': tkn
                }, controller)
            })
            .then((res: Ticket[]) => {
                setTickets(prev => [...prev, ...res]);
                if (isIframe) {
                    return res
                }
                onGetTicketStatuses(res)
                return res
            })
    }, [clear, getToken, onGetTicketStatuses]);

    const onGetTicketsGroups = useCallback((
        passenger: PassengerForm,
        firstRequest = false,
        controller?: AbortController | null
    ) => {
        const { passenger_phone, ...requestParam } = passenger;
        if (firstRequest) {
            setGroupedTicket([]);
            clear();
        }

        return getToken(ACTIONS.getTickets)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return post(`${config.TICKETS_EP}/get_groupped_list`, filteredParams({ ...requestParam }), {
                    'x-api-key': keyRef.current,
                    'recaptcha-token': tkn
                }, controller)
            })
            .then((res: GroupedTicket[]) => {
                setGroupedTicket(prev => [...prev, ...res]);
                return res
            })
    }, [clear, getToken]);

    const onBindTicket = useCallback((ticketNumber: string | undefined, controller?: AbortController | null) => {
        const { ticket_number, ...requestParam } = passenger as PassengerForm;
        return getToken(ACTIONS.bindTicket)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return post(`${config.TICKETS_EP}/bind`, {
                    ...filteredParams(requestParam),
                    ...ticketNumber && { ticket_number: ticketNumber },
                }, {
                    'x-api-key': config.CHECKIN_SERVER_API_KEY,
                    'recaptcha-token': tkn
                }, controller)
            })
            .then((resp: Response) => resp)
    }, [getToken, passenger]);

    const onInitSession = useCallback((ticketNumber: string, controller?: AbortController | null) => {
        const { passenger_phone, ticket_number, ...requestParam } = passenger as PassengerForm;
        return getToken(ACTIONS.initSession)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }


                return post(`${config.SESSION_EP}/init`, {
                    ...filteredParams(requestParam),
                    ticket_number: ticketNumber,
                }, {
                    'x-api-key': config.CHECKIN_SERVER_API_KEY,
                    'recaptcha-token': tkn,
                    // 'nonce-api-key': nonce
                }, controller)
            })
            .then((res: Session) => {
                setInitData({
                    ticket_number: ticketNumber,
                    session_id: res.id
                });
                return res
            })
            .catch((err) => {
                console.log('[CheckInContext] onInitSession error:', err);
                throw (err)
            })
    }, [getToken, passenger]);

    const onCancelSession = useCallback((ticketNumber: string, controller?: AbortController | null) => {
        const { passenger_phone, ticket_number, ...requestParam } = passenger as PassengerForm;
        return getToken(ACTIONS.cancelSession)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return post(`${config.SESSION_EP}/cancel`, {
                    ...filteredParams(requestParam),
                    ticket_number: ticketNumber
                }, {
                    'x-api-key': config.CHECKIN_SERVER_API_KEY,
                    'recaptcha-token': tkn,
                    // 'nonce-api-key': nonce
                }, controller)
            })
            .then((resp) => resp)
            .then(() => clear())
            .catch((err) => {
                console.log('[CheckInContext] onCancelSession', err);
                throw (err)
            })
    }, [clear, getToken, passenger]);

    const onDocumentRecognize = useCallback(async (
        ticketNumber: string,
        files: File[],
        upload_id?: string | null,
        stepId?: string | null,
        sessionId?: string,
    ) => {
        return new Promise(async (resolve: (value: Document) => void, reject) => {
            const formData = new FormData();
            const request = new XMLHttpRequest();

            files.length > 0 && files.forEach((file: File) => {
                formData.append('files', file);
            });

            upload_id && formData.append('upload_id', upload_id);

            const tknRecaptcha = await getToken(ACTIONS.documentRecognize);

            const { passenger_phone, ticket_number, ...requestParam } = passenger as PassengerForm;
            const filter = filteredParams(requestParam)

            Object.entries(filter || {}).forEach(([key, value], index) => {
                formData.append(key, value);
            })
            !!sessionId && formData.append('session_id', sessionId);
            formData.append('ticket_number', ticketNumber);
            !!stepId && formData.append('step_id', stepId);

            request.open('POST', `${config.DOCUMENT_EP}/session/recognize`);
            request.setRequestHeader('x-api-key', config.CHECKIN_SERVER_API_KEY);
            // request.setRequestHeader('nonce-api-key', nonce as string);
            !!tknRecaptcha && request.setRequestHeader('recaptcha-token', tknRecaptcha)

            request.onload = () => {
                try {
                    if ((request.status === 200) || (request.status === 201)) {
                        resolve(JSON.parse(request.response) as Document);
                    } else if (request.status === 401) {
                        reject(new Error('wrong_token'));
                    } else if ((request.status === 404) || (request.status === 405) || (request.status >= 500)) {
                        reject(new Error('service_is_temporarily_unavailable'));
                    } else {
                        const data = JSON.parse(request.response);
                        reject(data);
                    }
                } catch (err) {
                    console.log('[CheckInContext] onDocumentRecognize failed:', err);
                    reject(err);
                }
            }

            request.onerror = (e) => {
                console.log('[CheckInContext] onDocumentRecognize failed:', e);
                reject(new Error('service_is_temporarily_unavailable'))
            }
            request.send(formData);
        })
    }, [getToken, passenger]);

    const onDeleteDocument = useCallback((docId: string, ticketNumber: string, reservationNumber: string) => {
        const { passenger_phone, ticket_number, reservation_number, ...requestParam } = passenger as PassengerForm;
        return getToken(ACTIONS.deleteDocument)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return del(`${config.DOCUMENT_EP}/${docId}`, {
                    ...filteredParams(requestParam),
                    ticket_number: ticketNumber,
                    reservation_number: reservationNumber
                }, {
                    'x-api-key': config.CHECKIN_SERVER_API_KEY,
                    'recaptcha-token': tkn
                })
            })
    }, [getToken, passenger]);

    const onManualRecognize = useCallback((
        ticketNumber: string,
        form: Record<string, boolean | number | string>,
        stepId: string,
        sessionId?: string,
    ) => {
        const { passenger_phone, ticket_number, ...requestParam } = passenger as PassengerForm;
        return getToken(ACTIONS.manualRecognize)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return post(`${config.DOCUMENT_EP}/session/manual`, {
                    ...filteredParams(requestParam),
                    data: form,
                    step_id: stepId,
                    ticket_number: ticketNumber,
                    ...sessionId && { session_id: sessionId },
                }, {
                    'x-api-key': config.CHECKIN_SERVER_API_KEY,
                    'recaptcha-token': tkn,
                    // 'nonce-api-key': nonce
                })
            })
    }, [getToken, passenger]);

    const onGetValidationStep = useCallback((ticketNumber: string, sessionId?: string) => {
        const { passenger_phone, ticket_number, ...requestParam } = passenger as PassengerForm;
        return getToken(ACTIONS.getValidationStep)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return post(`${config.VALIDATION_EP}/get_validation_result`, {
                    ...requestParam,
                    ticket_number: ticketNumber,
                    ...sessionId && { session_id: sessionId },
                }, {
                    'x-api-key': config.CHECKIN_SERVER_API_KEY,
                    'recaptcha-token': tkn,
                    // 'nonce-api-key': nonce
                })
            })
            .then((res) => {
                setResultValidation(res);
                return res;
            })
    }, [getToken, passenger]);

    const onBucketValidation = useCallback((ticketNumber: string) => {
        const { passenger_phone, ticket_number, ...requestParam } = passenger as PassengerForm;
        return getToken(ACTIONS.bucketValidation)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return post(`${config.VALIDATION_EP}/bucket`, {
                    ...filteredParams(requestParam),
                    all_documents_added: true,
                    ticket_number: ticketNumber
                }, {
                    'x-api-key': config.CHECKIN_SERVER_API_KEY,
                    'recaptcha-token': tkn,
                    // 'nonce-api-key': nonce
                })
            })
            .then((res) => {
                setResultValidation(res);
                return res;
            })
    }, [getToken, passenger]);

    const onStandardValidation = useCallback((ticketNumber: string, skipId: string | null, sessionId?: string) => {
        const { passenger_phone, ticket_number, ...requestParam } = passenger as PassengerForm;
        return getToken(ACTIONS.standardValidation)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return post(`${config.VALIDATION_EP}/standard`, {
                    ticket_number: ticketNumber,
                    ...filteredParams(requestParam),
                    ...skipId && { skip_id: skipId },
                    ...sessionId && { session_id: sessionId },
                }, {
                    'x-api-key': config.CHECKIN_SERVER_API_KEY,
                    'recaptcha-token': tkn,
                    // 'nonce-api-key': nonce
                })
            })
            .then((res) => {
                setResultValidation(res);
                return res;
            })
    }, [getToken, passenger]);

    const onGetJsonFormByType = useCallback((type: string, id: string, updateState: boolean = true) => {
        return getToken(ACTIONS.getJsonByType)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return get(`${config.FORM_EP}/${type}/${id}`, {}, {
                    'x-api-key': config.CHECKIN_SERVER_API_KEY,
                    'recaptcha-token': tkn,
                    // 'nonce-api-key': nonce
                })
            })
            .then((res: Form) => {
                updateState && setForm(form => {
                    return {
                        ...form,
                        [res.groupId || res.documentId || id]: res,
                    }
                })
                return res;
            })
    }, [getToken]);

    const onGetJsonFormById = useCallback((id: string) => {
        return getToken(ACTIONS.getJsonById)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return get(`${config.FORM_EP}/${id}`, {}, {
                    'x-api-key': config.CHECKIN_SERVER_API_KEY,
                    'recaptcha-token': tkn,
                    // 'nonce-api-key': nonce
                })
            })
            .then((res: Form) => {
                setForm(form => {
                    return {
                        ...form,
                        [id]: res,
                    }
                })
                return res;
            })
    }, [getToken]);

    const onLoadDictionary = useCallback((type: string) => {
        return getToken(ACTIONS.getDictionary)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return get(`${config.SCHEMA_EP}/dictionary/${type}`, {}, {
                    'x-api-key': config.CHECKIN_SERVER_API_KEY,
                    'recaptcha-token': tkn,
                    // 'nonce-api-key': nonce
                })
            })
            .then((response: CountryDictionary | VaccineDictionary) => {
                setDictionaries((value) => ({
                    ...value,
                    [type]: response
                }));
                return response;
            })
    }, [getToken]);

    const onGetRequirements = useCallback((ticketNumber: string, controller: AbortController | null) => {
        const { passenger_phone, ...requestParam } = passenger as PassengerForm;
        return getToken(ACTIONS.getRequirements)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return post(`${config.REQUIREMENTS_EP}/get_requirements`, {
                    ...filteredParams(requestParam),
                    ticket_number: ticketNumber,
                }, {
                    'x-api-key': config.CHECKIN_SERVER_API_KEY,
                    'recaptcha-token': tkn,
                    // 'nonce-api-key': nonce
                }, controller)
            })
            .then((res) => {
                setRequirements(res)
                return res
            })
    }, [getToken, passenger]);

    const onGetUrlAndIdForUpload = useCallback((controller?: AbortController | null) => {
        return getToken(ACTIONS.getUploadUrlAndId)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return post(`${config.UPLOAD_EP}`, {
                    count: 5
                }, {
                    'x-api-key': config.CHECKIN_SERVER_API_KEY,
                    'recaptcha-token': tkn,
                    // 'nonce-api-key': nonce
                }, controller)
            })
    }, [getToken]);

    const onGetUploadStatus = useCallback((id: string, controller?: AbortController | null) => {
        return getToken(ACTIONS.getUploadStatus)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return get(`${config.UPLOAD_EP}/${id}`, {
                    ts: Date.now()
                }, {
                    'x-api-key': config.CHECKIN_SERVER_API_KEY,
                    'recaptcha-token': tkn,
                    // 'nonce-api-key': nonce
                }, controller)
            })
    }, [getToken]);

    return useMemo(() => ({
        passenger,
        tickets,
        groupedTicket,
        ticketsStatuses,
        initData,
        resultValidation,
        form,
        dictionaries,
        requirements,
        isValidation,
        activeTicket,
        iframeValidationResult,
        currentStep,
        validationDirection,
        setCurrentStep,
        setPassenger,
        setTickets,
        setGroupedTicket,
        setResultValidation,
        onGetAirport,
        onGetTickets,
        onGetTicketsGroups,
        onBindTicket,
        onGetTicketStatus,
        onInitSession,
        onCancelSession,
        onDocumentRecognize,
        onDeleteDocument,
        onManualRecognize,
        onGetValidationStep,
        onBucketValidation,
        onStandardValidation,
        onGetJsonFormByType,
        onGetJsonFormById,
        onLoadDictionary,
        onGetRequirements,
        setIsValidation,
        setActiveTicket,
        onGetUrlAndIdForUpload,
        onGetUploadStatus,
        setIframeValidationResult,
        setValidationDirection,
        clear
    }), [
        passenger,
        tickets,
        groupedTicket,
        ticketsStatuses,
        initData,
        resultValidation,
        form,
        dictionaries,
        requirements,
        isValidation,
        activeTicket,
        iframeValidationResult,
        currentStep,
        validationDirection,
        setCurrentStep,
        setPassenger,
        setTickets,
        setGroupedTicket,
        setResultValidation,
        onGetAirport,
        onGetTickets,
        onGetTicketsGroups,
        onBindTicket,
        onGetTicketStatus,
        onInitSession,
        onCancelSession,
        onDocumentRecognize,
        onDeleteDocument,
        onManualRecognize,
        onGetValidationStep,
        onBucketValidation,
        onStandardValidation,
        onGetJsonFormByType,
        onGetJsonFormById,
        onLoadDictionary,
        onGetRequirements,
        setIsValidation,
        setActiveTicket,
        onGetUrlAndIdForUpload,
        onGetUploadStatus,
        setIframeValidationResult,
        setValidationDirection,
        clear
    ])
}