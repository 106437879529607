import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import useFlightSeating from "hooks/useFlightSeating";
import {IPlaneSchemeTemplateSection, SeatKlass, Seats} from "contexts/PlaneSeatContext";
import SeatIcon from "../SeatIcon";
import './PlaneSeating.css'


export interface IPlaneSectionSeating {
  section: IPlaneSchemeTemplateSection;
  check: (row: number, column: number) => void;
  seats: Seats;
  seatsRequests: {id: number, name: string}[]
}


function PlaneSectionSeating({section, check, seats, seatsRequests}: IPlaneSectionSeating) {
  const {t} = useTranslation();
  console.log('PlaneSectionSeating: ', seats, seatsRequests);

  const onCheck = useCallback((row: number, column: number) => () => {
    console.log('PlaneSectionSeating onCheck: ', row, column);
    check(section.startRow + row, column);
  }, [section.startRow, check]);

  const header = useMemo(() => {
    return (
      <div className="PlaneSectionSeating-header" style={section.mapHeaderStyle}>
        {section.mapHeader.map(([letter, pos]) => {
          return <div
            key={letter}
            style={{left: pos}}
            className="PlaneSectionSeating-header-item">
            {letter}
          </div>;
        })}
      </div>
    )
  }, [section]);

  const rows = useMemo(() => {
    return (
      <div className="PlaneSectionSeating-rows" style={section.rowHeaderStyle}>
        {section.rows.map((row, rowIndex) => {
          const items = row.map(([pos, klass, available], column) => {
            const seat = seats?.[column]?.[section.startRow + rowIndex];
            let currentSeatIcon;

            if (klass === SeatKlass.economy2 || klass === SeatKlass.busy2) {
              currentSeatIcon = available ? seat?.flag ? SeatKlass.mySeat2 : klass : SeatKlass.busy2;
            } else if (klass === SeatKlass.economy || klass === SeatKlass.busy) {
              currentSeatIcon = available ? seat?.flag ? SeatKlass.mySeat : klass : SeatKlass.busy;
            } else if (klass === SeatKlass.business || klass === SeatKlass.businessBusy) {
              currentSeatIcon = available ? seat?.flag ? SeatKlass.businessSeat : klass : SeatKlass.businessBusy;
            } else {
              currentSeatIcon = klass;
            }

            return <div
              key={`${pos}${klass}${available}`}
              style={{left: 7 + pos}}
              className="PlaneSectionSeating-header-item">
              <button className="PlaneSectionSeating-btn"
                      onClick={onCheck(rowIndex, column)}
                      // onDoubleClick={onCheck(rowIndex, column)}
              >
                <SeatIcon klass={currentSeatIcon} seat={seat}/>
              </button>
            </div>;
          });

          return <div className="PlaneSectionSeating-row" style={{
            top: rowIndex * section.distance + (section.getSpace(rowIndex)),
          }}>
            <span className="PlaneSectionSeating-index">{section.startRow + rowIndex}</span>
            {items}
          </div>
        })}
      </div>
    )
  }, [seats, section, seatsRequests]);

  return (
    <div className="PlaneSectionSeating" style={{ height: section.baseStyle.height}}>
      <div style={section.baseStyle} className='PlaneSeating-planeMap'>
        {header}
        {rows}
      </div>
    </div>
  );
}

interface PlaneSeatingProps {
  klass?: string | undefined,
  prices?: {
    [key: string]: number
  }
}

function PlaneSeating({klass, prices}: PlaneSeatingProps) {
  const {template, check, seats, seatsRequests} = useFlightSeating();

  console.log('PlaneSeating: ', template, check, seats);

  return (
    <div className="PlaneSeating">
      <div className='PlaneSeating-plane'>
        {
          Object.keys(template).map((sectionName, index) => {
            const section = template[sectionName];
            console.log('section', klass, section.klass);
            if (klass !== section.klass.toLowerCase()) {
              return null;
            }
            return <PlaneSectionSeating
              key={sectionName}
              section={section}
              check={check}
              seats={seats}
              seatsRequests={seatsRequests}
            />
          }).filter(Boolean)
        }
      </div>
    </div>
  );
}

export default PlaneSeating;
