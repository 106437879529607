export enum Currency {
    chf,
    eur,
    usd,
    gbp
}

export const CURRENCY_KEY = 'currency';

export function getCorrectPrice(price: number) {
    return +(price).toFixed(2)
}

export function getPrice(valueInUSD: number) {
    return {
        [Currency.chf]: getCorrectPrice(valueInUSD * 0.94),
        [Currency.eur]: getCorrectPrice(valueInUSD * 0.89),
        [Currency.gbp]: getCorrectPrice(valueInUSD * 0.82),
        [Currency.usd]: valueInUSD,
    }
}

export function getCurrencyIcon(currency: Currency) {
    switch (currency) {
        case Currency.chf:
            return 'CHF';
        case Currency.eur:
            return '€';
        case Currency.usd:
            return '$';
        case Currency.gbp:
            return '£';
        default:
            return '$';
    }
}