import { Currency } from "./currency";

export const SERVICE_CLASS_TYPE = {
    checkedinbaggage: 'checkedinbaggage',
    flexibleamendment: 'flexibleamendment',
    flexiblecancellation: 'flexiblecancellation',
    foodonboard: 'foodonboard',
    handbaggage: 'handbaggage',
    prioritycheckin: 'prioritycheckin',
    refundableticket: 'refundableticket',
    seatselection: 'seatselection',
}

export const SERVICE_ORDER = [
    SERVICE_CLASS_TYPE.handbaggage,
    SERVICE_CLASS_TYPE.checkedinbaggage,
    SERVICE_CLASS_TYPE.foodonboard,
    SERVICE_CLASS_TYPE.flexibleamendment,
    SERVICE_CLASS_TYPE.seatselection,
    SERVICE_CLASS_TYPE.refundableticket
];

export interface ServiceStatus {
    active: boolean;
    [key: string]: any;
}

export interface ServiceClass {
    id: string;
    classname: string;
    services: Record<string, ServiceStatus>;

    fare: {
        adult: Record<Currency, number>;
        child: Record<Currency, number>;
        infant: Record<Currency, number>;
        tax: number;
    };
}

export interface ServiceExtraOption {
    id: string;
    text: string;
    price: Record<Currency, number>;
    meta: Record<string, any>;
}

export interface ServiceExtra {
    id: string;
    name: string;
    description: string;

    options: ServiceExtraOption[];
}

export interface SelectedServiceExtra {
    id: string;
    options: {
        type: string;
        pax_idx: number;
        pax_type: "adult" | "child" | "infant";
    }[];
}

export function getCalculatedPrices(item: ServiceClass, currency: Currency, counts: { adults: number; childs: number; infants: number }, multiplier: number = 1) {
    const resp = {
        adults: (item.fare.adult[currency] * counts.adults) * multiplier,
        childs: (item.fare.child[currency] * counts.childs) * multiplier,
        infants: (item.fare.infant[currency] * counts.infants) * multiplier,
        tax: 0,
    };
    resp.tax = (resp.adults + resp.childs + resp.infants) * item.fare.tax;
    return resp;

}

export function getOneSeatPrice(item: ServiceClass, currency: Currency, passenger: 'adult' | 'child' | 'infant', multiplier: number = 1) {
    const resp = {
        [`${passenger}s`]: item.fare[passenger][currency] * multiplier,
        tax: 0,
    };
    resp.tax = resp[`${passenger}s`] * item.fare.tax;
    return resp;
}