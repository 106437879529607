import React from 'react';
import { Select } from 'antd';
import personIcon from '../../assets/images/person.svg';


export interface PassengerSelectProps {
    value: number;
    children: any;
}

export default function PassengerSelect({ children, value }: PassengerSelectProps) {
    return (
        <Select
            style={{ width: '100%' }}
            size='large' value={`${value} passenger`}
            suffixIcon={<img src={personIcon} alt='' />}
            dropdownRender={() => (children)}
            dropdownStyle={{ padding: '20px' }}
        />
    )
}
