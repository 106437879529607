import { useMemo } from "react";
import edelweisLogo from "assets/images/logo-edelweiss.png";
import easyJetLogo from "assets/images/ej-logo.png";
import config from "config";

interface PrimaryColorAndLink {
  colorPrimary: string;
  link: string;
  logo: string;
}

const usePrimaryColorAndLink = (): PrimaryColorAndLink => {
  const getConfigByOrigin = (origin: string): PrimaryColorAndLink => {
    const configMap: Record<string, PrimaryColorAndLink> = {
      [config.EASY_JET_DOMAIN]: {
        colorPrimary: "#F60",
        link: `${window.location.origin}/easyJetStyles.css`,
        logo: easyJetLogo,
      },
    };

    return (
      configMap[origin] || {
        colorPrimary: "#E40421",
        link: "",
        logo: edelweisLogo,
      }
    );
  };

  return useMemo(() => getConfigByOrigin(window.location.origin), []);
};

export default usePrimaryColorAndLink;
