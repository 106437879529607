import {useCallback, useMemo} from 'react';
import {Row, Col, Typography, Flex, Button, Divider} from 'antd';
import {CaretRightOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Flight, FlightV2} from 'utils/flight';
import './OptionsInfo.css';
import {Pax} from "../../utils/booking";
import {useContextSelector} from 'use-context-selector';
import {Currency, getCorrectPrice, getCurrencyIcon} from 'utils/currency';
import {AppContext, AppContextType} from 'AppContext';
import {getOneSeatPrice} from 'utils/serviceClass';
import {string} from "yup";

export interface OptionsInfoProps {
    flight: FlightV2,
    passengers?: Record<'adults' | 'childs' | 'infants', Pax[]>,
    type?: 'inbound' | 'outbound'
}

export default function OptionsInfo({
                                        flight,
                                        passengers,
                                        type
                                    }: OptionsInfoProps) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const currency: Currency = useContextSelector(AppContext, (c: AppContextType) => c.wallet.currency);
    const classes = useContextSelector(AppContext, (c: AppContextType) => c.serviceClass.classes);

    const getPrice = useCallback((type: string, passenger: 'adult' | 'child' | 'infant') => {
        const flightClasses = type === 'ECONOMY'
            ? classes.find((it) => it.id.includes('standard'))
            : classes.find((it) => it.id.includes('flex'))

        if (flightClasses) {
            const price = getOneSeatPrice(flightClasses, currency, passenger, flight.route.length)
            const correctPrice = getCorrectPrice((price?.[`${passenger}s`] + price.tax));
            return correctPrice
        } else {
            return 0
        }
    }, [classes, currency, flight]);

    const totalSum = useMemo(() => {
        let total = 0;

        [passengers?.adults, passengers?.childs, passengers?.infants].forEach((passengerGroup) => {
            passengerGroup?.forEach((item: any) => {
                if (item?.flyClass) {
                    const price = getPrice(item.flyClass, item.type as 'adult' | 'child' | 'infant');
                    total += price;
                }
            });
        });

        return getCorrectPrice(total)
    }, [getPrice, passengers?.adults, passengers?.childs, passengers?.infants]);

    const passengerSeatsNumber = useMemo(() => {
        return passengers && [passengers.adults, passengers.childs, passengers.infants].map((passengers, idx) => {
            const list = passengers.map((passenger, jdx) => {
                return (
                    <Typography key={`${idx}_${jdx}`} style={{fontSize: 16, fontWeight: 'bold'}}>
                        {passenger.seat_number}
                    </Typography>
                )
            });
            return list
        })
    }, [passengers]);

    const handleBack = useCallback(() => {
        navigate('/options')
    }, []); //eslint-disable-line

    return (
        <>
            <Flex justify='space-between' align='center'>
                <Typography.Title level={5} className="options-title">
                    {t(type === 'inbound' ? 'OptionsScreen.inboundOptions' : 'OptionsScreen.outboundOptions')}
                    {/*{t('OptionsScreen.options')}*/}
                </Typography.Title>
                <Button onClick={handleBack} type='link'>
                    {<CaretRightOutlined/>}{t('OptionsScreen.changeOptions')}
                </Button>
            </Flex>
            <div className="OptionsInfo">
                <Row gutter={[16, 16]} className='OptionsInfo-Row'>
                    <Col md={4} sm={12} xs={12}>
                        <Typography.Text>{t('OptionsScreen.seatReservation')}</Typography.Text>
                    </Col>
                    <Col md={14} sm={0} xs={0} className='OptionsInfo-Col'>
                        <Flex vertical>
                            <Typography.Text>
                                {t(type === 'inbound' ? 'OptionsScreen.inboundFlight' : 'OptionsScreen.outboundFlight')}
                            </Typography.Text>
                            {passengerSeatsNumber}
                        </Flex>
                    </Col>
                    <Col md={6} sm={12} xs={12}>
                        <Flex vertical>
                            <Typography.Text className="price-text">{t('OptionsScreen.price')}</Typography.Text>
                            <Typography.Text className="price-text">{getCurrencyIcon(currency)} 0</Typography.Text>
                        </Flex>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} className='OptionsInfo-Row'>
                    <Col md={4} sm={12} xs={12}>
                        <Typography.Text>{t('OptionsScreen.baggage')}</Typography.Text>
                    </Col>
                    <Col md={14} sm={0} xs={0} className='OptionsInfo-Col'>
                        <Flex vertical>
                            <Typography.Text>{t('OptionsScreen.outboundFlight')}</Typography.Text>
                            <Typography.Text className="bold-text">{t('OptionsScreen.notSelected')}</Typography.Text>
                        </Flex>
                    </Col>
                    <Col md={6} sm={12} xs={12}>
                        <Flex vertical>
                            <Typography.Text className="price-text">{t('OptionsScreen.price')}</Typography.Text>
                            <Typography.Text className="price-text">{getCurrencyIcon(currency)} 0</Typography.Text>
                        </Flex>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} className='OptionsInfo-Row'>
                    <Col md={4} sm={12} xs={12}>
                        <Typography.Text>{t('OptionsScreen.specialMeals')}</Typography.Text>
                    </Col>
                    <Col md={14} sm={0} xs={0} className='OptionsInfo-Col'>
                        <Flex vertical>
                            <Typography.Text>
                                {t(type === 'inbound' ? 'OptionsScreen.inboundFlight' : 'OptionsScreen.outboundFlight')}
                            </Typography.Text>
                            <Typography.Text className="bold-text">{t('OptionsScreen.notSelected')}</Typography.Text>
                        </Flex>
                    </Col>
                    <Col md={6} sm={12} xs={12}>
                        <Flex vertical>
                            <Typography.Text className="price-text">{t('OptionsScreen.price')}</Typography.Text>
                            <Typography.Text className="price-text">{getCurrencyIcon(currency)} 0</Typography.Text>
                        </Flex>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} className='OptionsInfo-Row'>
                    <Col md={4} sm={12} xs={12}>
                        <Typography.Text>{t('OptionsScreen.sportsEquipment')}</Typography.Text>
                    </Col>
                    <Col md={14} sm={0} xs={0} className='OptionsInfo-Col'>
                        <Flex vertical>
                            <Typography.Text>
                                {t(type === 'inbound' ? 'OptionsScreen.inboundFlight' : 'OptionsScreen.outboundFlight')}
                            </Typography.Text>
                            <Typography.Text className="bold-text">{t('OptionsScreen.notSelected')}</Typography.Text>
                        </Flex>
                    </Col>
                    <Col md={6} sm={12} xs={12}>
                        <Flex vertical>
                            <Typography.Text className="price-text">{t('OptionsScreen.price')}</Typography.Text>
                            <Typography.Text className="price-text">{getCurrencyIcon(currency)} 0</Typography.Text>
                        </Flex>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} className='OptionsInfo-Row'>
                    <Col md={4} sm={12} xs={12}>
                        <Typography.Text>{t('OptionsScreen.offsetting')}</Typography.Text>
                    </Col>
                    <Col md={14} sm={0} xs={0} className='OptionsInfo-Col'>
                        <Flex vertical>
                            <Typography.Text>{t('OptionsScreen.oneWay')}</Typography.Text>
                            <Typography.Text className="bold-text">{t('OptionsScreen.notSelected')}</Typography.Text>
                        </Flex>
                    </Col>
                    <Col md={6} sm={12} xs={12}>
                        <Flex vertical>
                            <Typography.Text className="price-text">{t('OptionsScreen.price')}</Typography.Text>
                            <Typography.Text className="price-text">{getCurrencyIcon(currency)} 0</Typography.Text>
                        </Flex>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} className='subtotal-options-row'>
                    <Col md={12} sm={12} xs={12}>
                        <Typography.Title level={4}
                                          className='subtotal-title'>{t('OptionsScreen.subtotalOptions')}</Typography.Title>
                    </Col>
                    <Col md={12} sm={12} xs={12} style={{textAlign: 'end'}}>
                        <Typography.Title level={4}
                                          className='subtotal-title'>{getCurrencyIcon(currency)} 0</Typography.Title>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} className='total-price-row'>
                    <Col md={12} sm={12} xs={12}>
                        <Typography.Title level={3}
                                          className='total-title'>{t('OptionsScreen.totalPrice')}</Typography.Title>
                    </Col>
                    <Col md={12} sm={12} xs={12} style={{textAlign: 'end'}}>
                        <Typography.Title level={3}
                                          className='total-title'>{getCurrencyIcon(currency)} {totalSum}</Typography.Title>
                    </Col>
                </Row>
            </div>
        </>
    );
}