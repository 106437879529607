import { useCallback, useEffect, useMemo } from 'react'
import { Typography, Flex, Button, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useContextSelector } from 'use-context-selector';
import { AppContext, AppContextType } from 'AppContext';
import InfoBlock from 'components/InfoBlock';
import { FlightTicket, getRoutesByDirection, Steps, Ticket } from 'utils/checkin';
import './TicketInfo.css';


export interface TicketInfoProps {
    onChangeStep: (step: number) => void;
    isIframe?: boolean;
}

export default function TicketInfo({ onChangeStep, isIframe }: TicketInfoProps) {
    const { t } = useTranslation();
    const {
        tickets,
        groupedTicket,
        validationDirection,
        setTickets,
        setGroupedTicket,
        setValidationDirection
    } = useContextSelector(AppContext, (c: AppContextType) => c.checkin);

    const isOutoundOnly = useMemo(() => {
        return groupedTicket[0].available_directions.length === 1 && groupedTicket[0].available_directions[0] === 'outbound'
    }, [groupedTicket]);

    const optionsDirection = useMemo(() => [
        { value: 'outbound', label: t('PassengerForm.outbound'), disabled: !groupedTicket[0].available_directions.includes('outbound') },
        { value: 'inbound', label: t('PassengerForm.inbound'), disabled: !groupedTicket[0].available_directions.includes('inbound') }
    ], [groupedTicket]); //eslint-disable-line

    useEffect(() => {
        isOutoundOnly && setValidationDirection('outbound')
    }, []); //eslint-disable-line

    const flight = useMemo(() => {
        if (!tickets.length && (isIframe && !groupedTicket.length)) {
            return null
        }

        const ticket = isIframe ? groupedTicket[0]?.flight_tickets[0] : tickets[0];
        if (isIframe) {
            if (validationDirection) {
                const routes = getRoutesByDirection(groupedTicket[0]?.flight_tickets[0], validationDirection).routes;
                return routes.map((route) => ({
                    flight_number: route?.flight_number,
                    departure_airport: route?.flight_origin,
                    destination_airport: route?.flight_destination,
                    departure: dayjs(`${route?.flight_date} ${route?.flight_departure_time}`).format('MMM D, YYYY - HH:mm')
                }))
            } else {
                return (ticket as FlightTicket).routes.map((route) => ({
                    flight_number: route?.flight_number,
                    departure_airport: route?.flight_origin,
                    destination_airport: route?.flight_destination,
                    departure: dayjs(`${route?.flight_date} ${route?.flight_departure_time}`).format('MMM D, YYYY - HH:mm')
                }))
            }
        } else {
            return {
                flight_number: (ticket as Ticket)?.flight_number,
                departure_airport: (ticket as Ticket)?.flight_origin,
                destination_airport: (ticket as Ticket)?.flight_destination,
                departure: dayjs(`${(ticket as Ticket)?.flight_date} ${(ticket as Ticket)?.flight_departure_time}`).format('MMM D, YYYY - HH:mm')
            }
        }
    }, [tickets, isIframe, groupedTicket, validationDirection]);

    const passengers = useMemo(() => {
        if (!tickets.length && (isIframe && !groupedTicket.length)) {
            return null
        }

        if (isIframe) {
            return groupedTicket[0].validation_request?.paxList.map((ticket) => ({
                passenger: `${ticket.firstName} ${ticket.lastName}`,
                document_number: ticket.documentNumber || t('na'),
                ticket_number: ticket.eTicket
            }))
        } else {
            return tickets.map((ticket) => ({
                passenger: `${ticket.passenger_firstname} ${ticket.passenger_lastname}`,
                document_number: ticket.passenger_document || t('na'),
                ticket_number: ticket.ticket_number
            }))
        }

    }, [tickets, groupedTicket, isIframe]); //eslint-disable-line

    const onCheckout = useCallback(() => {
        setTickets([]);
        setGroupedTicket([]);
    }, []); //eslint-disable-line

    const onChangeDirection = useCallback((event: RadioChangeEvent) => {
        setValidationDirection(event.target.value);
    }, []); //eslint-disable-line

    if (isIframe && !groupedTicket[0].available_directions.length) {
        return (
            <div className='TicketInfo'>
                <Typography.Title type='danger' style={{ textAlign: 'center', fontSize: 18 }} className='TicketInfo-Description'>
                    {t('PassengerForm.validationNotPossible')}
                </Typography.Title>
                <InfoBlock data={passengers ? passengers : []} />
                <InfoBlock data={flight ? Array.isArray(flight) ? flight : [flight] : []} />
                <Flex justify='flex-end'>
                    <Button
                        size='large'
                        onClick={onCheckout}
                    >
                        {t('PassengerForm.checkOut')}
                    </Button>
                </Flex>
            </div>
        )
    }

    return (
        <div className='TicketInfo'>
            {
                isOutoundOnly
                    ? null
                    : (
                        <>
                            <Typography.Paragraph style={{ textAlign: 'center', fontSize: 18 }} className='TicketInfo-Description'>
                                {t('PassengerForm.selectDirection')}
                            </Typography.Paragraph>
                            <Radio.Group style={{ textAlign: 'center', marginBottom: 20 }} size='large' onChange={onChangeDirection} options={optionsDirection} />
                        </>
                    )
            }
            <Typography.Paragraph className='TicketInfo-Description'>
                {t('PassengerForm.findPassengersBooking')}
            </Typography.Paragraph>
            <Typography.Paragraph className='TicketInfo-Description'>
                {t('PassengerForm.allPassengersTogether')}
            </Typography.Paragraph>
            <InfoBlock data={passengers ? passengers : []} />
            <InfoBlock data={flight ? Array.isArray(flight) ? flight : [flight] : []} />
            <Flex justify='space-between'>
                <Button
                    size='large'
                    onClick={onCheckout}
                >
                    {t('PassengerForm.checkOut')}
                </Button>
                <Button
                    size='large'
                    type='primary'
                    disabled={!validationDirection}
                    onClick={() => onChangeStep(Steps.checkIn)}
                >
                    {t('continue')}
                </Button>
            </Flex>
        </div>
    )
}
