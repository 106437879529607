import React from 'react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { FlightPoint, FlightParams, Flight, FlightV2, generateFlightTimes } from 'utils/flight';
import { get } from 'utils/rest';
import config from 'config';

export interface FlightContext {
    flights: FlightV2[];
    getDeparturesList: () => Promise<FlightPoint[]>;
    getArrivalsList: (origin: string) => Promise<FlightPoint[]>;
    getFlights: (params: FlightParams, controller?: AbortController | null) => Promise<FlightV2[]>;
    getFlightReservedSeats: (
        flight: string,
        params: {
            airline: string;
            date: string;
            flight_departure_time: string;
            flight_arrival_time: string;
        },
        controller?: AbortController | null
    ) => Promise<string[]>;
    clear: () => void;
}

export const INIT_FLIGHT_CONTEXT: FlightContext = {
    flights: [],
    getDeparturesList: () => Promise.resolve([]),
    getArrivalsList: () => Promise.resolve([]),
    getFlights: () => Promise.resolve([]),
    getFlightReservedSeats: () => Promise.resolve([]),
    clear: () => { },
};

export function useFlightContextProvider(provider: string | null): FlightContext {
    const [flights, setFlights] = useStateWithCallbackLazy<FlightV2[]>([]);

    const getDeparturesList = React.useCallback(() => {
        return get(`${config.FLIGHTS_EP}/${provider}/origin/list`, {}, {})
            .then((resp: FlightPoint[]) => resp);
    }, [provider]);

    const getArrivalsList = React.useCallback((origin: string) => {
        return get(`${config.FLIGHTS_EP}/${provider}/destination/list`, { origin }, {})
            .then((resp: FlightPoint[]) => resp);
    }, [provider]);

    // const getFlights = React.useCallback((params: FlightParams, controller?: AbortController | null) => {
    //     return get(`${config.FLIGHTS_EP}/${config.AIRLINE}/list`, params, {}, controller)
    //         .then((resp: Flight[]) => {
    //             const result: Flight[] = resp.map((it: Flight) => {
    //                 return {
    //                     ...it,
    //                     classes: ['standard', 'flex']
    //                 }
    //             });
    //             return new Promise((res: (value: Flight[]) => void)  => {
    //                 setFlights(result, () => res(result));
    //             });                
    //         });
    // }, [setFlights]);

    const getFlights = React.useCallback((params: FlightParams, controller?: AbortController | null) => {
        return get(`${config.FLIGHTS_TRANSIT_EP}/${provider}/list`, params, {}, controller)
            .then((resp: FlightV2[]) => {
                const result: FlightV2[] = resp.map((it: FlightV2) => {
                    return {
                        ...it,
                        route: generateFlightTimes(it.route),
                        classes: ['standard', 'flex']
                    }
                });
                return new Promise((res: (value: FlightV2[]) => void) => {
                    setFlights(result, () => res(result));
                });
            });
    }, [setFlights, provider]);

    const getFlightReservedSeats = React.useCallback((
        flight: string,
        params: {
            airline: string;
            date: string;
            flight_departure_time: string;
            flight_arrival_time: string;
        },
        controller?: AbortController | null
    ) => {
        return get(
            `${config.FLIGHTS_EP}/${flight}/seats`,
            params,
            {},
            controller
        )
            .then((resp: string[]) => resp);
    }, []);

    const clear = React.useCallback(() => {
        setFlights([], () => { });
    }, [setFlights]);

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return React.useMemo(() => ({
        flights,
        getDeparturesList,
        getArrivalsList,
        getFlights,
        getFlightReservedSeats,
        clear,
    }), [
        flights,
        getDeparturesList,
        getArrivalsList,
        getFlights,
        getFlightReservedSeats,
        clear,
    ]);
}
