import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Empty, Flex } from 'antd';
import { useContextSelector } from 'use-context-selector';
import { MatchboxValidatorApi } from '../../scripts/iframeValidator';
import { AppContext, AppContextType } from 'AppContext';
import SecurityStatement from 'components/SecurityStatement';
import ShortFlightInfo from 'components/ShortFlightInfo';
import { IFRAME_EVENT } from 'utils/utilits';
import { getRoutesByDirection, Steps, Ticket, ValidationInfoData, ValidationRequest, getValidationRequest } from 'utils/checkin';
import config from 'config';
import './IframeValidator.css';
import usePrimaryColorAndLink from 'hooks/usePrimaryColorAndLink';

export interface IframeValidatorProps {
    onChangeStep: (step: number) => void;
}

export default function IframeValidator({ onChangeStep }: IframeValidatorProps) {
    const {
        clear,
        groupedTicket,
        setIframeValidationResult,
        validationDirection
    } = useContextSelector(AppContext, (c: AppContextType) => c.checkin);
    const { link } = usePrimaryColorAndLink();
    const [securityCheck, setSecurityCheck] = useState<boolean>(false);
    const validatorApiRef = useRef<MatchboxValidatorApi | null>(null);

    useEffect(() => {
        if (!securityCheck) {
            return
        }

        const validatorApi = new MatchboxValidatorApi(
            "validator",
            {
                link: link,
                variables: {},
                icons: {}
            },
            {
                ...!!groupedTicket[0] &&
                getValidationRequest(
                    groupedTicket[0].validation_request as ValidationRequest,
                    groupedTicket[0].flight_tickets[0],
                    validationDirection as 'outbound' | 'inbound'
                )
            },
            {
                targetDomain: config.CHECKIN_IFRAME,
                parentDomain: window.location.origin === 'http://localhost:3000' ? config.CHECKIN_PARENT_DOMAIN : window.location.origin,
            }
        );

        validatorApiRef.current = validatorApi;

        const handleStartValidation = (ticket: string) => {
            console.log('[IframeValidator] start validation event:', ticket);
            setIframeValidationResult((prevResult) => prevResult.filter(result => result.eTicket !== ticket));
        };

        const handleValidationResult = (data: ValidationInfoData) => {
            console.log('[IframeValidator] validationResult event:', data);
            setIframeValidationResult((prevResult) => ([...prevResult, data]));
        };

        const handleContinue = (data: null) => {
            console.log('[IframeValidator] continue event:', data);
            onChangeStep(Steps.boarding);
        };

        const handleClose = () => {
            console.log('[IframeValidator] close event');
            clear(true);
            setTimeout(() => {
                onChangeStep(Steps.detail);
            }, 0);
        };

        const handleMessage = (event: MessageEvent) => {
            switch (event?.data?.event) {
                case IFRAME_EVENT.start_validation:
                    return handleStartValidation(event?.data?.data)
                case IFRAME_EVENT.validation_result:
                    return handleValidationResult(event?.data?.data)
                case IFRAME_EVENT.continue:
                    return handleContinue(event?.data?.data)
                case IFRAME_EVENT.close:
                    return handleClose()
                default:
                    return
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, [
        clear,
        onChangeStep,
        groupedTicket,
        securityCheck,
        setIframeValidationResult,
        validationDirection
    ]);

    const handleConfirm = useCallback(() => setSecurityCheck(true), []);

    const handleExit = useCallback(() => {
        clear(true);
        onChangeStep(Steps.detail);
    }, [clear, onChangeStep]);

    const ticket: Ticket | null = useMemo(() => {
        if (!groupedTicket[0]) {
            return null
        }

        const ticket = groupedTicket[0].flight_tickets[0]

        if (validationDirection) {
            const availablRoutes = getRoutesByDirection(groupedTicket[0].flight_tickets[0], validationDirection);
            return {
                id: '',
                identifiers: ticket.identifiers,
                ticket_number: ticket.ticket_number,

                airline: availablRoutes.routes[0].airline,
                flight_number: (availablRoutes.routes || []).map((route) => route.flight_number).join(' -> '),
                flight_date: availablRoutes?.routes[0].flight_date,
                flight_departure_time: availablRoutes?.routes[0].flight_departure_time ?? '',
                flight_arrival_time: availablRoutes?.routes[availablRoutes?.routes.length - 1].flight_arrival_time ?? '',
                flight_origin: availablRoutes?.routes[0].flight_origin,
                flight_destination: availablRoutes?.routes[availablRoutes?.routes.length - 1].flight_destination,

                passenger_document: ticket.passenger_document,
                passenger_firstname: ticket.passenger_firstname,
                passenger_lastname: ticket.passenger_lastname
            }
        } else {
            return {
                id: '',
                identifiers: ticket.identifiers,
                ticket_number: ticket.ticket_number,

                airline: ticket?.routes[0].airline,
                flight_number: (ticket.routes || []).map((route) => route.flight_number).join(' -> '),
                flight_date: ticket?.routes[0].flight_date,
                flight_departure_time: ticket?.routes[0].flight_departure_time ?? '',
                flight_arrival_time: ticket?.routes[ticket?.routes.length - 1].flight_arrival_time ?? '',
                flight_origin: ticket?.routes[0].flight_origin,
                flight_destination: ticket?.routes[ticket?.routes.length - 1].flight_destination,

                passenger_document: ticket.passenger_document,
                passenger_firstname: ticket.passenger_firstname,
                passenger_lastname: ticket.passenger_lastname
            }
        }
    }, [groupedTicket, validationDirection]);

    return (
        <Flex justify='center' style={{ width: '100%' }}>
            {
                securityCheck ? (
                    <div className='Tickets'>
                        <Flex className='Tickets-Info' justify='flex-end'>
                            {ticket && (
                                <ShortFlightInfo
                                    ticket={ticket}
                                    routes={validationDirection
                                        ? getRoutesByDirection(groupedTicket[0].flight_tickets[0], validationDirection).routes
                                        : groupedTicket[0].flight_tickets[0].routes}
                                />
                            )}
                        </Flex>
                        {
                            !groupedTicket.length ? (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            ) : (
                                <div
                                    className='IframeValidator'
                                    dangerouslySetInnerHTML={{ __html: '<iframe id="validator" title="validator"></iframe>' }}
                                >
                                </div>
                            )
                        }
                    </div>
                ) : (
                    <SecurityStatement onConfirm={handleConfirm} onExit={handleExit} />
                )
            }
        </Flex>
    );
}
