import React from 'react';
import config from 'config';

export interface ReCaptchaContext {
    ready: boolean;
    getToken: (action: string) => Promise<string|null>;
}

export const INIT_RECAPTCHA_CONTEXT: ReCaptchaContext = {
    ready: false,
    getToken: () => Promise.reject(new Error('execute_recaptcha_not_available')),
};

export function useReCaptchaContextProvider(): ReCaptchaContext {
    const [ready, setReady] = React.useState<boolean>(false);

    React.useEffect(() => {
        if ((window as any).grecaptcha) {
            setReady(true);
            return;
        }

        const onLoaded = () => setReady(true);

        // Add reCaptcha
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${config.RECAPTCHA.SITE_KEY}`
        script.addEventListener("load", onLoaded)
        document.body.appendChild(script)
    }, []);

    const getToken = React.useCallback((action: string) => {
        if (!config.RECAPTCHA.SITE_KEY) {
            return Promise.resolve('-')
        }

        if (ready && (window as any).grecaptcha) {
            return new Promise((res: (value: string | null) => void, rej) => {
                (window as any).grecaptcha.ready(() => {
                    (window as any).grecaptcha
                        .execute(config.RECAPTCHA.SITE_KEY, { action })
                        .then(res)
                        .catch(rej);
                })
            });
        } else {
            return Promise.reject(new Error('execute_recaptcha_not_available'));
        }
    }, [ready]);

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return React.useMemo(() => ({
        ready,
        getToken,
    }), [
        ready,
        getToken,
    ]);
}
