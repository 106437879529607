import React from 'react';
import { Select } from 'antd';
import { useContextSelector } from 'use-context-selector';
import { AppContext, AppContextType } from 'AppContext';
import { useTranslation } from 'react-i18next';
import { Currency } from 'utils/currency';
import './LanguageSelector.css';

function LanguageSelector() {
    const { t } = useTranslation();
    const { language, setLanguage } = useContextSelector(AppContext, (c: AppContextType) => c)

    const options = React.useMemo(() => {
        return [
            {
                value: 'en',
                label: '🇬🇧 English',
            },
            {
                value: 'de',
                label: '🇩🇪 Deutsch',
            },
        ];
    }, [t]);

    return (
        <Select
            size="small"
            value={language}
            options={options}
            variant='borderless'
            onSelect={setLanguage}
            className="LanguageSelector"
        />
    );
}

export default LanguageSelector