import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Steps, Typography } from 'antd';
import { isMobile, isTablet } from 'react-device-detect';
import { useContextSelector } from 'use-context-selector';
import { AppContext, AppContextType } from 'AppContext';
import PassengerForm from '../../components/PassengerForm';
import IframeValidator from './components/IframeValidator';
import Boarding from './components/Boarding';
import './NonceScreen.css';


function NonceScreen() {
    const { t } = useTranslation();
    const { currentStep, setCurrentStep } = useContextSelector(AppContext, (c: AppContextType) => c.checkin);

    const handleChangeStep = useCallback((step: number) => {
        setCurrentStep(step);
    }, [setCurrentStep]);

    const mobileCheck = useMemo(() => isMobile && !isTablet, []);

    const steps = useMemo(() => {
        return [
            {
                title: t('CheckInScreen.passengerDetails'),
                content: <PassengerForm onChangeStep={handleChangeStep} isIframe />
            },
            {
                title: t('CheckInScreen.checkIn'),
                content: <IframeValidator onChangeStep={handleChangeStep} />,
            },
            {
                title: t('CheckInScreen.boardingPass'),
                content: <Boarding />
            }
        ]
    }, [handleChangeStep, t]);

    return (
        <div className='NonceScreen'>
            <Typography.Title>{t('LaunchScreen.checkIn')}</Typography.Title>
            <Steps
                className='NonceScreen-Steps'
                current={currentStep}
                items={steps}
                size='small'
                responsive={false}
                labelPlacement={mobileCheck ? 'vertical' : 'horizontal'}
            />
            <div className='NonceScreen-Steps-Container'>
                {steps[currentStep].content}
            </div>
        </div>
    )
}

export default NonceScreen
