import { useMemo } from 'react'
import { Col, Row, Typography } from 'antd'
import moment from 'moment';
import 'moment-duration-format';
import { useTranslation } from 'react-i18next';
import { Currency, getCurrencyIcon } from 'utils/currency';
import { Route } from 'utils/flight';

import './Flight.css';
import { Dayjs } from "dayjs";

export interface FlightProps {
    route: Route;
    mode?: 'dark' | 'white';
    currency: Currency,
    economyTotal: number,
    businessTotal: number,
    date: Dayjs
}

export default function Flight({
    route,
    mode = 'dark',
    currency,
    economyTotal,
    businessTotal,
    date
}: FlightProps) {
    const { t, i18n } = useTranslation();

    const duration = useMemo(() => {
        moment.locale(i18n.language);
        const departure_time = route.flight_departure_time;
        const arrival_time = route.flight_arrival_time;
        const a = moment(`${date.format('YYYY-MM-DD')}T${departure_time}`);
        const b = moment(`${date.format('YYYY-MM-DD')}T${arrival_time}`);
        return moment.duration(b.diff(a, 'minutes'), 'minutes').format(`h [${t('hrs')}] mm [${t('min')}]`);
    }, [i18n.language, route?.flight_departure_time, route?.flight_arrival_time, date]);

    return (
        <Row gutter={[16, 16]} className={`Flight ${mode}`}>
            <Col span={24} className='Flight-class'>
                <Row style={{ width: '100%' }}>
                    <Col span={10}>
                        <Typography>{t('flight')}</Typography>
                        <Col>
                            <Typography.Title level={4}>{route?.flight_number}</Typography.Title>
                        </Col>
                    </Col>
                    <Col span={10}>
                        {
                            (!!economyTotal || !!businessTotal) && (
                                <>
                                    <Typography>{t('class')}</Typography>
                                    <Col>
                                        {!!economyTotal && <Typography.Title level={4}>{t('OutboundDurationCard.economy')} {getCurrencyIcon(currency)} {economyTotal}</Typography.Title>}
                                        {!!businessTotal && <Typography.Title level={4}>{t('OutboundDurationCard.business')} {getCurrencyIcon(currency)} {businessTotal}</Typography.Title>}
                                    </Col>
                                </>
                            )
                        }
                    </Col>
                </Row>
            </Col>
            <Col span={24} className='Flight-direction'>
                <Row style={{ width: '100%' }}>
                    <Col xs={6}>
                        <Typography>{t('OutboundDurationCard.departure')}</Typography>
                        <Typography className='Text'>
                            {route?.origin} {route?.flight_departure_time}
                        </Typography>
                    </Col>
                    <Col xs={12} style={{ textAlign: 'center' }}>
                        <span className="ibe_flight_selection_duration--inner">
                            <span
                                className="ibe_flight_selection_duration-circle ibe_flight_selection_duration-circle-outline"
                                style={mode === 'white' ? { border: '1px dashed #333333' } : { border: '1px dashed #767676' }}
                            />
                            <span className="ibe_flight_selection_duration-circle ibe_flight_selection_duration-circle-anim">
                                <span className="ibe_flight_selection_duration-circle-anim-plane" />
                            </span>
                        </span>
                        <span className="ibe_flight_selection_duration--content" style={{ textAlign: 'center' }}>
                            <span className="ibe_flight_selection_duration--label" style={mode === 'white' ? { color: '#333333' } : { color: '#000' }}>
                                {duration}
                            </span>
                        </span>
                    </Col>
                    <Col xs={6} style={{ paddingRight: 15, textAlign: 'end' }}>
                        <Typography>{t('OutboundDurationCard.arrival')}</Typography>
                        <Typography className='Text'>
                            {route?.destination} {route?.flight_arrival_time}
                        </Typography>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
