import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Select, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { FlightPoint } from 'utils/flight';

export interface CountrySelectProps {
    name: string;
    placeholder?: string;
    value: any;
    size?: SizeType;
    style?: Record<string, any>;
    disabled?: boolean;
    icon?: React.ReactNode;
    forceUpdate?: boolean;
    onSelect: (country: FlightPoint | null) => void;
    onBlur?: (name: string, touched: boolean) => void;
    search: () => Promise<any>;
}

function EmptyContent({ children }: { children: any }) {
    return (
        <div style={{ textAlign: 'center', margin: 10 }}>
            {children}
        </div>
    )
}

function CountrySelect({ placeholder, value, size, style, disabled, icon, forceUpdate, search, onSelect }: CountrySelectProps) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [list, setList] = useState<FlightPoint[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (forceUpdate) {
            setList(null);
        }
    }, [forceUpdate]);

    useEffect(() => {
        if (!isFocused || (list && list.length > 0)) {
            setError(null);
            return
        }

        setLoading(true);
        search()
            .then((resp) => {
                setList(resp);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                setError(t([err.message, 'error_oops']))
            })
    }, [isFocused]); //eslint-disable-line

    const option = useMemo(() => {
        return list && list.map((item: FlightPoint) => ({ value: item.iata, label: item.name }))
    }, [list]);

    const handleSelect = useCallback((country: any) => {
        const res = list && list.find((item: FlightPoint) => item.iata === country.value)
        res && onSelect(res);
    }, [list, onSelect]);

    return (
        <Select
            labelInValue
            filterOption={false}
            size={size}
            // showSearch
            value={value ? { value: value?.iata, label: value?.name } : undefined}
            disabled={disabled}
            placeholder={placeholder}
            style={style}
            suffixIcon={icon}
            onSelect={handleSelect}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            virtual={false}
            notFoundContent={
                loading
                    ? <EmptyContent><Spin size='large' /></EmptyContent>
                    : error
                        ? <EmptyContent><Typography style={{ color: 'red' }}>{error}</Typography></EmptyContent>
                        : list && !list.length
                            ? <EmptyContent><Typography style={{ fontWeight: 500 }}>{t('not_found')}</Typography></EmptyContent>
                            : null
            }
            options={option || []}
            labelRender={(item) => <span>{item.label}, {item.value}</span>}
            optionRender={(item) => <span>{item.label}, {item.value}</span>}
        />
    );
}

export default CountrySelect