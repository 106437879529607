export interface Pax {
  type: 'adult' | 'child' | 'infant';
  phone?: string;
  phone_country?: string;
  email?: string;
  fn: string;
  mn: string;
  ln: string;
  dob?: string;
  address?: string;
  city?: string;
  zip?: string;
  document_type?: string;
  document_number: string;
  document_expiry_date?: string;
  document_nationality?: string;
  ticket_number?: string;
  //new
  sex: "MR" | "MS";
  seat_number: string;
  flyClass?: string;
  price?: string;
  document_issued_at: string; //country alpha2
}

export interface Ticket {
  document_nationality: string;
  document_issued_at: string;
  document_expiry_date: string;
  document_number: string;
  document_type: string;
  zip: string;
  city: string;
  address: string;
  sex: string;
  dob: string;
  ln: string;
  fn: string;
  seat_number: string;
  service_class: string;
  ticket_number?: string;
  status?: string;
  id?: string;
  type: 'adult' | 'child' | 'infant';
  pnr?: string;
  adl?: string;
}

export interface InitBookingParams {
  language: "DE" | "EN";
  routes: {
    airline: string;
    flight_number: string;
    flight_date: string;
    flight_departure_time: string | null;
    flight_arrival_time: string | null;
    flight_origin: string;
    flight_destination: string;
  }[];
  phone: string;
  email: string;
  tickets: Ticket[];
  destination_airport: string | null
}

export interface Booking {
  tickets: Ticket[];
  routes?: {
    airline: string;
    flight_number: string;
    flight_date: string;
    flight_departure_time: string | null;
    flight_arrival_time: string | null;
    flight_origin: string;
    flight_destination: string;
  }[];
  service_class: string;
  airline: string;
  flight_number: string;
  flight_date: string;
  flight_origin: string;
  flight_origin_name: string;
  flight_origin_country: string;
  flight_destination: string;
  flight_destination_name: string;
  flight_destination_country: string;
  flight_departure_time: string | null;
  flight_arrival_time: string | null;
  flight_ticket_id: string;
  flight_ticket_identifier: string;
  flight_ticket_provider: string;
  email: string;
  phone?: string;
  created_at: number;
  updated_at: number;
  deleted_at: number;

}

export enum documentType {
  P = "P",
  I = "I",
}

export enum pasSexOption {
  MR = "MR",
  MS = "MS",
}

export enum Direction {
  Forward = 'outbound',
  Back = 'inbound',
  Empty = 'empty',
}
