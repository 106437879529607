export function setValueToStorage(field: string, value: string) {
    window.localStorage && window.localStorage.setItem(field, value);
}

export function getValueFromStorage(field: string): string | null {
    if (window.localStorage) {
        return window.localStorage.getItem(field);
    }

    return null;
}

export function removeValueFromStorage(field: string) {
    window.localStorage && window.localStorage.removeItem(field);
}