import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';
import { useContextSelector } from 'use-context-selector';
import { Typography, Calendar as AntCalendar, Button, Flex } from 'antd';
import { AppContext, AppContextType } from 'AppContext';
import usePaxCount from 'hooks/usePaxCount';
import { Currency, getCorrectPrice, getCurrencyIcon } from 'utils/currency';
import { getCalculatedPrices, ServiceClass } from 'utils/serviceClass';
import './Calendar.css';


export interface CalendarProps {
    autoFocus?: boolean
    availableMonth: Dayjs;
}

function Calendar({ availableMonth, autoFocus }: CalendarProps) {
    const { t, i18n } = useTranslation();
    const paxCount = usePaxCount();
    const flights = useContextSelector(AppContext, (c: AppContextType) => c.flight.flights);
    const classes = useContextSelector(AppContext, (c: AppContextType) => c.serviceClass.classes);
    const currency: Currency = useContextSelector(AppContext, (c: AppContextType) => c.wallet.currency);
    const {
        date: outDate,
        isOneWay,
        setDate: setOutDate
    } = useContextSelector(AppContext, (c: AppContextType) => c.booking);
    const {
        date: backDate,
        setDate: setBackDate
    } = useContextSelector(AppContext, (c: AppContextType) => c.bookingBackFlight);
    const setShowDrawer = useContextSelector(AppContext, (c: AppContextType) => c.booking.setShowDrawer);
    const [months, setMonths] = useState<Dayjs[]>([]);
    const [selectedDate, setSelectedDate] = useState<undefined | Dayjs>(undefined);
    const [isFocused, setIsFocused] = useState(false);
    const calendarRef = useRef<HTMLDivElement | null>(null);

    // const [outDate, setOutDate] = useState<Dayjs>();
    // const [backDate, setBackDate] = useState<Dayjs>();

    useEffect(() => {
        availableMonth && setMonths([availableMonth])
    }, [availableMonth]);

    useEffect(() => {
        if (!autoFocus || isFocused) {
            return
        }

        const observer = new MutationObserver(() => {
            if (calendarRef.current) {
                calendarRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                setIsFocused(true)
            }
        });

        observer.observe(document.body, { childList: true, subtree: true });

        return () => observer.disconnect();
    }, [autoFocus, isFocused]);

    const flightClasses: ServiceClass[] = React.useMemo(() => {
        return classes?.filter((it: ServiceClass) => flights[0]?.classes?.includes(it.id));
    }, [classes, flights]);

    const price = React.useMemo(() => {
        const prices = flightClasses?.map((it: ServiceClass) => {
            const prices = getCalculatedPrices(it, currency, paxCount, 1)
            return (prices.adults + prices.childs + prices.infants + prices.tax)
        });
        return getCorrectPrice(Math.min(...prices));
    }, [flightClasses, currency, paxCount]);

    const onShowMonth = useCallback(() => {
        setMonths(prev => [...prev, dayjs(prev[prev.length - 1]).add(1, 'month')]);
    }, []);

    const dateFullCellRender = React.useCallback((current: Dayjs, month: any) => {
        const isSameOrBefore = dayjs().isSameOrBefore(current, 'day');
        if (current.month() !== month.month()) {
            return <div
                className="ant-picker-cell-inner ant-picker-calendar-date"
                style={{ visibility: 'hidden', pointerEvents: 'none' }}
            >
                {current.date()}
            </div>

        }

        let isSelected = current.isSame(outDate, 'day');
        let isSelectedStartEnd = current.isSame(outDate, 'day');

        if (backDate) {
            isSelected = (current.isAfter(outDate, 'day') && current.isBefore(backDate, 'day'));
            isSelectedStartEnd = (current.isSame(outDate, 'day') || current.isSame(backDate, 'day'));
        }

        return (
            <div className={`ant-picker-cell-inner ant-picker-calendar-date ${isSelected ? 'selected' : isSelectedStartEnd ? 'selected-start-end' : ''}`}>
                <div className={`ant-picker-calendar-date-value ${isSameOrBefore ? 'flight-date' : ''}`}>{current.date()} </div>
                {isSameOrBefore
                    ? <div className={`ant-picker-calendar-date-content ${isMobile && !isTablet ? 'mobile' : ''}`}>
                        {isMobile && !isTablet ? Math.round(price) : t('fromCurrencyPrice', { currency: getCurrencyIcon(currency), price: Math.round(price) })}
                    </div>
                    : <div className="ant-picker-calendar-date-content" />
                }
            </div>
        );
    }, [currency, price, selectedDate, isMobile, outDate, backDate]); //eslint-disable-line

    const renderCalendar = useCallback((data: Dayjs, idx: number) => {
        return (
            <div ref={calendarRef} key={`${data.format('YYYY-MM-DD')}_${idx}`}>
                <Typography.Title level={3}>{data.locale(i18n.language).format('MMMM YYYY')}</Typography.Title>
                <AntCalendar
                    className='Calendar'
                    defaultValue={data}
                    disabledDate={date => (dayjs().isAfter(date, 'date') || date.month() !== data.month())}
                    fullCellRender={(date) => dateFullCellRender(date, data)}
                    onSelect={(date, { source }) => {
                        if (source === 'date') {
                            if (outDate && date.isBefore(outDate, 'day')) {
                                setOutDate(date);
                                setBackDate(null);
                                return;
                            }

                            if (outDate && backDate) {
                                setOutDate(date);
                                setBackDate(null);
                            } else if (outDate) {
                                setBackDate(date);
                            } else {
                                setOutDate(date);
                            }

                            //TODO: exclude this case in code
                            if (backDate && !outDate) {
                                setBackDate(null);
                            }

                            if(date.isBefore(outDate, 'day') || date.isSame(outDate, 'day')) {
                                setOutDate(date);
                                setBackDate(null);
                            }
                        }
                    }}
                />
            </div>
        )
    }, [dateFullCellRender, i18n.language, setShowDrawer, outDate, backDate]);

    useEffect(() => {
        if (isOneWay && outDate) {
            setShowDrawer(true);
        } else if(outDate && backDate && !outDate.isSame(backDate, 'day')) {
            setShowDrawer(true);
        }
    }, [isOneWay, outDate, backDate, setShowDrawer]);

    return (
        <div>
            {months.map(renderCalendar)}
            <Flex justify='center'>
                <Button type='primary' onClick={onShowMonth}>
                    {t('showAdditionalMonth')}
                </Button>
            </Flex>
        </div>
    );
}

export default memo(Calendar)
