import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Typography, Row, Col } from 'antd';
import dayjs from 'dayjs';
import { BookingTickets } from 'utils/mybooking';
import planeLoader from 'assets/images/plane-loader.gif';
import edelweissBarcode from 'assets/images/edelweiss-barcode.svg';
import { Airport, getRoutesByDirection } from 'utils/checkin';
import './BoardingPass.css';
import usePrimaryColorAndLink from 'hooks/usePrimaryColorAndLink';
import { useContextSelector } from 'use-context-selector';
import { AppContext, AppContextType } from 'AppContext';

function extractText(input: string): string | null {
    const match = input.match(/\(([^)]+)\)/);
    return match ? match[1] : null;
}

function capitalizeLetter(input: string): string {
    return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
}

export interface BoardingPassProps {
    booking: BookingTickets | null;
    airports?: Record<string, Airport>;
    fn?: string | null | undefined;
    ln?: string | null | undefined;
    loading: boolean;
    error: string | null;
    direction: 'outbound' | 'inbound' | null;
}

export default function BoardingPass({ booking, airports, fn, ln, loading, error, direction }: BoardingPassProps) {
    const { t } = useTranslation();
    const { logo } = usePrimaryColorAndLink();
    const provider = useContextSelector(AppContext, (c: AppContextType) => c.configAirline.provider);
    const rotatedImgRef = useRef<HTMLImageElement | null>(null);
    const imgWrapperRef = useRef<HTMLDivElement | null>(null);

    const passenger = useMemo(() => {
        return booking?.flight_tickets.find((ticket) =>
            ticket?.passenger_firstname?.toLocaleLowerCase() === fn?.toLocaleLowerCase() &&
            ticket?.passenger_lastname?.toLocaleLowerCase() === ln?.toLocaleLowerCase()
        )
    }, [booking?.flight_tickets, fn, ln]);

    const routes = useMemo(() => {
        if (direction) {
            if (!booking?.flight_tickets[0]) {
                return []
            }
            const routes = getRoutesByDirection(booking?.flight_tickets[0], direction)?.routes
            return routes
        } else {
            const flightDate = passenger?.routes[0]?.flight_date;
            return (passenger?.routes || []).filter((route) => route.flight_date === flightDate);
        }
    }, [booking?.flight_tickets, direction, passenger?.routes]);

    const { seat_number, fligth_class, service_class } = useMemo(() => {
        const directPassengerData = (passenger?.validation_info_list || []).find((info) => info.direction === direction || info.direction === 'full');
        if (directPassengerData) {
            return {
                seat_number: directPassengerData.booking?.seat_number,
                fligth_class: directPassengerData.booking && extractText(directPassengerData.booking?.seat_number),
                service_class: directPassengerData.booking?.service_class
            }
        } else {
            return {
                seat_number: t('na'),
                fligth_class: t('na'),
                service_class: t('na')
            }
        }
    }, [passenger, t, direction]);

    if (loading) {
        return (
            <Flex vertical align="center" justify="center" style={{ height: '100%' }}>
                <img src={planeLoader} alt="" />
                <Typography style={{ fontWeight: 500, fontSize: 16 }}>
                    {t('BoardingPass.loading')}
                </Typography>
            </Flex>
        )
    }

    if (error || !booking) {
        <Flex vertical align="center" justify="center" style={{ height: '100%' }}>
            <Typography.Title level={3} style={{ color: 'red' }}>{t('BoardingPass.loadingError')}</Typography.Title>
            <Typography style={{ fontWeight: 500, fontSize: 16 }}>
                {error}
            </Typography>
        </Flex>
    }

    return (
        <>
            <div className='BoardingPass'>
                <div className='BoardingPass-Header'>
                    <img src={edelweissBarcode} alt='barcode' />
                    <img src={logo} alt='logo' />
                </div>
                {
                    routes?.map((route, idx) => {
                        return (
                            <div key={`${route.flight_number}_${idx}`} className='BoardingPass-Ticket'>
                                <div className='BoardingPass-Ticket-Header' />
                                <div className='BoardingPass-Ticket-Content'>
                                    <div className='BoardingPass-Ticket-Info'>
                                        <Row gutter={[16, 16]} className='BoardingPass-Ticket-Row'>
                                            <Col xs={24} sm={12} md={8}>
                                                <Typography.Paragraph className='BoardingPass-Ticket-Paragraph'>{t('BoardingPass.airline')}</Typography.Paragraph>
                                                <Typography.Text>{t('BoardingPass.edelweiss', {airline: provider === '94@' ? 'EasyJet' : 'Edelweiss'})}</Typography.Text>
                                            </Col>
                                            <Col xs={24} sm={12} md={8}>
                                                <Typography.Paragraph className='BoardingPass-Ticket-Paragraph'>{t('BoardingPass.passenger')}</Typography.Paragraph>
                                                <Typography.Text>{passenger ? `${!!passenger.booking?.sex ? `${passenger.booking.sex}.` : ''} ${passenger.passenger_firstname} ${passenger.passenger_lastname}` : t('na')}</Typography.Text>
                                            </Col>
                                            <Col xs={24} sm={12} md={8}>
                                                <Typography.Paragraph className='BoardingPass-Ticket-Paragraph'>{t('BoardingPass.ticketNumber')}</Typography.Paragraph>
                                                <Typography.Text>{passenger?.ticket_number ?? t('na')}</Typography.Text>
                                            </Col>
                                        </Row>

                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} sm={8}>
                                                <Typography.Paragraph className='BoardingPass-Ticket-Paragraph'>{t('BoardingPass.from')}</Typography.Paragraph>
                                                <Typography.Text>{airports?.[route.flight_origin]?.name ?? route?.flight_origin_name ?? t('na')} ({route.flight_origin})</Typography.Text>
                                            </Col>
                                            <Col xs={24} sm={8}>
                                                <Typography.Paragraph className='BoardingPass-Ticket-Paragraph'>{t('BoardingPass.to')}</Typography.Paragraph>
                                                <Typography.Text>{airports?.[route.flight_destination]?.name ?? route?.flight_destination_name ?? t('na')} ({route.flight_destination})</Typography.Text>
                                            </Col>
                                            <Col xs={24} sm={8}>
                                                <Typography.Paragraph className='BoardingPass-Ticket-Paragraph'>{t('BoardingPass.flightNumber')}</Typography.Paragraph>
                                                <Typography.Text>{route?.flight_number ?? t('na')}</Typography.Text>
                                            </Col>
                                        </Row>

                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} sm={8}>
                                                <Typography.Paragraph className='BoardingPass-Ticket-Paragraph'>{t('BoardingPass.date')}</Typography.Paragraph>
                                                <Typography.Text>{route?.flight_date ? dayjs(route.flight_date).format('DD MMM YYYY') : t('na')}</Typography.Text>
                                            </Col>
                                            <Col xs={24} sm={8}>
                                                <Typography.Paragraph className='BoardingPass-Ticket-Paragraph'>{t('BoardingPass.boardingTime')}</Typography.Paragraph>
                                                <Typography.Text>{route?.flight_departure_time ?? t('na')}</Typography.Text>
                                            </Col>
                                            <Col xs={24} sm={8}>
                                                <Typography.Paragraph className='BoardingPass-Ticket-Paragraph'>{t('BoardingPass.departureTime')}</Typography.Paragraph>
                                                <Typography.Text>{route?.flight_arrival_time ?? t('na')}</Typography.Text>
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 16]} className='BoardingPass-Ticket-Row'>
                                            <Col xs={24} sm={8} />
                                            <Col xs={24} sm={8} >
                                                <Typography.Paragraph className='BoardingPass-Ticket-Paragraph'>{t('BoardingPass.seat')}</Typography.Paragraph>
                                                <Typography.Text>{seat_number}</Typography.Text>
                                            </Col>
                                            <Col xs={24} sm={8} >
                                                <Typography.Paragraph className='BoardingPass-Ticket-Paragraph'>{t('BoardingPass.serviceClass')}</Typography.Paragraph>
                                                <Typography.Text>{service_class}</Typography.Text>
                                            </Col>
                                        </Row>
                                        <Flex justify='space-between'>
                                            <Typography.Title className='BoardingPass-Ticket-Title'>{t('BoardingPass.boarding')}</Typography.Title>
                                            <Typography.Title className='BoardingPass-Ticket-Title'>{t('BoardingPass.adoc')}</Typography.Title>
                                            <Typography.Title className='BoardingPass-Ticket-Title'>{t('BoardingPass.flightClass', { class: fligth_class ? capitalizeLetter(fligth_class) : t('na') })}</Typography.Title>
                                        </Flex>
                                    </div>
                                    <div ref={imgWrapperRef} className="BoardingPass-Ticket-Barcode">
                                        <img
                                            ref={rotatedImgRef}
                                            src={edelweissBarcode}
                                            alt="barcode"
                                            style={{ transform: 'rotate(90deg)' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                <div className='BoardingPass-Notice'>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12}>
                            {t('BoardingPass.departureGateWarning')}
                        </Col>
                        <Col xs={24} sm={12}>
                            {t('BoardingPass.liquidItemsNotice')}
                        </Col>
                        <Col xs={24} sm={12}>
                            {t('BoardingPass.handLuggageOnlyInfo')}
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}
