import React from 'react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { FlightPoint, ACTIONS, FlightV2 } from 'utils/flight';
import { Pax, InitBookingParams, Booking } from 'utils/booking';
import { SelectedServiceExtra } from 'utils/serviceClass';
import { BookingTickets } from 'utils/mybooking';
import { post, get } from 'utils/rest';
import config from 'config';
import { Dayjs } from "dayjs";

export interface BookingContext {
    date: Dayjs | null;
    setDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;

    isOneWay: boolean;
    setIsOneWay: React.Dispatch<React.SetStateAction<boolean>>;

    departure: FlightPoint | null,
    setDeparture: React.Dispatch<React.SetStateAction<FlightPoint | null>>,
    arrival: FlightPoint | null,
    setArrival: React.Dispatch<React.SetStateAction<FlightPoint | null>>,
    flight: FlightV2 | null;
    setFlight: React.Dispatch<React.SetStateAction<FlightV2 | null>>;
    reserved: string[];
    setReserved: React.Dispatch<React.SetStateAction<string[]>>;

    serviceClass: string | null;
    setServiceClass: React.Dispatch<React.SetStateAction<string | null>>;

    extras: SelectedServiceExtra[];
    setExtras: React.Dispatch<React.SetStateAction<SelectedServiceExtra[]>>;

    counts: {
        adults: number;
        childs: number;
        infants: number;
    };
    setCounts: React.Dispatch<React.SetStateAction<{
        adults: number;
        childs: number;
        infants: number;
    }>>;

    passengers: Record<'adults' | 'childs' | 'infants', Pax[]>;
    setPassengers: (value: Record<'adults' | 'childs' | 'infants', Pax[]>, cb: () => void) => void;

    createBooking: (props: InitBookingParams) => Promise<{ pnr: string }>;
    searchBookings: (pnl: string, lastName: string) => Promise<Booking | null>;

    showCalendar: boolean;
    setShowCalendar: React.Dispatch<React.SetStateAction<boolean>>;

    showDrawer: boolean,
    setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>;

    booking: BookingTickets | null;
    setBooking: React.Dispatch<React.SetStateAction<BookingTickets | null>>;
    searchBookingsInCheckin: (pnl: string, lastName: string, ticketNumber?: string) => Promise<any>;

    clear: () => void;
}

export const INIT_BOOKING_CONTEXT: BookingContext = {
    date: null,
    setDate: () => { },

    isOneWay: false,
    setIsOneWay: () => { },

    departure: null,
    setDeparture: () => { },
    arrival: null,
    setArrival: () => { },
    flight: null,
    setFlight: () => { },
    reserved: [],
    setReserved: () => { },

    serviceClass: null,
    setServiceClass: () => { },

    extras: [],
    setExtras: () => { },

    counts: {
        adults: 0,
        childs: 0,
        infants: 0,
    },
    setCounts: () => { },

    passengers: { adults: [], childs: [], infants: [] },
    setPassengers: () => { },

    createBooking: () => Promise.resolve({ pnr: '' }),
    searchBookings: () => Promise.resolve(null),

    showCalendar: false,
    setShowCalendar: () => { },

    showDrawer: false,
    setShowDrawer: () => { },

    booking: null,
    setBooking: () => { },
    searchBookingsInCheckin: () => Promise.resolve(null),

    clear: () => { },
};

export function useBookingContextProvider(getToken: (action: string) => Promise<string | null>, key: string | null, provider: string | null): BookingContext {
    const [date, setDate] = React.useState<Dayjs | null>(null);
    const [isOneWay, setIsOneWay] = React.useState<boolean>(false);

    const [departure, setDeparture] = React.useState<FlightPoint | null>(null);
    const [arrival, setArrival] = React.useState<FlightPoint | null>(null);
    const [flight, setFlight] = React.useState<FlightV2 | null>(null);
    const [reserved, setReserved] = React.useState<string[]>([]);
    const [serviceClass, setServiceClass] = React.useState<string | null>(null);
    const [extras, setExtras] = React.useState<SelectedServiceExtra[]>([]);
    const [showCalendar, setShowCalendar] = React.useState<boolean>(false);
    const [showDrawer, setShowDrawer] = React.useState<boolean>(false);
    const [booking, setBooking] = React.useState<BookingTickets | null>(null);

    const keyRef = React.useRef<string | null>(key);
    const providerRef = React.useRef<string | null>(provider);

    React.useEffect(() => {
        keyRef.current = key;
        providerRef.current = provider;
    }, [key, provider]);

    const [counts, setCounts] = React.useState<{
        adults: number;
        childs: number;
        infants: number;
    }>({
        adults: 0,
        childs: 0,
        infants: 0,
    });

    const [passengers, setPassengers] = useStateWithCallbackLazy<Record<'adults' | 'childs' | 'infants', Pax[]>>({
        adults: [],
        childs: [],
        infants: [],
    });

    const clear = React.useCallback(() => {
        setDate(null);
        setDeparture(null);
        setArrival(null);
        setFlight(null);
        setReserved([]);
        setServiceClass(null);
        setExtras([]);
        setShowCalendar(false);
        setShowDrawer(false);
        setBooking(null);
        setIsOneWay(false);
        setCounts({
            adults: 0,
            childs: 0,
            infants: 0,
        });
        setPassengers({ adults: [], childs: [], infants: [] }, () => { });
    }, [setPassengers]);

    const createBooking = React.useCallback((props: InitBookingParams): Promise<{ pnr: string }> => {
        return getToken(ACTIONS.create)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return post(`${config.BOOKING_EP_V2}/${provider}`, { ...props, token: tkn }, {})
            })
            .then((resp: { pnr: string }) => resp);
    }, [getToken, provider]);

    const searchBookings = React.useCallback((pnl: string, lastName: string) => {
        return get(`${config.BOOKING_EP_V2}/${providerRef.current}/search`, { pnl, last_name: lastName }, {})
            .then((resp: Booking | null) => resp);
    }, []);

    const searchBookingsInCheckin = React.useCallback((pnl: string, lastName: string, ticketNumber?: string) => {
        return getToken(ACTIONS.create)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return post(`${config.BOOKING_SEARCH_V2_EP}/search`, {
                    pnl,
                    last_name: lastName,
                    ticket_number: ticketNumber
                }, {
                    'x-api-key': keyRef.current,
                    'recaptcha-token': tkn
                })
            })
            .then((resp: BookingTickets) => {
                setBooking(resp);
                return resp
            });
    }, [getToken]);

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return React.useMemo(() => ({
        date, setDate,

        departure, setDeparture,
        arrival, setArrival,
        flight, setFlight,
        reserved, setReserved,
        serviceClass, setServiceClass,
        extras, setExtras,

        counts, setCounts,
        passengers, setPassengers,

        createBooking,
        searchBookings,

        showCalendar,
        setShowCalendar,

        showDrawer,
        setShowDrawer,

        isOneWay,
        setIsOneWay,

        booking,
        setBooking,
        searchBookingsInCheckin,

        clear,
    }), [
        date, setDate,

        departure, setDeparture,
        arrival, setArrival,
        flight, setFlight,
        reserved, setReserved,
        serviceClass, setServiceClass,
        extras, setExtras,

        counts, setCounts,
        passengers, setPassengers,

        createBooking,
        searchBookings,

        showCalendar,
        setShowCalendar,

        showDrawer,
        setShowDrawer,

        isOneWay,
        setIsOneWay,

        booking,
        setBooking,
        searchBookingsInCheckin,

        clear,
    ]);
}
