export enum Steps {
  detail,
  checkIn,
  boarding,
}

export enum variantValidator {
  standard,
  bucket,
}

export const DOCUMENT_TYPE = {
  not_recognized: "fasttrack_not_recognized",
};

export interface PassengerForm {
  flight_number: string;
  flight_date: string;

  passenger_document?: string;
  passenger_firstname?: string;
  passenger_lastname?: string;
  passenger_phone: string;

  ticket_number?: string;
  reservation_number?: string;
}

export interface PassengerFormRequest {
  flight_number: string;
  flight_date: string;

  passenger_document?: string;
  passenger_firstname?: string;
  passenger_lastname?: string;

  ticket_number?: string;
  reservation_number?: string;
}

export interface PaxList {
  documentNumber: string;
  sex: string;
  dob: string;
  lastName: string;
  middleName: string;
  firstName: string;
  eTicket: string;
}

export interface Routes {
  flightOrigin: string;
  flightDate: string;
  flightID: string;
}

export interface Docs {
  firstName: string | null;
  lastName: string | null;
  dateofBirth: string | null;
  gender: string | null;
  documentNumber: string | null;
  documentName: string | null;
  dateOfExpiry: string | null;
}

export interface TravelDoc {
  dateofBirth: string | null;
  gender: string | null;
  documentNumber: string | null;
  dateOfExpiry: string | null;
  documentName: string | null;
}

export interface ValidationRequest {
  paxList: PaxList[];
  pnr: string;
  itinerary: {
    destination: string;
    routes: Routes[];
  };
  nonce: string;
  direction?: "outbound" | "inbound" | undefined;
}

export interface ValidationInfoData {
  adoc?: boolean;
  prn: string;
  eTicket: string;
  firstName: string;
  lastName: string;
  travelDoc: TravelDoc;
  docs: Docs[];
  signature?: string;
}

export interface ValidationInfo {
  direction: "outbound" | "inbound" | "full";
  booking?: {
    adl: string;
    status: "accepted" | "rejected" | null;
    document_nationality?: string;
    document_issued_at?: string;
    document_expiry_date?: string;
    document_number?: string;
    document_type?: string;
    zip?: string;
    city?: string;
    address?: string;
    sex?: string;
    dob?: string;
    type: string;
    seat_number: string;
    service_class: string;
    email?: string;
    phone?: string;
  };
  data: ValidationInfoData;
}

export interface ValidationResultInfo {
  adoc: boolean;
  signature: string;
  pnr: string;
  eTicket: string;
  firstName: string;
  lastName: string;
  travelDoc: TravelDoc;
  docs: Docs[];
}

export interface Ticket {
  id: string;
  identifiers: string[];
  ticket_number: string;

  airline: string;
  flight_number: string;
  flight_date: string;
  flight_departure_time: string;
  flight_arrival_time: string;
  flight_origin: string;
  flight_destination: string;

  passenger_document: string | null;
  passenger_firstname: string | null;
  passenger_lastname: string | null;
  status?: TicketStatus;
}

export interface FlightTicketRoute {
  airline: string;
  flight_number: string;
  flight_date: string;
  flight_departure_time: string | null;
  flight_arrival_time: string | null;
  flight_origin: string;
  flight_destination: string;
  flight_origin_name: string;
  flight_destination_name: string;
}

export interface FlightTicket {
  id: string;
  identifiers: string[];
  ticket_number: string;
  routes: FlightTicketRoute[];
  passenger_firstname: string | null;
  passenger_lastname: string | null;
  passenger_document: string | null;
}

export interface FlightTicketExpanded extends FlightTicket {
  booking?: {
    adl: string;
    status: "accepted" | "rejected" | null;
    document_nationality?: string;
    document_issued_at?: string;
    document_expiry_date?: string;
    document_number?: string;
    document_type?: string;
    zip?: string;
    city?: string;
    address?: string;
    sex?: string;
    dob?: string;
    type: string;
    seat_number: string;
    service_class: string;
    email?: string;
    phone?: string;
  };
  flight?: {
    origin_name: string | null;
    destination_name: string | null;
  };
  validation_info_list?: ValidationInfo[] | null;
  destination_airport: string;
}

export interface GroupedTicket {
  available_directions: ("outbound" | "inbound")[];
  flight_tickets: FlightTicketExpanded[];
  validation_request: ValidationRequest | null;
}

export interface Airport {
  code: string;
  countryCode: string;
  iata: string;
  icao: string;
  name: string;
}

export interface TicketStatus {
  result: boolean;
  can_validate: boolean;
  confirmation_is_required: boolean;
  session: {
    id: string | null;
    short_id: string | null;
  };
  validation_count: number;
  reasons: string[];
  validation_info: ValidationInfo | null;
}

export interface Session {
  id: string;
  short_id: string;
}

export interface DocumentFile {
  hash: string | null;
  link: string;
  position: number;
  uploaded?: boolean;
}

export interface Document {
  id: string;
  user_id: string;
  session_id: string;
  form_id: string;
  form_version: string | null;
  type: string;
  data: string;
  files: DocumentFile[];
  category: string | null;
  recognizer: string | null;
  verified_manual: number;
  verified_auto: number;
  created_by: string;
  updated_by: string | null;
  auto_verification_result: string;
  provider: string;
  created_at: number;
  updated_at: number;
  deleted: number;
  version: string;
  hashes: string;
  created_from: number;
  updated_from: number;
}

interface SchemaField {
  id: number;
  list: number[];
  vaccines: number[];
  name: string;
  label: string;
  type: number;
  description: string;
  user_help: string;
}

export interface SchemaDocumentsGroup {
  id: string;
  name: string;
  label: string;
  parent: {};
  category: number;
  doc_fields: SchemaField[];
  doc_types: string[];
  user_help: string;
  description: string;
}

export interface SchemaDocument {
  id: number;
  category: number;
  name: string;
  types: string;
  description?: string;
  user_help: string;
}

interface SchemaStepHelp {
  type: string;
  document_group: string | null;
  document: number | null;
  label: string;
  value: number;
}

interface SchemaDocumentOrGroup {
  type: string;
  label: string;
  value: string | number;
}

export interface SchemaStep {
  id: string;
  else: string;
  then: string;
  help: SchemaStepHelp | null;
  needForm: boolean;
  forceForm: boolean;
  documentOrGroup: SchemaDocumentOrGroup;
  hint: string | null;
}

export interface ValidationResulItem {
  id: string;
  category: number;
  name: string;
  description: string;
  user_help: string;
  fields: SchemaField;
  documents: number[];
}

export interface ValidationResult {
  result: boolean;
  confirmation_is_required: boolean;
  finished: boolean;
  session: {
    id: string;
    short_id: string;
  };
  next?: {
    skip_id: string | null;
    form_identifier: string | null;
    item: ValidationResulItem | null | undefined;
    step: SchemaStep;
  };
  reasons?: string[];
  validation_info: ValidationInfo;
}

export interface Form {
  id: number;
  documentId: string;
  documentTypes: string[];
  groupId: string;
  json: string;
  recognizer: string;
  title: string;
}

export interface CountryDictionary {
  id: string;
  name?: string;
  countries: any;
  is_organization: boolean;
  code2?: string;
  code3: string;
  created_at: string;
  updated_at: string;
}

export interface VaccineDictionary {
  id: string;
  key: string;
  display: string;
  lang: string;
  active: boolean;
  system: string;
  version: string;
  field: string | null;
}

export interface Requirements {
  id: string;
  version: string;
  data: string;
  path?: string;
  created_at: number;
  updated_at: number;
}

export interface UploadRequest {
  url: string;
  id: string;
}

export interface UploadStatus {
  id: string;
  files: string[];
  meta: {
    count: string;
    description: string;
    title: string;
  };
}

export function getRoutesByDirection(
  flight_ticket: FlightTicketExpanded,
  direction?: "outbound" | "inbound",
  isIndexOnly?: boolean
): { routes: FlightTicketRoute[]; index: number | null } {
  if (!direction) {
    return {
      routes: flight_ticket.routes,
      index: null,
    };
  }

  const destinationIndex = flight_ticket.routes.findIndex(
    (it) => it.flight_destination === flight_ticket.destination_airport
  );

  if (destinationIndex === -1) return { routes: [], index: null };

  if (isIndexOnly) {
    return {
      routes: [],
      index: destinationIndex,
    };
  }

  let routes: FlightTicketRoute[] = [];

  if (direction === "outbound") {
    routes = flight_ticket.routes.filter((it, idx) => idx <= destinationIndex);
  } else if (direction === "inbound") {
    routes = flight_ticket.routes.filter((it, idx) => idx > destinationIndex);
  }

  return { routes, index: destinationIndex };
}

export function getValidationRequest(
  validation_request: ValidationRequest,
  flight_ticket: FlightTicketExpanded,
  direction?: "outbound" | "inbound"
): ValidationRequest {
  if (!direction) {
    return validation_request;
  }

  const flightIndex = getRoutesByDirection(flight_ticket, direction).index;

  if (flightIndex === null) {
    return validation_request;
  }

  const shouldIncludeRoute = (i: number) =>
    direction === "outbound" ? i <= flightIndex : i > flightIndex;

  return {
    ...validation_request,
    itinerary: {
      ...validation_request.itinerary,
      destination:
        direction === "inbound"
          ? validation_request.itinerary.routes[0].flightOrigin
          : validation_request.itinerary.destination,
      routes: validation_request.itinerary.routes.reduce<Routes[]>(
        (acc, route, i) => {
          if (shouldIncludeRoute(i)) {
            acc.push(route);
          }
          return acc;
        },
        []
      ),
    },
  };
}
