import React, {useMemo} from 'react'
import {Typography, Flex, Button, Row, Col,} from 'antd';
import {PlusCircleOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {useContextSelector} from 'use-context-selector';
import {AppContext, AppContextType} from 'AppContext';
import usePaxCount from 'hooks/usePaxCount';
import {Currency, getCorrectPrice} from 'utils/currency';
import {ServiceClass, getCalculatedPrices} from 'utils/serviceClass';
import FlightInformationTwoDirect from 'components/FlightInfrormationTwoDirect';
import OutboundInfo from 'components/OutboundInfo';
import Option from './components/Option';
import BoardingPassDriver from 'components/BoardingPassDriver';
import {FlightV2, Route} from 'utils/flight';
import {STEPS} from 'utils/utilits';
import searReservation from '../../assets/images/options/seat_reservation.jpg';
import insurance from '../../assets/images/options/insurance_allianz.jpg';
import additionalBaggage from '../../assets/images/options/additional_baggage.jpg';
import specialMeals from '../../assets/images/options/special_meal.jpg';
import sportEquipment from '../../assets/images/options/sports_equipment.jpg';
import climate from '../../assets/images/options/my_climate.jpg';

import './OptionsScreen.css';
import FlightInformation from "../../components/FlightInfrormation";

export default function OptionsScreen() {
    const {t} = useTranslation();
    const paxCount = usePaxCount();
    const bookingContext = useContextSelector(AppContext, (c: AppContextType) => c.booking);
    const bookingBackContext = useContextSelector(AppContext, (c: AppContextType) => c.bookingBackFlight);

    const classes = useContextSelector(AppContext, (c: AppContextType) => c.serviceClass.classes);
    const currency: Currency = useContextSelector(AppContext, (c: AppContextType) => c.wallet.currency);

    const flightClasses: ServiceClass[] = useMemo(() => {
        if (!bookingContext.flight) {
            return []
        }
        return classes.filter((it: ServiceClass) => bookingContext.flight?.classes?.includes(it.id));
    }, [classes, bookingContext]);

    const price = useMemo(() => {
        if (bookingContext.serviceClass) {
            const item = flightClasses.find(it => it.id === bookingContext.serviceClass);
            if (item) {
                const prices = getCalculatedPrices(item, currency, paxCount, bookingContext.flight?.route.length);
                return getCorrectPrice(Math.min(prices.adults + prices.childs + prices.infants + prices.tax))
            }
        }
        const prices = flightClasses.map((it: ServiceClass) => {
            const prices = getCalculatedPrices(it, currency, paxCount);
            return (prices.adults + prices.childs + prices.infants + prices.tax)
        });
        return getCorrectPrice(Math.min(...prices))
    }, [bookingContext.serviceClass, bookingContext.flight?.route.length, flightClasses, currency, paxCount]);

    const passengerSeatsNumber = useMemo(() => {
        return [bookingContext.passengers.adults, bookingContext.passengers.childs, bookingContext.passengers.infants].map((passengers, idx) => {
            const list = passengers.map((passenger, jdx) => {
                return (
                    <Typography key={`${idx}_${jdx}`} style={{fontSize: 14, fontWeight: 'bold'}}>
                        {passenger.seat_number}
                    </Typography>
                )
            });
            return list
        })
    }, [bookingContext.passengers.adults, bookingContext.passengers.childs, bookingContext.passengers.infants]);

    const options = useMemo(() => {
        return [
            {
                title: t('OptionsScreen.seatReservation'),
                img: searReservation,
                content: (
                    <>
                        <Typography.Text>{t('OptionsScreen.oneWay')}</Typography.Text>
                        <div>
                            {passengerSeatsNumber}
                        </div>
                    </>
                ),
                actions: [<Button type="link" style={{fontSize: 16}} icon={< PlusCircleOutlined/>}
                                  iconPosition='end'> {t('OptionsScreen.add')}</Button>]
            },
            {
                title: t('OptionsScreen.insurance'),
                img: insurance,
                content: <Typography.Text>{t('OptionsScreen.insuranceDesc')}</Typography.Text>,
                actions: [<Button type="link" style={{fontSize: 16}} icon={<PlusCircleOutlined/>}
                                  iconPosition='end'>{t('OptionsScreen.add')}</Button>]
            },
            {
                title: t('OptionsScreen.baggage'),
                img: additionalBaggage,
                content: <Typography.Text>{t('OptionsScreen.baggageDesc')}</Typography.Text>,
                actions: [<Button type="link" style={{fontSize: 16}} icon={<PlusCircleOutlined/>}
                                  iconPosition='end'>{t('OptionsScreen.add')}</Button>]
            },
            {
                title: t('OptionsScreen.specialMeals'),
                img: specialMeals,
                content: <Typography.Text>{t('OptionsScreen.specialMealsDesc')}</Typography.Text>,
                actions: [<Button type="link" style={{fontSize: 16}} icon={<PlusCircleOutlined/>}
                                  iconPosition='end'>{t('OptionsScreen.add')}</Button>]
            },
            {
                title: t('OptionsScreen.sportsEquipment'),
                img: sportEquipment,
                content: <Typography.Text>{t('OptionsScreen.sportsEquipmentDesc')}</Typography.Text>,
                actions: [<Button type="link" style={{fontSize: 16}} icon={<PlusCircleOutlined/>}
                                  iconPosition='end'>{t('OptionsScreen.add')}</Button>]
            },
            {
                title: t('OptionsScreen.climate'),
                img: climate,
                content: <Typography.Text>{t('OptionsScreen.climateDesc')}</Typography.Text>,
                actions: [<Button type="link" style={{fontSize: 16}} icon={<PlusCircleOutlined/>}
                                  iconPosition='end'>{t('OptionsScreen.add')}</Button>]
            },
        ]
    }, [passengerSeatsNumber, t]);

    const outbound = useMemo(() => {
        return {
            routes: bookingContext.flight?.route as Route[],
            originCountryName: bookingContext.departure?.country,
            destinationCountryName: bookingContext.arrival?.country,
            date: bookingContext.date
        }
    }, [bookingContext, price]);

    const inbound = useMemo(() => {
        return {
            routes: bookingBackContext.flight?.route as Route[],
            originCountryName: bookingBackContext.departure?.country,
            destinationCountryName: bookingBackContext.arrival?.country,
            date: bookingBackContext.date
        }
    }, [bookingContext, price]);

    const isOneWay = useMemo(() => {
        return bookingContext.isOneWay;
    }, [bookingContext]);

    return (
        <div className='PaymentScreen'>
            <Flex vertical>
                {
                    isOneWay ? (
                        <FlightInformation
                            {...outbound}
                            counts={{adults: paxCount.adults, childs: paxCount.childs}}
                            mode="white"
                        />
                    ) : (
                        <FlightInformationTwoDirect
                            outbound={outbound}
                            inbound={inbound}
                            passengers={bookingContext.passengers}
                            counts={{adults: paxCount.adults, childs: paxCount.childs}}
                            mode="white"
                        />
                    )
                }
                <OutboundInfo
                    flight={bookingContext.flight as FlightV2}
                    date={bookingContext.date}
                    counts={{adults: paxCount.adults, childs: paxCount.childs}}
                    passengers={bookingContext.passengers}
                    mode="white"
                    type={'outbound'}
                />

                {
                    !isOneWay ? (
                        <OutboundInfo
                            flight={bookingBackContext.flight as FlightV2}
                            date={bookingBackContext.date}
                            counts={{adults: paxCount.adults, childs: paxCount.childs}}
                            passengers={bookingBackContext.passengers}
                            mode="white"
                            type={'inbound'}
                        />
                    ) : null
                }
            </Flex>
            <Typography.Title level={2} style={{marginTop: 35}}>{t('OptionsScreen.title')}</Typography.Title>
            <Row gutter={[16, 16]}>
                {
                    options.map((option, idx) => (
                        <Col span={12} key={idx}>
                            <Option
                                title={option.title}
                                img={option.img}
                                content={option.content}
                                actions={option.actions}
                            />
                        </Col>
                    ))
                }
            </Row>
            <BoardingPassDriver nextStep="/passenger-data" step={STEPS.options}/>
        </div>
    )
}
