import moment from "moment";

export function getMS(time: string) {
    const [hours, minutes] = time.split(':');
    return (parseInt(hours, 10) * 60 * 60 * 1000) + (parseInt(minutes, 10) * 60 * 1000) 
}

export function getDuration(ms: number) {
    const minutes = Math.floor(ms / (1000 * 60));
    const mins = minutes % 60;
    const hours = Math.floor(minutes / 60);

    return {
        hours: hours,
        minutes: mins,
    };
}

export function get2D(value: number) {
    return value < 9 ? `0${value}` : value.toString();
}
export function formatDate(date: Date | null): string {
    if (!date) {

      return "";
    }
   
    return moment(date).format("DD-MMM-YYYY");
  }

export function getFractionOfTime(startTime: string, endTime: string, fraction: number) {
    var start = startTime.split(':').map(Number);
    var end = endTime.split(':').map(Number);

    var startMinutes = start[0] * 60 + start[1];
    var endMinutes = end[0] * 60 + end[1];

    var intermediateMinutes = Math.round(startMinutes + (endMinutes - startMinutes) * fraction);

    var hours = Math.floor(intermediateMinutes / 60);
    var minutes = intermediateMinutes % 60;

    var formattedHours = hours < 10 ? '0' + hours : hours;
    var formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    return formattedHours + ':' + formattedMinutes;
}