import React from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

interface PhoneNumberProps {
    name: string
    value: string;
    defaultCountry: string;
    placeholder?: string;
    onChange: (name: string, value: string, country: string) => void;
    onBlur?: (name: string, touched: boolean) => void;
}
const PhoneNumber: React.FC<PhoneNumberProps> = React.memo(({ name, value, defaultCountry, placeholder, onChange, onBlur }) => {
    const handleChange = React.useCallback((phone: string, meta: { country: any }) => {
        onChange(name, phone, meta.country.iso2 as string);
    }, [name, onChange])

    const handleBlur = React.useCallback(() => {
        onBlur && onBlur(name, true);
    }, [name, onBlur]);

    return (
        <div>
            <PhoneInput
                defaultCountry={defaultCountry}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                inputStyle={{ width: "100%" }}
                autoFocus={false}
                placeholder={placeholder}
            />
        </div>
    );
});

export default PhoneNumber;