import { Drawer, Flex, Typography } from 'antd';
import { useEffect, useRef, useState } from 'react'
import { MatchboxValidatorApi } from "../../../NonceScreen/scripts/iframeValidator";
import { ValidationInfo, ValidationRequest } from "../../../../utils/checkin";
import config from "../../../../config";
import { IFRAME_EVENT } from "../../../../utils/utilits";
import { useTranslation } from "react-i18next";
import usePrimaryColorAndLink from 'hooks/usePrimaryColorAndLink';
import './IframeDrawer.css';


export interface IframeDrawerProps {
  open: boolean,
  onClose: () => void,
  onError: (err: object) => void,
  direction: string,
  validation_request?: ValidationRequest,
  onIframeValidationResult?: (result: any) => void
}

export default function IframeDrawer({ open, direction, onClose, onError, validation_request, onIframeValidationResult }: IframeDrawerProps) {
  const { t } = useTranslation();
  const { link } = usePrimaryColorAndLink();
  const [securityCheck] = useState<boolean>(false);
  const validatorApiRef = useRef<MatchboxValidatorApi | null>(null);

  useEffect(() => {
    if (!open) {
      return;
    }
    const validatorApi = new MatchboxValidatorApi(
      "myValidator",
      {
        link: link,
        variables: {},
        icons: {}
      },
      { ...validation_request },
      {
        targetDomain: config.CHECKIN_IFRAME,
        parentDomain: window.location.origin
      }
    );

    validatorApiRef.current = validatorApi;

    const handleValidationResult = (data: ValidationInfo) => {
      console.log('[IframeValidator] validationResult event:', data);
      if (onIframeValidationResult) {
        onIframeValidationResult(data);
      }
    };

    const handleContinue = (data: null) => {
      console.log('[IframeValidator] continue event:', data);
      onClose();
    };

    const handleClose = () => {
      console.log('[IframeValidator] close event');
      onClose();
    };

    const handleError = (err: object) => {
      console.log('[IframeValidator] error event', err);
      onError(err);
    };

    const handleMessage = (event: MessageEvent) => {
      switch (event?.data?.event) {
        case IFRAME_EVENT.validation_result:
          return handleValidationResult(event?.data?.data)
        case IFRAME_EVENT.continue:
          return handleContinue(event?.data?.data)
        case IFRAME_EVENT.close:
          return handleClose()
        case IFRAME_EVENT.error:
          return handleError(event?.data?.data)
        default:
          return
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [
    open,
    securityCheck,
    direction
  ]);

    return (
        <Drawer
            height={'100%'}
            title={<Typography style={{fontSize: 24, color: '#FFF'}}>{t('IframeDrawer.title')}</Typography>}
            placement="bottom"
            onClose={onClose}
            open={open}
            classNames={{header: 'IframeDrawer-header', body: 'IframeDrawer-body', footer: 'IframeDrawer-footer'}}
        >
            <Flex justify='center' style={{width: '100%'}}>
                <div className='IframeDrawer-content'>
                    {
                        open && (
                            <div
                                style={{width: '100%', height: '100%'}}
                                dangerouslySetInnerHTML={{__html: '<iframe id="myValidator" title="myValidator"></iframe>'}}
                            >
                            </div>
                        )
                    }
                </div>
            </Flex>
        </Drawer>
    )
}
