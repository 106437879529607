import {useCallback, useMemo} from 'react';
import {Row, Col, Typography, Flex, Button} from 'antd';
import {useContextSelector} from 'use-context-selector';
import {CaretRightOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {AppContext, AppContextType} from 'AppContext';
import {Route, Route as IRoute} from 'utils/flight';
import {Pax} from "../../utils/booking";
import dayjs, {Dayjs} from "dayjs";
import './FlightInformationTwoDirect.css';
import {normalizeCountry} from "../../utils/countries";


export interface FlightInformationProps {
  counts?: {
    adults: number;
    childs: number;
  },
  mode?: 'dark' | 'white',
  showNavigation?: boolean,
  outbound?: {
    date: Dayjs | null;
    routes: Route[];
    destinationCountryName: string | undefined;
    originCountryName: string | undefined
  },
  inbound?: {
    date: dayjs.Dayjs | null;
    routes: Route[];
    destinationCountryName: string | undefined;
    originCountryName: string | undefined
  },
  passengers?: Record<"adults" | "childs" | "infants", Pax[]>,
}

export default function FlightInformationTwoDirect({
                                                     counts,
                                                     mode = 'white',
                                                     showNavigation = false,
                                                     passengers,
                                                     outbound,
                                                     inbound,
                                                   }: FlightInformationProps) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const setDate = useContextSelector(AppContext, (c: AppContextType) => c.booking.setDate);

  const handleBack = useCallback(() => {
    navigate('/');
  }, [setDate]); //eslint-disable-line

  const passengersInfo = useMemo(() => {
      return Object.entries(counts || {}).filter(([, value]) => value > 0).map((count, idx) => (
        <Row key={idx} gutter={5}>
          <Col>
            <Typography style={{fontSize: 16, fontWeight: 'bold'}}>{count[1]}</Typography>
          </Col>
          <Col>
            <Typography style={{fontSize: 16, fontWeight: 'bold'}}>{count[0]}</Typography>
          </Col>
        </Row>
      ));
    },
    [counts, passengers]
  );

  if (!outbound?.routes || !inbound?.routes) return null;

  // TODO: use chain for detect start end routes
  const startRoute = outbound?.routes[0];
  const endRoute = outbound?.routes[outbound?.routes?.length - 1];

  return (
    <>
      {
        mode === 'white' && (
          <Flex justify='space-between' align='center'>
            <Typography.Title level={5} style={{marginBottom: 0}}>{t('travelData')}</Typography.Title>
            {showNavigation &&
              <Button onClick={handleBack} type='link'>{<CaretRightOutlined/>}{t('changeTravelData')}</Button>}
          </Flex>
        )
      }
      <div className={`FlightInformationTwoDirect ${mode}`}>
        <div className='FlightInformationTwoDirect-routes'>
          <Col className='Route-diraction' span={24}>
            <Row>
              <Col span={10}>
                <Typography>{t('SearchFlight.from')}</Typography>
                <Row align="middle" gutter={10}>
                  <Col>
                    <Typography.Title>{startRoute?.origin}</Typography.Title>
                  </Col>
                  <Col>
                    <Typography className='Text'>
                      {startRoute?.origin_name}
                    </Typography>
                    {
                      normalizeCountry(outbound?.originCountryName) !== 'N/a' && (
                        <Typography.Text className='Text' ellipsis>
                          {normalizeCountry(outbound?.originCountryName)}
                        </Typography.Text>
                      )
                    }
                  </Col>
                </Row>
              </Col>
              <Col span={4} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <span className="Route-icon"/>
              </Col>
              <Col span={10}>
                <Typography>{t('SearchFlight.to')}</Typography>
                <Row align="middle" gutter={10}>
                  <Col>
                    <Typography.Title>{endRoute?.destination}</Typography.Title>
                  </Col>
                  <Col>
                    <Typography className='Text'>
                      {endRoute?.destination_name}
                    </Typography>
                    {
                      normalizeCountry(outbound?.destinationCountryName) !== 'N/a' && (
                        <Typography.Text className='Text' ellipsis>
                          {normalizeCountry(outbound?.destinationCountryName)}
                        </Typography.Text>
                      )
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24} className=''>
            <Row>
              <Col span={10}>
                <Typography>{t('SearchFlight.outboundFlight')}</Typography>
                <Typography className='Text'>
                  {dayjs(outbound?.date).format('dddd, MMMM D, YYYY')}
                </Typography>
              </Col>
              <Col span={4} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              </Col>
              <Col span={10}>
                <Typography>{t('SearchFlight.inboundFlight')}</Typography>
                <Typography className='Text'>
                  {dayjs(inbound?.date).format('dddd, MMMM D, YYYY')}
                </Typography>
              </Col>
            </Row>
          </Col>
        </div>
        <Row style={{paddingLeft: 15, borderLeft: `1px solid ${mode === 'dark' ? '#333333' : '#FFF'}`}}>
          <Col span={24}>
            <Typography>{t('SearchFlight.passengers')}</Typography>
            <div>{passengersInfo}</div>
          </Col>
        </Row>
      </div>
    </>
  );
}