import React, { SetStateAction, useEffect, useRef } from 'react';
import { createContext } from 'use-context-selector';
import config from './config';
import { ReCaptchaContext, INIT_RECAPTCHA_CONTEXT, useReCaptchaContextProvider } from './contexts/ReCaptchaContext';
import { NetworkContext, INIT_NETWORK_CONTEXT, useNetworkContextProvider } from './contexts/NetworkContext';
import { WalletContext, INIT_WALLET_CONTEXT, useWalletContextProvider } from 'contexts/WalletContext';
import { FlightContext, INIT_FLIGHT_CONTEXT, useFlightContextProvider } from 'contexts/FlightContext';
import { ServiceClassContext, INIT_SERVICE_CLASS_CONTEXT, useServiceClassContextProvider } from 'contexts/ServiceClassContext';
import { BookingContext, INIT_BOOKING_CONTEXT, useBookingContextProvider } from 'contexts/BookingContext';
import { CheckInContext, INIT_CHECKIN_CONTEXT, useCheckInContextProvider } from 'contexts/CheckInContext';
import { DocRequestContext, INIT_DOCREQUEST_CONTEXT, useDocRequestContextProvider } from 'contexts/DocRequestContext';
import { SeatsContext, INIT_SEATS_CONTEXT, usePlaneSeatContext } from "contexts/PlaneSeatContext";
import { getValueFromStorage, setValueToStorage, removeValueFromStorage } from 'utils/storage';
import { LANGUAGE_KEY } from 'utils/utilits';
import { post } from 'utils/rest';
import { TOKEN_KEY } from 'helpers/constants';
import { ACTIONS } from 'utils/flight';
import { ConfigContext, INIT_CONFIG_CONTEXT, useConfigContextProvider } from 'contexts/ConfigContext';


export interface AppContextType {
    token: string | null;
    activeTab: string;
    onLogin: (code: string) => Promise<string>;
    onLogout: () => void;
    language: 'de' | 'en';
    setLanguage: (value: 'de' | 'en') => void;
    clear: () => void;
    reCaptcha: ReCaptchaContext;
    network: NetworkContext;
    wallet: WalletContext;
    flight: FlightContext;
    seats: SeatsContext,
    serviceClass: ServiceClassContext;
    booking: BookingContext;
    bookingBackFlight: BookingContext;
    checkin: CheckInContext;
    docRequest: DocRequestContext;
    configAirline: ConfigContext
    setActiveTab: React.Dispatch<SetStateAction<string>>;
}

export const INIT_NEW_APP_CONTEXT: AppContextType = {
    token: null,
    activeTab: '0',
    onLogin: () => { return Promise.resolve('-'); },
    onLogout: () => { },
    language: 'en',
    setLanguage: () => { },
    clear() { },
    reCaptcha: INIT_RECAPTCHA_CONTEXT,
    network: INIT_NETWORK_CONTEXT,
    wallet: INIT_WALLET_CONTEXT,
    flight: INIT_FLIGHT_CONTEXT,
    serviceClass: INIT_SERVICE_CLASS_CONTEXT,
    booking: INIT_BOOKING_CONTEXT,
    bookingBackFlight: INIT_BOOKING_CONTEXT,
    seats: INIT_SEATS_CONTEXT,
    checkin: INIT_CHECKIN_CONTEXT,
    docRequest: INIT_DOCREQUEST_CONTEXT,
    configAirline: INIT_CONFIG_CONTEXT,
    setActiveTab: () => { },
};

function getLanguage() {
    const value = getValueFromStorage(LANGUAGE_KEY);
    if (value && ['de', 'en'].includes(value)) {
        return value as ('de' | 'en');
    }

    return 'en';
}

export const AppContext = createContext<AppContextType>(INIT_NEW_APP_CONTEXT);


export function useAppContextProvider() {
    const [language, setLanguage] = React.useState<'de' | 'en'>(getLanguage());
    const [token, setToken] = React.useState<string | null>(getValueFromStorage(TOKEN_KEY));
    const reCaptcha = useReCaptchaContextProvider();
    const configAirline = useConfigContextProvider();
    const network = useNetworkContextProvider();
    const wallet = useWalletContextProvider();
    const flight = useFlightContextProvider(configAirline.provider);
    const serviceClass = useServiceClassContextProvider();
    const booking = useBookingContextProvider(reCaptcha.getToken, configAirline.key, configAirline.provider);
    const bookingBackFlight = useBookingContextProvider(reCaptcha.getToken, configAirline.key, configAirline.provider);
    const checkin = useCheckInContextProvider(reCaptcha.getToken, configAirline.key, configAirline.provider);
    const docRequest = useDocRequestContextProvider(reCaptcha.getToken);
    const seats = usePlaneSeatContext();

    const keyRef = useRef<string | null>(configAirline.key);

    useEffect(() => {
        keyRef.current = configAirline.key;
    }, [configAirline.key]);

    const [activeTab, setActiveTab] = React.useState<string>('0');

    const clear = React.useCallback(() => {
        booking.clear();
        flight.clear();
        checkin.clear(true);
        seats.clearSeats();
    }, [booking, flight, checkin, seats]);

    const handleSetLanguage = React.useCallback((value: 'de' | 'en') => {
        setValueToStorage(LANGUAGE_KEY, value.toString());
        setLanguage(value);
    }, []);

    const onLogin = React.useCallback((code: string) => {
        return reCaptcha.getToken(ACTIONS.token)
            .then((tkn: string | null) => {
                if (!tkn) {
                    throw new Error('warning_recaptcha');
                }

                return post(`${config.AUTH_EP}`, { code, token: tkn, }, { 'x-api-key': keyRef.current })
            })
            .then((token: string) => {
                const tkn = token.replace(/"/g, '');
                setValueToStorage(TOKEN_KEY, tkn);
                setToken(tkn);
                return tkn;
            });
    }, [reCaptcha]);

    const onLogout = React.useCallback(() => {
        removeValueFromStorage(TOKEN_KEY);
        setToken(null);
        clear();
    }, [clear]);

    const context = React.useMemo<AppContextType>(() => ({
        token, activeTab, onLogin, onLogout,
        language, setLanguage: handleSetLanguage,
        clear,
        reCaptcha,
        network,
        wallet,
        seats,
        flight,
        serviceClass,
        booking,
        bookingBackFlight,
        checkin,
        docRequest,
        configAirline,
        setActiveTab
    }), [
        token, activeTab, onLogin, onLogout,
        language, handleSetLanguage,
        clear,
        reCaptcha,
        network,
        wallet,
        seats,
        flight,
        serviceClass,
        booking,
        bookingBackFlight,
        checkin,
        docRequest,
        configAirline,
        setActiveTab
    ]);
    config.isDev && console.log('[Context]', context);
    return context;
}
