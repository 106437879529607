import { useState, useMemo, useEffect } from 'react';
import { getValueFromStorage } from 'utils/storage';
import config from 'config';

const LS_KEY_PROVIDER = 'provider';
const LS_KEY_AIRLINE = 'airline';

function getValue(json: string): string | null {
    if (!json) {
        return null
    }

    const origin = window.location.origin;
    try {
        const parsedValue = JSON.parse(json);
        return parsedValue[origin] || null;
    } catch (error) {
        console.error(`Failed to parse JSON "${origin}":`, error);
        return null;
    }
}

export interface ConfigContext {
    key: string | null;
    provider: string | null;
}

export const INIT_CONFIG_CONTEXT: ConfigContext = {
    key: null,
    provider: null,
};

export function useConfigContextProvider(): ConfigContext {
    const [key, setKey] = useState<string | null>(null);
    const [provider, setProvider] = useState<string | null>(null);

    useEffect(() => {
        const keyFromConfig = getValue(config.CHECKIN_SERVER_API_KEY_BY_ORIGIN);
        const keyFromStorage = getValueFromStorage(LS_KEY_AIRLINE);

        const providerFromStorage = getValueFromStorage(LS_KEY_PROVIDER);
        const providerFromConfig = getValue(config.PROVIDER_BY_ORIGIN);
        console.log('CONFIG:', config.PROVIDER_BY_ORIGIN)
        setKey(keyFromStorage || keyFromConfig || config.CHECKIN_SERVER_API_KEY);
        setProvider(providerFromStorage || providerFromConfig || config.AIRLINE);
    }, []);

    return useMemo(() => ({
        key,
        provider,
    }), [key, provider]);
}
