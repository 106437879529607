import React, { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { theme } from 'antd';
import plane from 'assets/images/plane_filled.png';
import plane_easyJet from 'assets/images/plane_filled_easy_jet.png';
import './FlightInfo.css';
import config from 'config';

const { useToken } = theme;
export interface FlightInfoProps {
    origin: string;
    destination: string;
    date: string | number | null;
    departureAt: string;
    arrivalAt: string;
    duration: string
    transit?: number
}

export default function FlightInfo({ origin, destination, date, departureAt, arrivalAt, duration, transit }: FlightInfoProps) {
    const { t } = useTranslation();
    const { token } = useToken();

    const day = React.useMemo(() => {
        if (!date) {
            return t('na');
        }

        return moment(date).format('DD MMM');
    }, [date, t]);

    const { color, flightPlane } = useMemo(() => {
        const origin = window.location.origin;
        if (origin === config.EASY_JET_DOMAIN) {
            return {
                color: token.colorPrimary,
                flightPlane: plane_easyJet
            }
        } else {
            return {
                color: token.colorPrimary,
                flightPlane: plane
            }
        }
    }, []);

    return (
        <div className='FlightInfo'>
            <div className="FlightInfo-directionPoint">
                <span>{departureAt}</span>
                <span>{day} | {origin}</span>
            </div>
            <div className="FlightInfo-planeContainer">
                <div className="FlightInfo-duration">{duration}</div>
                <div className="FlightInfo-flight">
                    <div className="FlightInfo-point" />
                    <div className="FlightInfo-line" style={{ borderBottomColor: color }} />
                    <div className="FlightInfo-point" />
                    {
                        transit && transit > 1 ? (
                            Array(transit).fill(null).map((_, idx) => {
                                if (idx === 0 || idx === transit) {
                                    return null
                                }
                                return <img
                                    id={idx.toString()}
                                    key={idx}
                                    src={flightPlane}
                                    alt=""
                                    className="FlightInfo-plane"
                                    style={{
                                        position: 'absolute',
                                        left: `${(idx / (transit)) * 100}%`,
                                        transform: 'translateX(-50%)',
                                    }}
                                />
                            })
                        ) : (
                            <img
                                src={flightPlane}
                                alt=""
                                className="FlightInfo-plane"
                            />
                        )
                    }
                </div>
            </div>
            <div className="FlightInfo-directionPoint">
                <span>{arrivalAt}</span>
                <span>{day} | {destination}</span>
            </div>
        </div>
    )
}
