import { Formik, setNestedObjectValues } from "formik";
import React, { useCallback, useState } from "react";
import { Pax } from "utils/booking";
import { useContextSelector } from "use-context-selector";
import { AppContext, AppContextType } from "AppContext";
import { useNavigate } from "react-router-dom";
import { Collapse, Typography, Flex, Button } from "antd";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import PaxForm from './components/PaxForm';
import config from "config";
import { Drawer } from "components/Drawer";

function mapPax(it: any, type: 'adult' | 'child' | 'infant'): Pax {
    return {
        type,
        fn: it.fn?.trim(),
        mn: it.mn?.trim(),
        ln: it.ln?.trim(),
        email: it.email?.trim(),
        phone: (it.phone?.length > 4) ? it.phone : undefined,
        phone_country: it.phone_country,
        sex: it.sex,
        dob: it.dob,
        address: it.address?.trim(),
        city: it.city?.trim(),
        zip: it.zip?.trim(),
        document_type: it.document_type,
        document_number: it.document_number?.trim(),
        document_expiry_date: it.document_expiry_date,
        document_nationality: it.document_nationality,
        seat_number: it.seat_number,
        document_issued_at: it.document_issued_at,
        flyClass: it.flyClass
    };
}

const MainForm = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [showDrawer, setShowDrawer] = useState<boolean>(false);
    const formikSubmitRef = React.useRef<(() => void) | null>(null);

    const ValidationShema = React.useMemo(() => {
        return Yup.object().shape({
            adults: Yup.array().of(
                Yup.object().shape({
                    fn: Yup.string()
                        .required(t("required"))
                        .matches(/^[A-Z/\d\s]+$/gmi, t('PassengerScreen.inputLatinAndNumbers')),
                    mn: Yup.string()
                        .matches(/^[A-Z/\d\s]+$/gmi, t('PassengerScreen.inputLatinAndNumbers')),
                    ln: Yup.string()
                        .required(t("required"))
                        .matches(/^[A-Z/\d\s]+$/gmi, t('PassengerScreen.inputLatinAndNumbers')),
                    document_number: Yup.string()
                        .matches(/^[A-Z/\d]+$/gmi, t('PassengerScreen.inputLatinAndNumbers')),
                    dob: Yup.date().required(t("required")),
                    email: Yup.string().email(t('PassengerScreen.inEmail')).test({
                        name: 'email_test',
                        message: t("required"),
                        test: (value, options) => {
                            return options.path.includes('adults[0]') && !value ? false : true;
                        }
                    }),
                })
            ),
            childs: Yup.array().of(
                Yup.object().shape({
                    fn: Yup.string()
                        .required(t("required"))
                        .matches(/^[A-Z/\d\s]+$/gmi, t('PassengerScreen.inputLatinAndNumbers')),
                    mn: Yup.string()
                        .matches(/^[A-Z/\d\s]+$/gmi, t('PassengerScreen.inputLatinAndNumbers')),
                    ln: Yup.string()
                        .required(t("required"))
                        .matches(/^[A-Z/\d\s]+$/gmi, t('PassengerScreen.inputLatinAndNumbers')),
                    document_number: Yup.string()
                        .matches(/^[A-Z/\d]+$/gmi, t('PassengerScreen.inputLatinAndNumbers')),
                    dob: Yup.date().required(t("required"))
                })
            ),
            infants: Yup.array().of(
                Yup.object().shape({
                    fn: Yup.string()
                        .required(t("required"))
                        .matches(/^[A-Z/\d\s]+$/gmi, t('PassengerScreen.inputLatinAndNumbers')),
                    mn: Yup.string()
                        .matches(/^[A-Z/\d\s]+$/gmi, t('PassengerScreen.inputLatinAndNumbers')),
                    ln: Yup.string()
                        .matches(/^[A-Z/\d\s]+$/gmi, t('PassengerScreen.inputLatinAndNumbers')),
                    document_number: Yup.string()
                        .required(t("required"))
                        .matches(/^[A-Z/\d]+$/gmi, t('PassengerScreen.inputLatinAndNumbers')),
                    dob: Yup.date().required(t("required"))
                })
            ),
        });
    }, [t]);

    const { passengers, setPassengers, clear, counts } = useContextSelector(
        AppContext,
        (c: AppContextType) => c.booking
    );

    const paxCount = counts;
    const onSubmit = useCallback((Form: { adults: Pax[], childs: Pax[], infants: Pax[] }) => {
        try {

            //MERGED FIELDS
            const mergedAdult: Pax[] = Form.adults.map((it: any) => mapPax(it, 'adult'))
            const mergedChild: Pax[] = Form.childs.map((it: any) => mapPax(it, 'child'))
            const mergedInfant: Pax[] = Form.infants.map((it: any) => mapPax(it, 'infant'))

            setPassengers({
                adults: mergedAdult,
                childs: mergedChild,
                infants: mergedInfant,
            }, () => navigate("/payment"));
        } catch (error) {
            console.error(error);
        }
    }, [setPassengers, navigate]);

    const initialValues = React.useMemo(() => {
        return {
            adults: passengers.adults.map((it: Pax) => ({ ...it })),
            childs: passengers.childs.map((it: Pax) => ({ ...it })),
            infants: passengers.infants.map((it: Pax) => ({ ...it })),
        };
    }, [passengers]);

    const renderAdult = React.useCallback((index: number, pax: Pax) => {
        const name = `adults.${index}`;
        return (
            <PaxForm
                key={name}
                name={name}
                type="adult"
                index={index}
                typeIndex={index}
                primary={!index}
                pax={pax}
            />
        )
    }, []);

    const renderChild = React.useCallback((index: number, prevCount: number, pax: Pax) => {
        const name = `childs.${index}`;
        return (
            <PaxForm
                key={name}
                name={name}
                type="children"
                index={prevCount + index}
                typeIndex={index}
                primary={false}
                pax={pax}
            />
        )
    }, []);

    const renderInfant = React.useCallback((index: number, prevCount: number, pax: Pax) => {
        const name = `infants.${index}`;
        return (
            <PaxForm
                key={name}
                name={name}
                type="infant"
                index={prevCount + index}
                typeIndex={index}
                primary={false}
                pax={pax}
            />
        );
    }, []);

    const onCancel = React.useCallback(() => {
        clear();
        setTimeout(() => {
            navigate('/');
        }, 0)
    }, [clear, navigate]);

    const renderForm = React.useCallback(({ values, handleSubmit, validateForm, setErrors, setTouched, errors }: any) => {
        formikSubmitRef.current = handleSubmit;
        const submit = () => {
            validateForm()
                .then((errors: any) => {
                    if (!errors || !Object.keys(errors).length) {
                        setShowDrawer(true);
                        // handleSubmit();
                    } else {
                        setErrors(errors);
                        setTouched(setNestedObjectValues(errors, true));
                    }
                })
        };

        return (
            <>
                <div style={{ marginTop: 20 }}>

                    {values.adults.map((pax: Pax, idx: number) => (
                        <Collapse
                            key={`adults_${idx}`}
                            className="MainForm-Collapse"
                            expandIconPosition="end"
                            defaultActiveKey={idx === 0 ? `adults_${idx}` : undefined}
                            items={[{
                                key: `adults_${idx}`,
                                label: `Adult ${idx + 1}`,
                                children: renderAdult(idx, pax),
                            }]}
                        />
                    ))}

                    {values.childs.map((pax: Pax, idx: number) => (
                        <Collapse
                            key={`childs_${idx}`}
                            className="MainForm-Collapse"
                            expandIconPosition="end"
                            items={[{
                                key: `childs_${idx}`,
                                label: `Children ${idx + 1}`,
                                children: renderChild(idx, paxCount.adults, pax),
                            }]}
                        />
                    ))}

                    {values.infants.map((pax: Pax, idx: number) => (
                        <Collapse
                            key={`infants_${idx}`}
                            className="MainForm-Collapse"
                            expandIconPosition="end"
                            items={[{
                                key: `infants_${idx}`,
                                label: `Children ${idx + 1}`,
                                children: renderInfant(idx, paxCount.adults + paxCount.childs, pax),
                            }]}
                        />
                    ))}
                </div>
                <Flex justify="flex-end">
                    <Button
                        type='primary'
                        size='large'
                        onClick={() => submit()}
                    >
                        {t('PassengerScreen.checkData')}
                    </Button>
                </Flex>
            </>
        );
    }, [t, renderAdult, renderChild, paxCount.adults, paxCount.childs, renderInfant]);

    const onContinue = useCallback(() => {
        const wrapper = document.getElementById('Wrapper');
        formikSubmitRef.current && formikSubmitRef.current()
        wrapper?.scrollIntoView({ behavior: 'auto', block: "start" });
    }, []);

    return (
        <div>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={onSubmit}
                validationSchema={ValidationShema}
            >
                {renderForm}
            </Formik>
            <Flex vertical>
                <Typography.Title level={3}>{t('PassengerScreen.specialNeeds')}</Typography.Title>
                <Typography>{t('PassengerScreen.specialNeedsDesc', { phone: config.CALL_CENTER_PHONE })}</Typography>
            </Flex>
            <Drawer
                open={showDrawer}
                onClose={() => setShowDrawer(false)}
                onContinue={onContinue}
                title={t('PassengerScreen.checkDataSuccess')}
            />
        </div>
    );
};

export default MainForm;
