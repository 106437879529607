import { useCallback, useEffect, useMemo, useState } from 'react';
import { useContextSelector } from 'use-context-selector';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { isMobile } from 'react-device-detect';
import { Formik, Field, ErrorMessage, setNestedObjectValues } from "formik";
import * as Yup from "yup";
import { Flex, Typography, Input, DatePicker, Row, Col, Button, notification } from 'antd';
import { AppContext, AppContextType } from 'AppContext';
import Portal from 'components/Portal';
import Loader from 'components/Loader';
import PhoneNumber from 'components/PhoneNumber';
import TicketInfo from './components/TicketInfo';
import { PassengerForm as IPassengerForm } from 'utils/checkin';
import { IDS } from 'helpers/constants';
import config from 'config';
import './PassengerForm.css';

const { Paragraph, Text } = Typography;

const DEFAULT_PHONE = config.CHECKIN_DEFAULT_PHONE;

const NOTIFICATION_KEY = 'checkin_notification';
const DATE_FORMAT: any = {
    format: 'DD.MM.YYYY',
    type: 'mask'
}

export interface PassengerFormProps {
    onChangeStep: (step: number) => void;
    isIframe?: boolean;
}

function PassengerForm({ onChangeStep, isIframe = false }: PassengerFormProps) {
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const {
        passenger,
        setPassenger,
        onGetTickets,
        onGetTicketsGroups,
        tickets,
        groupedTicket,
    } = useContextSelector(AppContext, (c: AppContextType) => c.checkin);
    const { key, provider } = useContextSelector(AppContext, (c: AppContextType) => c.configAirline);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!key && !provider) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [key, provider]);

    const ValidationShema = useMemo(() => {
        return Yup.object().shape({
            passenger_firstname: Yup.string()
                .when(['reservation_number', 'ticket_number'], {
                    is: (reservation_number: string, ticket_number: string) => !reservation_number && !ticket_number,
                    then: (schema) => schema
                        .required(t("required"))
                        .matches(/^[A-Z/\d\s]+$/gmi, t('PassengerScreen.inputLatinAndNumbers')),
                    otherwise: (schema) => schema.nullable()
                }),
            passenger_lastname: Yup.string()
                .when(['reservation_number', 'ticket_number'], {
                    is: (reservation_number: string, ticket_number: string) => (!reservation_number && !ticket_number),
                    then: (schema) => schema
                        .required(t("required"))
                        .matches(/^[A-Z/\d\s]+$/gmi, t('PassengerScreen.inputLatinAndNumbers')),
                    otherwise: (schema) => schema.nullable()
                }),
            passenger_phone: Yup.string()
                .required(t("required"))
                .length(12, t('CheckInScreen.phoneNotValid')),
            flight_date: Yup.date()
                .when(['reservation_number', 'ticket_number'], {
                    is: (reservation_number: string, ticket_number: string) => !reservation_number && !ticket_number,
                    then: (schema) => schema.required(t("required")),
                    otherwise: (schema) => schema.nullable(),
                }),
            flight_number: Yup.string()
                .when(['reservation_number', 'ticket_number'], {
                    is: (reservation_number: string, ticket_number: string) => !reservation_number && !ticket_number,
                    then: (schema) => schema.required(t("required")),
                    otherwise: (schema) => schema.nullable(),
                }),
        });
    }, [t]);

    const initialValues = useMemo(() => {
        return {
            passenger_firstname: passenger?.passenger_firstname || '',
            passenger_lastname: passenger?.passenger_lastname || '',
            passenger_document: passenger?.passenger_document || '',
            passenger_phone: DEFAULT_PHONE.length > 0 ? DEFAULT_PHONE : passenger?.passenger_phone ?? '',
            flight_date: passenger?.flight_date || '',
            flight_number: passenger?.flight_number || '',
            reservation_number: passenger?.reservation_number || '',
            ticket_number: passenger?.ticket_number || '',
        };
    }, [passenger]);

    const onSubmit = useCallback((values: IPassengerForm) => {
        console.log('[PassengerForm] passenger data:', values);
        const data = {
            passenger_firstname: values.passenger_firstname?.trim(),
            passenger_lastname: values.passenger_lastname?.trim(),
            passenger_document: values.passenger_document?.trim(),
            passenger_phone: values.passenger_phone?.trim(),
            flight_number: values.flight_number?.trim(),
            flight_date: values.flight_date,
            ...values.ticket_number && { ticket_number: values.ticket_number?.trim() },
            ...values.reservation_number && { reservation_number: values.reservation_number?.trim() }
        }

        setPassenger(data);

        setLoading(true);

        if (isIframe) {
            onGetTicketsGroups(data, true)
                .then((resp) => {
                    setLoading(false);
                    if (!resp.length) {
                        api.warning({
                            key: NOTIFICATION_KEY,
                            message: t('warning'),
                            description: t('PassengerForm.ticketNotFound'),
                            placement: 'topRight',
                        });
                    }
                })
                .catch(error => {
                    console.log('[PassengerForm] onGetTickets error:', error);
                    setLoading(false);
                    api.error({
                        key: NOTIFICATION_KEY,
                        message: t('error'),
                        description: t([error.message, 'error_oops']),
                        placement: 'topRight',
                    });
                });
        } else {
            onGetTickets(data, true, isIframe)
                .then((resp) => {
                    setLoading(false);
                    if (!resp.length) {
                        api.warning({
                            key: NOTIFICATION_KEY,
                            message: t('warning'),
                            description: t('PassengerForm.ticketNotFound'),
                            placement: 'topRight',
                        });
                    }
                })
                .catch(error => {
                    console.log('[PassengerForm] onGetTickets error:', error);
                    setLoading(false);
                    api.error({
                        key: NOTIFICATION_KEY,
                        message: t('error'),
                        description: t([error.message, 'error_oops']),
                        placement: 'topRight',
                    });
                });
        }
    }, [onGetTickets, onGetTicketsGroups, isIframe]); // eslint-disable-line

    const renderError = useCallback((error: string) => {
        if (!error) {
            return null;
        }

        return (
            <Row>
                <Col md={{ flex: '40%' }} xs={{ flex: '100%' }} />
                <Col md={{ flex: '60%' }} xs={{ flex: '100%' }}>
                    <div className='errorText'>
                        {error}
                    </div>
                </Col>
            </Row>
        );
    }, []);

    const renderForm = useCallback(({ values, handleSubmit, validateForm, setErrors, setTouched, errors }: any) => {
        const isRequired = !values.reservation_number && !values.ticket_number;
        const submit = () => {
            validateForm()
                .then((errors: any) => {
                    if (!errors || !Object.keys(errors).length) {
                        handleSubmit();
                    } else {
                        setErrors(errors);
                        setTouched(setNestedObjectValues(errors, true));
                    }
                })
        };

        return (
            <>
                <Flex className='PassengerForm-Form-container' vertical gap={10}>
                    <div>
                        <Row align='middle'>
                            <Col md={{ flex: '40%' }} xs={{ flex: '100%' }}>
                                <Text className='PassengerForm-Label'>
                                    {t('PassengerForm.firstName')}{isRequired && <span style={{ color: 'red' }}>*</span>}
                                </Text>
                            </Col>
                            <Col md={{ flex: '60%' }} xs={{ flex: '100%' }}>
                                <Field
                                    as={Input}
                                    name={'passenger_firstname'}
                                    size='large'
                                    placeholder={t('PassengerForm.firstName')}
                                />

                            </Col>
                        </Row>
                        <ErrorMessage
                            name={'passenger_firstname'}
                            render={renderError}
                        />
                    </div>
                    <div>
                        <Row align='middle'>
                            <Col md={{ flex: '40%' }} xs={{ flex: '100%' }}>
                                <Text className='PassengerForm-Label'>
                                    {t('PassengerForm.lastName')}{isRequired && <span style={{ color: 'red' }}>*</span>}
                                </Text>
                            </Col>
                            <Col md={{ flex: '60%' }} xs={{ flex: '100%' }}>
                                <Field
                                    as={Input}
                                    name={'passenger_lastname'}
                                    size='large'
                                    placeholder={t('PassengerForm.lastName')}
                                />
                            </Col>
                        </Row>
                        <ErrorMessage
                            name={'passenger_lastname'}
                            render={renderError}
                        />
                    </div>
                    <div>
                        <Row align='middle'>
                            <Col md={{ flex: '40%' }} xs={{ flex: '100%' }}>
                                <Text className='PassengerForm-Label'>
                                    {t('PassengerForm.documentNumber')}
                                </Text>
                            </Col>
                            <Col md={{ flex: '60%' }} xs={{ flex: '100%' }}>
                                <Field
                                    as={Input}
                                    name={'passenger_document'}
                                    size='large'
                                    placeholder={t('PassengerForm.documentNumber')}
                                />
                            </Col>
                        </Row>
                        <ErrorMessage
                            name={'passenger_document'}
                            render={renderError}
                        />
                    </div>
                    {
                        DEFAULT_PHONE.length > 0 ? null : (
                            <div>
                                <Row align='middle'>
                                    <Col md={{ flex: '40%' }} xs={{ flex: '100%' }}>
                                        <Text className='PassengerForm-Label'>
                                            {t('PassengerForm.phoneNumber')}<span style={{ color: 'red' }}>*</span>
                                        </Text>
                                    </Col>
                                    <Col md={{ flex: '60%' }} xs={{ flex: '100%' }}>
                                        <Field
                                            name={'passenger_phone'}
                                        >
                                            {({ field, form, meta }: any) => {
                                                return (
                                                    <PhoneNumber
                                                        name={field.name}
                                                        value={field.value}
                                                        defaultCountry={'ch'}
                                                        onChange={(fieldname, value) => {
                                                            form.setFieldValue(fieldname, value);
                                                        }}
                                                    />
                                                );
                                            }}
                                        </Field>
                                    </Col>
                                </Row>
                                <ErrorMessage
                                    name={'passenger_phone'}
                                    render={renderError}
                                />
                            </div>
                        )
                    }
                    <div>
                        <Row align='middle'>
                            <Col md={{ flex: '40%' }} xs={{ flex: '100%' }}>
                                <Text className='PassengerForm-Label'>
                                    {t('PassengerForm.dayDeparture')}{isRequired && <span style={{ color: 'red' }}>*</span>}
                                </Text>
                            </Col>
                            <Col md={{ flex: '60%' }} xs={{ flex: '100%' }}>
                                <Field
                                    name={'flight_date'}
                                >
                                    {({ field, form, meta }: any) => {
                                        return (
                                            <DatePicker
                                                name={field.name}
                                                inputMode="numeric"
                                                pattern="\d{2}/\d{2}/\d{4}"
                                                size="large"
                                                style={{ width: '100%' }}
                                                onChange={(date: any) => {
                                                    form.setFieldValue(
                                                        field.name,
                                                        date ? dayjs(date).format('YYYY-MM-DD') : ''
                                                    );
                                                }}
                                                format={DATE_FORMAT}
                                                inputReadOnly
                                                value={field.value ? dayjs(field.value) : ''}
                                                onBlur={field.onBlur}
                                                onFocus={field.onFocus}
                                                minDate={dayjs(dayjs(), 'YYYY-MM-DD')}
                                                className={isMobile ? 'mobile' : undefined}
                                            />
                                        )
                                    }}
                                </Field>
                            </Col>
                        </Row>
                        <ErrorMessage
                            name={'flight_date'}
                            render={renderError}
                        />
                    </div>
                    <div>
                        <Row align='middle'>
                            <Col md={{ flex: '40%' }} xs={{ flex: '100%' }}>
                                <Text className='PassengerForm-Label'>
                                    {t('PassengerForm.flightNumber')}{isRequired && <span style={{ color: 'red' }}>*</span>}
                                </Text>
                            </Col>
                            <Col md={{ flex: '60%' }} xs={{ flex: '100%' }}>
                                <Field
                                    as={Input}
                                    name={'flight_number'}
                                    size='large'
                                    placeholder={t('PassengerForm.flightNumber')}
                                />

                            </Col>
                        </Row>
                        <ErrorMessage
                            name={'flight_number'}
                            render={renderError}
                        />
                    </div>
                </Flex>
                <Paragraph className='PassengerForm-description'>{t('PassengerForm.optionalDescription')}</Paragraph>
                <Flex className='PassengerForm-Form-container' vertical gap={10}>
                    <Row align='middle'>
                        <Col md={{ flex: '40%' }} xs={{ flex: '100%' }}>
                            <Text className='PassengerForm-Label'>{t('PassengerForm.reservationNumber')}</Text>
                        </Col>
                        <Col md={{ flex: '60%' }} xs={{ flex: '100%' }}>
                            <Field
                                as={Input}
                                name={'reservation_number'}
                                size='large'
                                placeholder={t('PassengerForm.reservationPlaceholder')}
                            />
                        </Col>
                    </Row>
                    <Row align='middle'>
                        <Col md={{ flex: '40%' }} xs={{ flex: '100%' }}>
                            <Text className='PassengerForm-Label'>{t('PassengerForm.ticketNumber')}</Text>
                        </Col>
                        <Col md={{ flex: '60%' }} xs={{ flex: '100%' }}>
                            <Field
                                as={Input}
                                name={'ticket_number'}
                                size='large'
                                placeholder={t('PassengerForm.ticketPlaceholder')}
                            />
                        </Col>
                    </Row>
                </Flex>
                <Flex justify='flex-end'>
                    <Button
                        type='primary'
                        size='large'
                        onClick={() => submit()}
                    >
                        {t('PassengerForm.continue')}
                    </Button>
                </Flex>
            </>
        );
    }, [renderError, t]); //eslint-disable-line

    return (
        <>
            <div className='PassengerForm-CheckIn'>
                {
                    !tickets.length && !groupedTicket.length ? (
                        <Flex vertical className='PassengerForm-contaner'>
                            <Paragraph className='PassengerForm-description'>
                                {t('PassengerForm.description')}
                            </Paragraph>
                            <Flex className='PassengerForm-Form' vertical>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={ValidationShema}
                                    onSubmit={onSubmit}
                                >
                                    {renderForm}
                                </Formik>
                            </Flex>
                        </Flex>
                    ) : (
                        <TicketInfo isIframe={isIframe} onChangeStep={onChangeStep} />
                    )
                }
            </div>
            {
                loading && <Portal id={IDS.loader}>
                    <Loader />
                </Portal>
            }
            {contextHolder}
        </>
    )
}

export default PassengerForm
