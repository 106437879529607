import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useMatch } from 'react-router-dom';
import { Button, Drawer, Flex, Popover, Typography, theme } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useContextSelector } from 'use-context-selector';
import { AppContext, AppContextType } from 'AppContext';
import BookingScreen from 'screens/BookingsScreen';
import Header from 'components/Header';
import CurrencySelector from 'components/CurrencySelector';
import LanguageSelector from 'components/LanguageSelector';
import LogoutButton from 'components/LogoutButton';
import { IDS } from 'helpers/constants';
import useBackground from 'hooks/useBackground';
import './Wrapper.css'

const { useToken } = theme;

export interface IProps {
    children: React.ReactNode;
    header: boolean;
}

function Wrapper({ children, header }: IProps) {
    const { t } = useTranslation();
    const { token } = useToken();
    const navigate = useNavigate();
    const location = useLocation();
    const clear = useContextSelector(AppContext, (c: AppContextType) => c.clear);
    const booking = useContextSelector(AppContext, (c: AppContextType) => c.booking.booking);
    const background = useBackground();
    const isAuth = useMatch('/auth');
    const [open, setOpen] = useState(false);
    const [showBooking, setShowBooking] = useState<boolean>(false);
    const [isMobile, setIsMobile] = useState<boolean>(false);

    const isCheckIn = location.pathname === '/check-in';
    const isRequest = location.pathname === '/request';
    const isNonce = location.pathname === '/nonce';
    const isMyBooking = useMatch('/my-booking');

    const handleResize = useCallback(() => {
        setIsMobile(window.innerWidth < 768);
    }, []);

    const handleOpenChange = useCallback((open: boolean) => {
        setOpen(open);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); //eslint-disable-line

    const getContainer = useCallback(() => {
        const container = document.getElementById('Wrapper');
        return container ? container : document.body;
    }, []);

    const onCheckIn = useCallback(() => {
        clear();
        setTimeout(() => {
            navigate('/check-in');
        }, 0)
        isMobile && setOpen(false);
    }, [clear, isMobile, navigate]);

    return (
        <div
            id="Wrapper"
            className={`Wrapper ${isAuth ? 'auth' : ''}`}
            style={{ background: isAuth ? token.colorPrimary : '#FFF' }}
        >
            {header && (<div className="Wrapper-top">
                <Header />
                <div>
                    {
                        isMobile ? (
                            <Popover
                                content={
                                    <Flex vertical gap={5}>
                                        <Button
                                            type='link'
                                            // onClick={onMyBooking}
                                            onClick={() => navigate('/my-booking')}
                                            style={{ color: '#000', padding: '0px 4px', justifyContent: 'flex-start' }}
                                        >
                                            {t('LaunchScreen.myBookings')}
                                        </Button>
                                        <Button
                                            type='link'
                                            style={{ color: '#000', padding: '0px 4px', justifyContent: 'flex-start' }}
                                            onClick={onCheckIn}
                                        >
                                            {t('LaunchScreen.checkIn')}
                                        </Button>
                                        <CurrencySelector />
                                        <LanguageSelector />
                                        <LogoutButton isMobile />
                                    </Flex>
                                }
                                open={open}
                                trigger="click"
                                onOpenChange={handleOpenChange}
                            >
                                <Button type="primary" shape="circle" icon={<MenuOutlined />} />
                            </Popover>
                        ) : (
                            <>
                                <Button
                                    className='Wrapper-booking'
                                    type='link'
                                    onClick={() => navigate('/my-booking')}
                                >
                                    {t('LaunchScreen.myBookings')}
                                </Button>|
                                <Button
                                    className='Wrapper-booking'
                                    type='link'
                                    onClick={onCheckIn}
                                >
                                    {t('LaunchScreen.checkIn')}
                                </Button>
                                <CurrencySelector />
                                <LanguageSelector />
                                <LogoutButton isMobile={false} />
                            </>
                        )
                    }
                </div>
            </div>)}
            {
                isCheckIn || isRequest || isNonce || !header || (!booking && isMyBooking)
                    ? null
                    : (
                        <div className='Wrapper-image' style={{ backgroundColor: token.colorPrimary }}>
                            <img src={background} alt='' />
                        </div>
                    )
            }
            <div className="Wrapper-container">
                {children}
            </div>
            {header && (<div className='Wrapper-footer'>
                <Typography style={{ fontSize: 16 }}>2024 CompPass Airways</Typography>
            </div>)}
            <div id={IDS.payment} className='Sticky-Drawer' />
            <Drawer
                rootClassName='Booking'
                open={showBooking}
                placement='bottom'
                title={<Typography style={{ fontSize: 24, color: '#FFF' }}>{t('myBookings')}</Typography>}
                onClose={() => setShowBooking(false)}
                style={{ height: '100vh' }}
                styles={{
                    content: { height: '100vh', width: '100vw', backgroundColor: '#FFF', overflow: 'auto' },
                    header: { backgroundColor: '#767676', fontSize: 24, color: '#FFF' }
                }}
                destroyOnClose
                getContainer={getContainer}
                height={'100vh'}
            >
                <div style={{ maxWidth: 950, margin: '0 auto' }}>
                    <BookingScreen onClose={() => setShowBooking(false)} />
                </div>
            </Drawer>
        </div>
    );
}

export default Wrapper;
