import React from 'react';
import { Select } from 'antd';
import { useContextSelector } from 'use-context-selector';
import { AppContext, AppContextType } from 'AppContext';
import { useTranslation } from 'react-i18next';
import { Currency } from 'utils/currency';
import './CurrencySelector.css';

function CurrencySelector() {
    const { t } = useTranslation();
    const { currency, setCurrency } = useContextSelector(AppContext, (c: AppContextType) => c.wallet)

    const options = React.useMemo(() => {
        return [
            {
                label: t('chf'),
                value: Currency.chf,
            },
            {
                label: t('eur'),
                value: Currency.eur,
            },
            {
                label: t('usd'),
                value: Currency.usd,
            },
            {
                label: t('gbp'),
                value: Currency.gbp,
            },
        ];
    }, [t]);

    return (
        <Select
            size="small"
            value={currency}
            options={options}
            variant='borderless'
            onSelect={setCurrency}
            className="CurrencySelector"
        />
    );
}

export default CurrencySelector