import React from 'react';

export interface NetworkContext {
    isOnline: boolean;
}

export const INIT_NETWORK_CONTEXT: NetworkContext = {
    isOnline: false,
};

export function useNetworkContextProvider(): NetworkContext {
    const [isOnline, setIsOnline] = React.useState<boolean>(true);

    React.useEffect(() => {
        const handleConnectionStatus = () => {
            setIsOnline(!!window.navigator?.onLine);
        };
        window.addEventListener('online', handleConnectionStatus);
        window.addEventListener('offline', handleConnectionStatus);

        return () => {
            window.removeEventListener('online', handleConnectionStatus);
            window.removeEventListener('offline', handleConnectionStatus);
        };
    }, []);

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return React.useMemo(() => ({
        isOnline,
    }), [
        isOnline
    ]);
}
