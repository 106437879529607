import React from 'react'
import { Typography, } from 'antd';

export interface LabelProps {
    text: string;
    required?: boolean;
    styles?: Record<string, any>
}

export default function Label({ text, required, styles }: LabelProps) {
    return (
        <Typography style={{ fontSize: '1.25rem', fontWeight: 'bold', ...styles }}>{text}{required && '*'}</Typography>
    )
}
