function generateGUID() {
  let guid = "";
  for (let i = 0; i < 32; i++) {
    guid += Math.floor(Math.random() * 16).toString(16);
  }
  return guid;
}


export class MatchboxValidatorApi {

  /**
   *
   * @param {object} otherWindow - window object of the other
   * @param {string} targetDomain - domain of the other window
   * @param {object} eventHandlers - all the event names and handlers
   */
  constructor(
    iframeId,
    styles = {
      link: "",
      variables: {},
      icons: {}
    },
    data = {},
    options = {},
  ) {
    this.targetDomain = options.targetDomain;
    this.parentDomain = options.parentDomain;
    this.data = data;
    this.cssLink = styles.link;
    this.cssVariables = styles.variables;
    this.icons = styles.icons;

    this.receive = this.receive.bind(this);
    this.post = this.post.bind(this);
    this.eventHandlers = {};

    this.iframe = document.getElementById(iframeId);

    if (!this.iframe) {
      document.addEventListener('DOMContentLoaded', () => {
        this.initIframe.apply(this, [iframeId]);
      });
    } else {
      this.initIframe.apply(this, [iframeId]);
    }

    window.addEventListener(
      "message",
      (e) => this.receive.call(this, e)
    );
  }

  initIframe(iframeId) {
    this.iframe = document.getElementById(iframeId);
    this.iframe.src = this.targetDomain;
    const div = document.createElement('div');
    div.classList.add('holds-the-iframe');
    this.iframe.parentElement.appendChild(div);
    div.appendChild(this.iframe);
    const url = new URL(this.targetDomain)

    div.style.background = `url('${url.origin}/preloder.gif') center center no-repeat`;
    div.style.width = '100%';
    div.style.display = 'flex';
    div.style.alignItems = 'center';
    div.style.justifyContent = 'center';

    this.iframe.onload= function() {
        div.style.background = 'none';
    };
  }

  addEventListener(eventName, handler) {
    this.eventHandlers[eventName] = handler;
  }

  post(event, data) {
    try {
      // data obj should have event name
      if (this.iframe?.contentWindow) {
        this.iframe.contentWindow.postMessage({event, data, id: generateGUID()}, this.targetDomain);
      }
    } catch (e) {
      console.error('MatchboxValidatorApi', e);
    }
  }

  receive(e) {
    const eventName = e.data.event;
    const data = e.data.data;

    if (e.origin !== this.targetDomain) {
      return;
    }

    if (eventName === 'height') {
      this.iframe.style.height = data + 'px';
    }

    if (eventName === 'ready') {
      if (this.cssLink) {
        this.post('cssLink', this.cssLink);
      }
      if (this.cssVariables) {
        this.post('cssVariables', this.cssVariables);
      }
      if (this.icons) {
        this.post('icons', this.icons);
      }
      if (this.parentDomain) {
        this.post('parentDomain', this.parentDomain);
      }
      this.post('data', this.data);
    }

    if (typeof this.eventHandlers[eventName] === "function")
      this.eventHandlers[eventName](data);
  }
}

window.MatchboxValidatorApi = MatchboxValidatorApi
