import { useCallback } from 'react';
import { Flex, Row, Col, Card, Typography, Divider, Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { FlightTicketExpanded, ValidationInfo } from 'utils/checkin';
import { JsonView, allExpanded, darkStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import './BookingCard.css';

export interface BookingCardProps {
    flight_ticket: FlightTicketExpanded;
}

export default function BookingCard({ flight_ticket }: BookingCardProps) {
    const { t } = useTranslation();

    const renderAdl = useCallback((infoList: ValidationInfo, adl: string | undefined) => {
        if (!adl) {
            return
        }

        let updatedString = adl;

        if (infoList.direction === 'outbound') {
            const outboundMatches = [...updatedString.matchAll(/^\.I[0-9]*(\/[^\r\n]+)[\r\n]+/gm)];
            outboundMatches.forEach((outboundMatch) => {
                const pattern = outboundMatch[1];

                updatedString = updatedString.replace(new RegExp(`^\\.I${pattern}[\\r\\n]+`, 'gm'), '');
                updatedString = updatedString.replace(new RegExp(`\\.M${pattern}[\\r\\n]+`, 'gm'), '');
            });
        } else if (infoList.direction === 'inbound') {
            const inboundMatches = [...updatedString.matchAll(/^\.O[0-9]*(\/[^\r\n]+)[\r\n]+/gm)];
            inboundMatches.forEach((inboundMatch) => {
                const pattern = inboundMatch[1];

                updatedString = updatedString.replace(new RegExp(`^\\.O${pattern}[\\r\\n]+`, 'gm'), '');
                updatedString = updatedString.replace(new RegExp(`^\\.M${pattern}[\\r\\n]+`, 'gm'), '');
            });
        }

        return (
            <div style={{marginBottom: 15}}>
                <Typography.Title level={5} className='BookingCard-title'>
                    TTY Type B ({infoList.direction}) {infoList.booking?.status === 'accepted' ? <CheckCircleFilled style={{ color: 'green' }} /> : <CloseCircleFilled style={{ color: 'red' }} />}
                </Typography.Title>
                <Typography.Text className='BookingCard-adl' code>
                    {updatedString.trim()}
                </Typography.Text>
            </div>
        )
    }, []);

    const renderValidationInfo = useCallback((ticket: FlightTicketExpanded, idx: number) => {
        if (!ticket.validation_info_list?.[idx]?.data) {
            return
        }

        const item = [{
            key: idx.toString(),
            label: <Typography style={{ margin: 0, fontSize: 16, fontWeight: 500 }}>{`JSON (${ticket.validation_info_list[idx].direction})`}</Typography>,
            children: <JsonView data={ticket.validation_info_list[idx].data} shouldExpandNode={allExpanded} style={darkStyles} />
        }]

        return (
            <Collapse key={'json'} defaultActiveKey={[idx.toString()]} items={item} style={{marginBottom: 15}}/>
        )
    }, []);

    const renderPassenger = useCallback((ticket: FlightTicketExpanded, validationInfo: ValidationInfo) => {
        const booking = validationInfo.booking;
        return (
            <Card key={ticket.id + validationInfo?.booking?.adl} className='BookingCard-passenger'>
                <Flex justify='space-between'>
                    <Typography.Title level={4}
                        className='BookingCard-title'>
                        {t(`BookingsScreen.${booking?.type.toLowerCase()}`)}: {ticket.passenger_firstname} {ticket.passenger_lastname}
                    </Typography.Title>
                    <Typography.Title level={4} style={{ margin: 0 }}>
                        {t('BookingsScreen.adoc')}
                    </Typography.Title>
                </Flex>
                <Divider />
                <Row>
                    <Col sm={24} md={10}>
                        {
                            booking?.dob && (
                                <Flex>
                                    <Typography className="BookingCard-text">
                                        {t('BookingsScreen.dob', { date: booking?.dob })}
                                    </Typography>
                                </Flex>
                            )
                        }
                        {
                            booking?.document_number && (
                                <Flex>
                                    <Typography className="BookingCard-text">
                                        {t('BookingsScreen.documentNumber', { number: (booking?.document_number || '').toUpperCase() })}
                                    </Typography>
                                </Flex>
                            )
                        }
                        {
                            booking?.document_expiry_date && (
                                <Flex>
                                    <Typography className="BookingCard-text">
                                        {t('BookingsScreen.documentExpDate', { date: booking?.document_expiry_date })}
                                    </Typography>
                                </Flex>
                            )
                        }
                        {
                            booking?.document_issued_at && (
                                <Flex>
                                    <Typography className="BookingCard-text">
                                        {t('BookingsScreen.documentIssuedAt', { issued: booking?.document_issued_at })}
                                    </Typography>
                                </Flex>
                            )
                        }
                        {
                            booking?.document_type && (
                                <Flex>
                                    <Typography className="BookingCard-text">
                                        {t('BookingsScreen.documentType', { type: booking?.document_type })}
                                    </Typography>
                                </Flex>
                            )
                        }
                        {
                            booking?.document_nationality && (
                                <Flex>
                                    <Typography className="BookingCard-text">
                                        {t('BookingsScreen.documentNationality', { nationality: booking?.document_nationality })}
                                    </Typography>
                                </Flex>
                            )
                        }
                        {
                            booking?.city && (
                                <Flex>
                                    <Typography className="BookingCard-text">
                                        {t('BookingsScreen.city', { city: booking?.city })}
                                    </Typography>
                                </Flex>
                            )
                        }
                        {
                            booking?.address && (
                                <Flex>
                                    <Typography className="BookingCard-text">
                                        {t('BookingsScreen.address', { address: booking?.address })}
                                    </Typography>
                                </Flex>
                            )
                        }
                        {
                            booking?.zip && (
                                <Flex>
                                    <Typography className="BookingCard-text">
                                        {t('BookingsScreen.zip', { zip: booking?.zip })}
                                    </Typography>
                                </Flex>
                            )
                        }
                        {
                            booking?.seat_number && (
                                <Flex>
                                    <Typography className="BookingCard-text">
                                        {t('BookingsScreen.seatNumber', { number: booking?.seat_number })}
                                    </Typography>
                                </Flex>
                            )
                        }
                        {
                            booking?.service_class && (
                                <Flex>
                                    <Typography className="BookingCard-text">
                                        {t('BookingsScreen.serviceClass', { class: booking?.service_class })}
                                    </Typography>
                                </Flex>
                            )
                        }
                        {
                            ticket.ticket_number && (
                                <Flex>
                                    <Typography className="BookingCard-text">
                                        {t('BookingsScreen.ticketNumber', { number: ticket.ticket_number })}
                                    </Typography>
                                </Flex>
                            )
                        }
                    </Col>
                    <Col sm={24} md={14}>
                        {ticket.validation_info_list?.map((info: ValidationInfo, idx: number) => renderAdl(info, info.booking?.adl))}
                        {ticket.validation_info_list?.map((info: ValidationInfo, idx: number) => renderValidationInfo(flight_ticket, idx))}
                    </Col>
                </Row>
            </Card>
        )
    }, [t]);

    return (
        <Card className='BookingCard'>
            <Row>
                <Col md={24}>
                    <Typography.Title level={2}
                        className='BookingCard-title'>
                        {t('MyOptionsRetrieveScreen.apiData')}
                    </Typography.Title>
                    <Divider />
                    {flight_ticket?.validation_info_list?.[0] ?
                        renderPassenger(flight_ticket, flight_ticket?.validation_info_list?.[0]) :
                        null
                    }
                </Col>
                <Col md={24}>

                </Col>
            </Row>
        </Card>
    )
}
