import { useEffect } from 'react'
import { useContextSelector } from 'use-context-selector';
import { AppContext, AppContextType } from 'AppContext';
import SearchBooking from './components/SearchBooking'
import { useNavigate } from "react-router-dom";


export default function MyBookingScreen() {
    const navigate = useNavigate();
    const { booking } = useContextSelector(AppContext, (c: AppContextType) => c.booking);

    useEffect(() => {
        if (booking) {
            navigate('/retrive', { state: { ...booking } });
        }
    }, [booking]);

    return (
        <SearchBooking />
    )
}
