export default function getEndpoints(config: any) {
    const isLocal = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '192.168.1.102';
    return {
        FLIGHTS_EP: `${config.HTTP_SERVER}/${isLocal ? 'booking/': ''}api/v1/flight`,
        FLIGHTS_TRANSIT_EP: `${config.HTTP_SERVER}/${isLocal ? 'booking/': ''}api/v1/flight_route`,
        BOOKING_EP: `${config.HTTP_SERVER}/${isLocal ? 'booking/': ''}api/v1/booking`,
        BOOKING_EP_V2: `${config.HTTP_SERVER}/${isLocal ? 'booking/': ''}api/v2/booking`,
        AUTH_EP: `${config.HTTP_SERVER}/${isLocal ? 'booking/': ''}api/v1/auth/token`,

        AIRPORTS_EP: `${config.CHECKIN_SERVER}/${isLocal ? 'checkin/': ''}api/v1/airport/list`,
        TICKETS_EP: `${config.CHECKIN_SERVER}/${isLocal ? 'checkin/': ''}api/v1/ticket`,
        SESSION_EP: `${config.CHECKIN_SERVER}/${isLocal ? 'checkin/': ''}api/v1/session`,
        VALIDATION_EP: `${config.CHECKIN_SERVER}/${isLocal ? 'checkin/': ''}api/v1/validation`,
        DOCUMENT_EP: `${config.CHECKIN_SERVER}/${isLocal ? 'checkin/': ''}api/v1/document`,
        DOCUMENT_REQUEST_EP: `${config.CHECKIN_SERVER}/${isLocal ? 'checkin/': ''}api/v1/document_request`,
        FORM_EP: `${config.CHECKIN_SERVER}/${isLocal ? 'checkin/': ''}api/v1/jsonform`,
        SCHEMA_EP: `${config.CHECKIN_SERVER}/${isLocal ? 'checkin/': ''}api/v1/schema`,
        REQUIREMENTS_EP: `${config.CHECKIN_SERVER}/${isLocal ? 'checkin/': ''}api/v1/requirements`,
        BOOKING_SEARCH_EP: `${config.CHECKIN_SERVER}/${isLocal ? 'checkin/': ''}api/v1/booking`,
        BOOKING_SEARCH_V2_EP: `${config.CHECKIN_SERVER}/${isLocal ? 'checkin/': ''}api/v2/booking`,

        UPLOAD_EP: `${config.CHECKIN_SERVER}/${isLocal ? 'checkin/': ''}api/v1/upload`,

        NONCE_EP: `${config.CHECKIN_SERVER}/${isLocal ? 'checkin/': ''}api/v1/nonce`,
    }
}
