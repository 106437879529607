import { isMobile } from "react-device-detect";

export const valides = (valide: any) => {
  if (valide) {
    return true;
  } else {
    return false;
  }
};

export const LANGUAGE_KEY = "language";

export const STEPS = {
  launch: "launch_step",
  outbound: "outboand_step",
  options: "options_step",
  passenger: "passenger_step",
  payment: "payment_step",
};

export const IFRAME_EVENT = {
  start_validation: 'startValidation',
  validation_result: "validationResult",
  continue: "continue",
  close: "close",
  error: "error",
};

export function getIsInMobile(): boolean {
  return isMobile;
}

export function keyToString(key: string) {
  function camelCaseSpace(str: string) {
    return str.replace(/([A-Z])/g, " $1").trim();
  }

  function removeUnderscore(str: string) {
    return str.replace(/_/g, " ");
  }

  function lowerCase(str: string) {
    return str.replace(/[A-Z]/g, (s) => s.toLowerCase());
  }

  function upperCase(str: string) {
    return str.replace(/^[a-z]/, (s) => s.toUpperCase());
  }

  return upperCase(lowerCase(camelCaseSpace(removeUnderscore(key))));
}

export function formatBytes(bytes: number) {
  const sizes = ["B", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 B";
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return (bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i];
}

export function getIsPDF(file: Blob | string) {
  if (!file) {
    return false;
  }

  if (file && file instanceof Blob) {
    return /application\/pdf/.test(file.type);
  }

  if (typeof file === "string") {
    return file.startsWith("data:application/pdf") || file.endsWith(".pdf");
  }

  return false;
}

export function sanitizeHtml(html: string | null | undefined) {
  if (!html) {
    return undefined;
  }
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;

  const allowedTags: string[] = ["b", "i", "em", "strong", "span", "p", "br"];
  const allowedAttributes: string[] = ["style"];

  const cleanElement = (element: HTMLElement): void => {
    const children = Array.from(element.childNodes);

    children.forEach((child) => {
      if (child.nodeType === 1) {
        const childElement = child as HTMLElement;
        if (!allowedTags.includes(childElement.tagName.toLowerCase())) {
          childElement.replaceWith(...childElement.childNodes);
        } else {
          Array.from(childElement.attributes).forEach((attr) => {
            if (!allowedAttributes.includes(attr.name)) {
              childElement.removeAttribute(attr.name);
            }
          });
        }
        cleanElement(childElement);
      }
    });
  };

  cleanElement(tempDiv);

  return tempDiv.innerHTML;
}
