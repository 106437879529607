import { useCallback } from "react";
import { Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from "@ant-design/icons";
import { FlightTicketExpanded } from 'utils/checkin';
import './TicketInfo.css';

export interface TicketInfoProps {
  ticket: FlightTicketExpanded,
  // booking: Booking,
  onShowInfo?: (ticket: FlightTicketExpanded) => void,
  valid?: boolean
}

export default function TicketInfo({
  ticket,
  // booking,
  onShowInfo,
  valid
}: TicketInfoProps) {
  const { t } = useTranslation();

  const handleShowInfo = useCallback(() => {
    onShowInfo?.(ticket);
  }, [ticket]);

  return (
    <div className='TicketInfo-booking'>
      <Col span={24} style={{ borderBottom: '1px solid #FFF', paddingLeft: 30 }}>
        <Row style={{ width: '100%' }}>
          <Col span={8}>
            <Typography>{t('name')}</Typography>
            <Col>
              <Typography.Title level={5}>
                {ticket.passenger_firstname} {ticket.passenger_lastname}
              </Typography.Title>
            </Col>
          </Col>
          <Col span={8}>
            <Typography>{t('document')}</Typography>
            <Col>
              <Typography.Title level={5}>{ticket.passenger_document || t('na')}</Typography.Title>
            </Col>
          </Col>
          <Col span={8}>
            <Typography>{t('PassengerScreen.seat_number')}</Typography>
            <Col>
              <Typography.Title level={5}>{Array.isArray(ticket.validation_info_list) ? ticket.validation_info_list[0]?.booking?.seat_number : t('na')}</Typography.Title>
            </Col>
          </Col>
        </Row>
      </Col>
      <Col span={24} style={{ borderBottom: '1px solid #FFF', paddingLeft: 30 }}>
        <Row style={{ width: '100%' }}>
          <Col span={8}>
            <Typography>{t('TicketInfo.phoneNumber')}</Typography>
            <Col>
              <Typography.Title level={5}>
                {ticket.booking?.phone ?? t('na')}
              </Typography.Title>
            </Col>
          </Col>
          <Col span={8}>
            <Typography>{t('TicketInfo.contact')}</Typography>
            <Col>
              <Typography.Title level={5}>{Array.isArray(ticket.validation_info_list) ? ticket.validation_info_list[0]?.booking?.email : t('na')}</Typography.Title>
            </Col>
          </Col>
          <Col span={8}>
          </Col>
        </Row>
      </Col>
      <Col span={24} style={{ borderBottom: '1px solid #FFF', paddingLeft: 30 }}>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <Col>
              <Button
                type="primary"
                icon={valid ? <CheckOutlined style={{ color: '' }} /> : null}
                onClick={handleShowInfo}
                style={{ margin: '5px 0' }}
              >
                {t('TicketInfo.showValidation')}
              </Button>
            </Col>
          </Col>
        </Row>
      </Col>
    </div>
  );
}