import MainForm from './MainForm';
import Header from './components/Header';

const PassengerScreen = () => {
    return (
        <div style={{maxWidth: 950, margin: '0 auto', width: '100%', marginTop: 20}}>
            <Header />
            <MainForm />
        </div>
    );
};
export default PassengerScreen;
