import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useContextSelector } from 'use-context-selector';
import { AppContext, AppContextType } from 'AppContext';
import { Button, Col, Divider, Flex, Input, notification, Row, Typography } from 'antd';
import Label from 'components/Label';
import Loader from 'components/Loader';
import Portal from 'components/Portal';
import BookingCard from './components/BookingCard';
import { IDS } from 'helpers/constants';
import { BookingTickets } from 'utils/mybooking';
import './BookingsScreen.css';

const NOTIFICATION_KEY = 'booking_notif';

function BookingScreen({ onClose, pnr, ln, fn, ticketNumber }: {
  onClose: () => void,
  pnr?: string | undefined,
  ln?: string | undefined,
  fn?: string | undefined,
  ticketNumber?: string
}) {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const searchBookings = useContextSelector(AppContext, (c: AppContextType) => c.booking.searchBookingsInCheckin);
  const [loading, setLoading] = useState<boolean>(false);
  const [booking, setBooking] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [result, setResult] = useState<BookingTickets | null>(null);

  const onBookingChange = useCallback((event: any) => {
    if (result) {
      setResult(null);
    }
    setBooking(event.target.value);
  }, [result]);

  const onLastNameChange = useCallback((event: any) => {
    if (result) {
      setResult(null);
    }
    setLastName(event.target.value);
  }, [result]);

  const onSearch = useCallback((pnr: string, lastName: string) => {
    setLoading(true);
    setResult(null);
    searchBookings(pnr, lastName, ticketNumber)
      .then((resp: BookingTickets | null) => {
        console.log('[BookingScreen]', resp);
        setResult(resp);
        setLoading(false);
      })
      .catch((err) => {
        console.log('[BookingScreen] searchBookings', err);
        setLoading(false);
        if (err.message === 'BOOKING_NOT_FOUND') {
          api.warning({
            key: NOTIFICATION_KEY,
            message: t('warning'),
            description: t('BOOKING_NOT_FOUND'),
            placement: 'topRight',
          });
        } else {
          api.error({
            key: NOTIFICATION_KEY,
            message: t('error'),
            description: t([err.message, 'error_oops']),
            placement: 'topRight',
          });
        }
      })
  }, [api, onClose, booking, ticketNumber, lastName, searchBookings, t]);

  React.useEffect(() => {
    if (!booking || !lastName || !!result) {
      return
    }

    const handleKeyPress = (e: any) => {
      if (e.key === "Enter") {
        onSearch(booking, lastName);
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [booking, lastName, result, onSearch]);

  React.useEffect(() => {
    if (pnr && ln) {
      onSearch(pnr, ln);
    }
  }, [pnr, ln]);

  console.log('[BookingScreen ]============ result', result);

  return (
    <div className='BookingsScreen' style={{}}>
      <Typography.Title level={2}
        style={{ textAlign: 'center' }}>{t('BookingsScreen.title', { host: window.location.host })}</Typography.Title>
      <Typography.Paragraph
        style={{ fontSize: 16 }}>{t('BookingsScreen.description', { host: window.location.host })}</Typography.Paragraph>
      {
        !(pnr || ln) && (
          <Row gutter={[16, 8]}>
            <Col xs={24} md={10}>
              <Flex vertical>
                <Label text={t('BookingsScreen.bookingRef')} />
                <Input
                  size='large'
                  onChange={onBookingChange}
                />
              </Flex>
            </Col>
            <Col xs={24} md={10}>
              <Flex vertical>
                <Label text={t('BookingsScreen.lastName')} />
                <Input
                  size='large'
                  onChange={onLastNameChange}
                />
              </Flex>
            </Col>
            <Col xs={24} sm={24} md={4}>
              <Flex justify='flex-end' align='flex-end' style={{ height: '100%' }}>
                <Button
                  type='primary'
                  size='large'
                  style={{ background: '#FFF', color: '#000' }}
                  disabled={!booking || !lastName || !!result}
                  onClick={() => booking && lastName && onSearch(booking, lastName)}>
                  {t('BookingsScreen.search')}
                </Button>
              </Flex>
            </Col>
          </Row>
        )
      }
      {
        result && !!Object.keys(result).length && (
          <>
            <Divider />
            {
              result.flight_tickets
                // .filter(flight_ticket => flight_ticket.passenger_lastname === ln)
                .map(flight_ticket => {
                  return <BookingCard key={flight_ticket.id} flight_ticket={flight_ticket} />
                })
            }
          </>
        )
      }
      {
        loading && <Portal id={IDS.loader}>
          <Loader />
        </Portal>
      }
      {contextHolder}
    </div>
  )
}

export default BookingScreen;

