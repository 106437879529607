  import endpoints from './endpoints';

const config = {
    HTTP_SERVER: process.env.REACT_APP_HTTP_SERVER || '',
    CHECKIN_SERVER: process.env.REACT_APP_CHECKIN_SERVER || '',
    CHECKIN_SERVER_API_KEY: process.env.REACT_APP_CHECKIN_SERVER_API_KEY || '',
    CHECKIN_SERVER_API_KEY_BY_ORIGIN: process.env.REACT_APP_CHECKIN_SERVER_API_KEY_BY_ORIGIN || '',
    RECAPTCHA: {
        SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
    },
    isDev: process.env.NODE_ENV === 'development',
    AIRLINE: process.env.REACT_APP_AIRLINE || '99@',
    PROVIDER_BY_ORIGIN: process.env.REACT_APP_PROVIDER_BY_ORIGIN || '',
    FLIGHT: {
        COLS: process.env.REACT_APP_FLIGHT_COLS
            ? JSON.parse(process.env.REACT_APP_FLIGHT_COLS)
            : ['A', 'B', 'C', 'D', 'E', 'F'],
        ROWS: (typeof process.env.REACT_APP_FLIGHT_ROWS_COUNT === 'string'
            ? parseInt(process.env.REACT_APP_FLIGHT_ROWS_COUNT, 10)
            : process.env.REACT_APP_FLIGHT_ROWS_COUNT) || 47
    },
    SUPPORT_EMAIL: process.env.REACT_APP_SUPPORT_EMAIL || 'info@cyberfishag.ch',

    JSONFORMS: {
        DOCUMENT_FIELD:
          process.env.REACT_APP_JSONFORMS_DOCUMENT_FIELD || "documentFile",
        TITLE: process.env.REACT_APP_JSONFORMS_TITLE || "\\$title$\\",
        ANSWER_FIELD: process.env.REACT_APP_JSONFORMS_ANSWER_FIELD || "answer",
        DOC_HELPER_FIELD:
          process.env.REACT_APP_JSONFORMS_DOC_HELPER_FIELD || "#doc_helper",
        STEP_HELPER_FIELD:
          process.env.REACT_APP_JSONFORMS_STEP_HELPER_FIELD || "#step_helper",
        TRAVEL_DOCUMENT_FORM:
          process.env.REACT_APP_JSONFORMS_TRAVEL_DOCUMENT_FORM || "",
        MRZ_FIELD: process.env.REACT_APP_JSONFORMS_MRZ_FIELD || "227",
        RFID_FIELD: process.env.REACT_APP_JSONFORMS_RFID_FIELD || "228",
        ICAO_CODE_FIELD: process.env.REACT_APP_JSONFORMS_ICAO_CODE_FIELD || "244",
        DOCUMENT_NAME_FIELD:
          process.env.REACT_APP_JSONFORMS_DOCUMENT_NAME_FIELD || "255",
        FULLNAME_FIELD: process.env.REACT_APP_JSONFORMS_FULLNAME_FIELD || "259",
        REGULA_ID: process.env.REACT_APP_JSONFORMS_REGULA_FIELD || "246",
        ACCEPT_ID: process.env.REACT_APP_JSONFORMS_ACCEPT_FIELD || "260",
        DEFAULT_SCHEME_ERROR:
          process.env.REACT_APP_JSONFORMS_DEFAULT_SCHEME_ERROR || "Manual validate",
        TRAVEL_DOCUMENT_EXPIRED_ERROR:
          process.env.REACT_APP_JSONFORMS_TRAVEL_DOCUMENT_EXPIRED_ERROR ||
          '["00 Expired Passport (Bona Fide)","Expired Passport/ID", "36 Passport not valid long enough (validity) (Bona Fide)"]',
    },

    RECOGNIZERS: {
        REGULA: process.env.REACT_APP_RECOGNIZERS_REGULA || "regula",
        EUDCC: process.env.REACT_APP_RECOGNIZERS_EUDCC || "eudcc",
        CUSTOM: process.env.REACT_APP_RECOGNIZERS_CUSTOM || "custom",
        MANUAL: process.env.REACT_APP_RECOGNIZERS_MANUAL || "manual",
    },

    DOCUMENT_CATEGORIES: {
        ID: process.env.REACT_APP_DOCUMENT_CATEGORIES_ID || "1",
        VISA: process.env.REACT_APP_DOCUMENT_CATEGORIES_VISA || "2",
        HEALTH: process.env.REACT_APP_DOCUMENT_CATEGORIES_HEALTH || "3",
        FORM: process.env.REACT_APP_DOCUMENT_CATEGORIES_FORM || "4",
        QUESTION: process.env.REACT_APP_DOCUMENT_CATEGORIES_QUESTION || "5",
        ADDITIONAL_DOCUMENT:
          process.env.REACT_APP_DOCUMENT_CATEGORIES_ADDITIONAL_DOCUMENT || "18",
        PHOTO: process.env.REACT_APP_DOCUMENT_CATEGORIES_PHOTO || "22",
    },

    CHECKIN_ENABLED: process.env.REACT_APP_CHECKIN_ENABLED || '',
    CHECKIN_DEFAULT_PHONE: process.env.REACT_APP_CHECKIN_DEFAULT_PHONE || '',
    CHECKIN_IFRAME: process.env.REACT_APP_CHECKIN_IFRAME || '',
    CHECKIN_PARENT_DOMAIN: process.env.REACT_APP_CHECKIN_PARENT_DOMAIN || '',
    CALL_CENTER_PHONE: process.env.REACT_APP_CALL_CENTER_PHONE || "+41 900 734 736",

    IS_EDELWEISS: process.env.REACT_APP_IS_EDELWEISS || 'true',

    EASY_JET_DOMAIN: process.env.REACT_APP_EASY_JET_DOMAIN || 'https://ez-demo-sandbox.comppass.io'
    
}

export default {
    ...config,
    ...endpoints(config),
}