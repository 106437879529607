import React from "react";
import { useTranslation } from "react-i18next";
import { documentType, pasSexOption, Pax } from "utils/booking";
import dayjs from "dayjs";
import {
    Input,
    Select,
    Col,
    Row,
    Checkbox,
    Flex,
    Typography,
    Radio,
    DatePicker,
    Divider
} from "antd";
import { Field, ErrorMessage } from "formik";
import PhoneNumber from "../../../components/PhoneNumber";
import Label from "components/Label";
import { Countries, filterOptionCountry } from "utils/countries";
import { isMobile } from "react-device-detect";

import "../style.css";

function getDobMaxDate() {
    return dayjs().add(1, 'day').startOf('day')
}

function getExpiredAtMinDate() {
    return dayjs().add(-1, "days")
}

function disabledDate(date: any, min: any, max: any) {
    return max <= date || min >= date
}

const DATE_FORMAT: any = {
    format: 'DD.MM.YYYY',
    type: 'mask'
}

export interface IProps {
    name: string;
    type: 'adult' | 'children' | 'infant';
    index: number;
    typeIndex: number;
    primary: boolean;
    pax?: Pax;
    //TODO add a new prop, like meta, where to pass the information from the previous step (seat number, etc.)
}

const { Text } = Typography;
const PaxForm = ({ name, type, index, typeIndex, primary, pax }: IProps) => {
    const { t } = useTranslation();

    const [showDetails, setShowDetails] = React.useState<boolean>(false);

    const documentTypeOptions = [
        {
            label: t("PassengerScreen.DocumentTypes.passport"),
            value: documentType.P,
        },
        {
            label: t("PassengerScreen.DocumentTypes.idCard"),
            value: documentType.I,
        },
    ];
    const passengerSexOptions = [
        {
            label: "M",
            value: pasSexOption.MR,
        },
        {
            label: "F",
            value: pasSexOption.MS,
        },
    ];
    const onToggleDetails = React.useCallback(() => setShowDetails(v => !v), []);

    const renderError = React.useCallback((error: string) => {
        if (!error) {
            return null;
        }

        return (
            <div className='errorText'>
                {error}
            </div>
        );
    }, []);

    const getRenderSelect = React.useCallback((options: { value: string; label: string }[], filterOption?: any, showSearch?: boolean) => {
        return ({ field, form, meta }: any) => {
            return (
                <>
                    <Select
                        size='large'
                        style={{ width: "100%", height: 50 }}
                        onChange={(e) => form.setFieldValue(field.name, e)}
                        options={options}
                        value={field.value}
                        showSearch={showSearch}
                        filterOption={filterOption}

                    />
                    {renderError(meta.error)}
                </>
            );
        }
    }, [renderError]);

    const getRadioGroup = React.useCallback((variants: { value: string; label: string }[]) => {
        return ({ field, form, meta }: any) => {
            return (
                <>
                    <Radio.Group
                        style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                        onChange={(e) => form.setFieldValue(field.name, e.target.value)}
                        value={field.value}
                    >
                        {
                            variants.map((variant: { value: string, label: string }, idx: number) => (
                                <Radio key={idx} value={variant.value}>{variant.label}</Radio>
                            ))
                        }
                    </Radio.Group>
                    {renderError(meta.error)}
                </>
            )
        }
    }, [renderError])

    const getRenderDate = React.useCallback((min: any | undefined, max: any | undefined) => {
        return ({ field, form, meta }: any) => {
            return (
                <>
                    <DatePicker
                        name={field.name}
                        size='large'
                        className={isMobile ? 'mobile' : undefined}
                        onChange={(date: any) => {
                            form.setFieldValue(
                                field.name,
                                date ? dayjs(date).format('YYYY-MM-DD') : null
                            );
                        }}
                        format={DATE_FORMAT}
                        value={field.value ? dayjs(field.value) : null}
                        onBlur={field.onBlur}
                        onFocus={field.onFocus}
                        disabledDate={(current) => disabledDate(current, min, max)}
                    />
                    {meta.touched && renderError(meta.error)}
                </>
            );
        }
    }, [renderError]);

    return (
        <div className="PassengerForm">
            {/* <Row gutter={[16, 16]}> */}
            <Row
                gutter={[16, 16]}
                style={{ marginBottom: 10, alignItems: 'flex-start', width: '100%' }}
            >
                <Col md={{ span: 12, order: 1 }} xs={{ span: 24, order: 2 }}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Text ellipsis>
                                {t("PassengerScreen.firstName")}<span style={{ color: 'red' }}>*</span>
                            </Text>
                            <Field
                                as={Input}
                                name={`${name}.fn`}
                                size='large'
                            />
                            <ErrorMessage
                                name={`${name}.fn`}
                                render={renderError}
                            />
                        </Col>
                        <Col xs={24}>
                            <Text ellipsis>
                                {t("PassengerScreen.middleName")}
                            </Text>
                            <Field
                                as={Input}
                                name={`${name}.mn`}
                                size='large'
                            />
                            <ErrorMessage
                                name={`${name}.mn`}
                                render={renderError}
                            />
                        </Col>
                        <Col xs={24}>
                            <Text ellipsis>{t("PassengerScreen.lastName")}<span style={{ color: 'red' }}>*</span></Text>
                            <Field
                                as={Input}
                                name={`${name}.ln`}
                                size='large'
                            />
                            <ErrorMessage
                                name={`${name}.ln`}
                                render={renderError}
                            />
                        </Col>
                        <Col xs={24}>
                            <Flex vertical>
                                <Text ellipsis>
                                    {t("PassengerScreen.dateOfBirth")}<span style={{ color: 'red' }}>*</span>
                                </Text>
                                <Field
                                    name={`${name}.dob`}
                                    size='large'
                                >
                                    {getRenderDate(undefined, getDobMaxDate())}
                                </Field>
                            </Flex>
                        </Col>
                        <Col xs={24}>
                            <Text ellipsis>
                                {t("PassengerScreen.docNumber")}
                            </Text>
                            <Field
                                as={Input}
                                name={`${name}.document_number`}
                                size='large'
                            />
                            <ErrorMessage
                                name={`${name}.document_number`}
                                render={renderError}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col md={{ span: 12, order: 2 }} xs={{ span: 24, order: 1 }}>
                    <Flex vertical>
                        <Typography.Title level={3} style={{ margin: 0 }}>{t('PassengerScreen.seatReservation')}</Typography.Title>
                        {/* <Typography.Text style={{fontSize: 16}}>{t('PassengerScreen.outboundFlight')}</Typography.Text> */}
                        <Typography.Text style={{fontSize: 18}}>{pax?.seat_number}</Typography.Text>
                    </Flex>
                    <Divider />
                </Col>
            </Row>
            {
                primary ? (
                    <Row
                        gutter={[16, 16]}
                        style={{ marginBottom: 10, alignItems: 'flex-start',width: '100%' }}
                    >
                        <Col md={{ span: 12, order: 1 }} xs={{ span: 24, order: 2 }}>
                            <Row gutter={[0, 16]} style={{ marginBottom: 10, alignItems: 'flex-start', width: '100%' }}>
                                <Col xs={24}>
                                    <Text ellipsis>
                                        {t("PassengerScreen.phoneNumber")}
                                    </Text>
                                    <Field
                                        name={`${name}.phone`}
                                    >
                                        {({ field, form, meta }: any) => {
                                            return (
                                                <>
                                                    <PhoneNumber
                                                        name={field.name}
                                                        value={field.value}
                                                        defaultCountry={form.values.adults[0].phone_country || 'ch'}
                                                        onChange={(fieldname, value, country) => {
                                                            form.setFieldValue(fieldname, value);
                                                            form.setFieldValue(`${name}.phone_country`, country);
                                                        }}
                                                    />
                                                    {renderError(meta.error)}
                                                </>
                                            );
                                        }}
                                    </Field>
                                </Col>
                                <Col xs={24}>
                                    <Text ellipsis>{t("PassengerScreen.email")}<span style={{ color: 'red' }}>*</span></Text>
                                    <Field
                                        as={Input}
                                        name={`${name}.email`}
                                        size='large'
                                    />
                                    <ErrorMessage
                                        name={`${name}.email`}
                                        render={renderError}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={{ span: 12, order: 2 }} xs={{ span: 24, order: 1 }}>
                            <Flex vertical>
                                <Typography.Paragraph style={{fontSize: 16, marginTop: 15}}>{t('PassengerScreen.contacDescription')}</Typography.Paragraph>
                            </Flex>
                        </Col>
                    </Row>
                ) : null
            }
            <Row
                gutter={[16, 16]}
                style={{ marginBottom: 10, alignItems: 'flex-start', width: '100%' }}
            >
                <Col xs={24}>
                    <Flex vertical style={{ height: '100%' }}>
                        <Text ellipsis>
                            {t("PassengerScreen.sex")}
                        </Text>
                        <Field
                            name={`${name}.sex`}
                        >
                            {/* {getRenderSelect(passengerSexOptions)} */}
                            {getRadioGroup(passengerSexOptions)}
                        </Field>
                    </Flex>
                </Col>
            </Row>
            <Flex align='center' style={{ marginBottom: 10 }}>
                <Flex
                    align='center'
                    style={{ marginBottom: 10 }}
                >
                    <Checkbox
                        style={{ marginRight: 10 }}
                        checked={showDetails}
                        onChange={onToggleDetails}
                    />
                    <Label
                        text={t("PassengerScreen.addDeitals")}
                    />
                </Flex>
            </Flex>
            {showDetails && (
                <Row gutter={[16, 16]}
                    style={{ marginBottom: 10, alignItems: 'flex-start', width: '100%' }}
                >
                    <Col md={{ span: 12, order: 1 }} xs={{ span: 24, order: 2 }}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24}>
                                <Text ellipsis>
                                    {t("PassengerScreen.docType")}
                                </Text>
                                <Field
                                    name={`${name}.document_type`}
                                    size='large'
                                >
                                    {getRenderSelect(documentTypeOptions)}
                                </Field>
                            </Col>
                            <Col xs={24}>
                                <Text ellipsis>
                                    {t(
                                        "PassengerScreen.document_issued_at"
                                    )}
                                </Text>
                                <Field
                                    name={`${name}.document_issued_at`}
                                    size='large'
                                >
                                    {getRenderSelect(Countries, filterOptionCountry, true)}
                                </Field>
                            </Col>
                            <Col xs={24}>
                                <Flex vertical>
                                    <Text ellipsis>
                                        {t("PassengerScreen.docExDate")}
                                    </Text>
                                    <Field
                                        name={`${name}.document_expiry_date`}
                                        size='large'
                                    >
                                        {getRenderDate(getExpiredAtMinDate(), undefined)}
                                    </Field>
                                </Flex>
                            </Col>
                            <Col xs={24}>
                                <Text ellipsis>
                                    {t("PassengerScreen.nationality")}
                                </Text>
                                <Field
                                    name={`${name}.document_nationality`}
                                    size='large'
                                >
                                    {getRenderSelect(Countries, filterOptionCountry, true)}
                                </Field>
                            </Col>
                            <Col xs={24}>
                                <Text ellipsis>
                                    {t("PassengerScreen.address")}
                                </Text>
                                <Field
                                    as={Input}
                                    name={`${name}.address`}
                                    size='large'
                                />
                            </Col>
                            <Col xs={24}>
                                <Text ellipsis>{t("PassengerScreen.city")}</Text>
                                <Field
                                    as={Input}
                                    name={`${name}.city`}
                                    size='large'
                                />
                            </Col>
                            <Col xs={24}>
                                <Text ellipsis>
                                    {t("PassengerScreen.zipCode")}
                                </Text>
                                <Field
                                    as={Input}
                                    name={`${name}.zip`}
                                    size='large'
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={{ span: 12, order: 2 }} xs={{ span: 24, order: 1 }} />
                </Row>
            )}
        </div>
    );
};

export default PaxForm;
