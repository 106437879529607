import { Drawer, Typography } from 'antd';
import React, { useCallback, useState } from 'react'
import { useTranslation } from "react-i18next";
import BookingScreen from "../../../BookingsScreen";
import { Ticket } from "../../../../utils/booking";
import { FlightTicketExpanded, ValidationRequest, ValidationResultInfo } from "../../../../utils/checkin";
import './BookingInfoDrawer.css';


export interface BookingInfoProps {

}

interface BookingInfoDrawerProps {
  ticket: FlightTicketExpanded,
  validationResult?: ValidationResultInfo | null,
  validationRequest: ValidationRequest,
  close: () => void,
}

export default function BookingInfoDrawer({
  ticket,
  validationResult,
  close,
  validationRequest
}: BookingInfoDrawerProps) {
  const { t } = useTranslation();

  return (
    <Drawer
      height={'100%'}
      title={<Typography style={{ fontSize: 24, color: '#FFF' }}>{t('myBookings')}</Typography>}
      placement="bottom"
      onClose={close}
      open={!!ticket}
      classNames={{
        header: 'BookingInfoDrawer-header',
        body: 'BookingInfoDrawer-body',
        footer: 'BookingInfoDrawer-footer'
      }}
    >
      <div className={'BookingInfoDrawer'} style={{ maxWidth: 950, margin: '0 auto' }}>
        <BookingScreen
          onClose={close}
          pnr={validationResult?.pnr || validationRequest?.pnr}
          ln={ticket?.passenger_lastname ?? ''}
          fn={ticket?.passenger_firstname ?? ''}
          ticketNumber={ticket?.ticket_number ?? ''}
        />
      </div>
    </Drawer>
  )
}
