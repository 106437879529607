import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import PlaneSeating from './components/PlaneSeating';
import FlightInformation from 'components/FlightInfrormation';
import buisenesDesctopImg from '../../assets/business_longhaul_350x525.jpg';
import economyDesctopImg from '../../assets/economy_longhaul_350x525.jpg';
import BoardingPassDriver from "../../components/BoardingPassDriver";
import './OutboundScreen.css'
import { useContextSelector } from "use-context-selector";
import { AppContext, AppContextType } from "../../AppContext";
import { FlightV2, Route } from 'utils/flight';
import moment from 'moment';
import "moment-duration-format";
import { getCalculatedPrices } from 'utils/serviceClass';
import { Currency, getCorrectPrice, getCurrencyIcon } from 'utils/currency';
import usePaxCount from 'hooks/usePaxCount';
import { STEPS } from 'utils/utilits';
import { Direction } from "../../utils/booking";
import FlightInformationTwoDirect from "components/FlightInfrormationTwoDirect";
import OutboundInfo from 'components/OutboundInfo';


function OutboundDurationCard({ flight, date }: { flight: FlightV2, date?: Dayjs | null }) {
  const { t, i18n } = useTranslation();

  const flightNumbers = (flight.route || []).map((route) => `${route.origin_name} - ${route.destination_name}`);

  const duration = useMemo(() => {
    moment.locale(i18n.language);
    const departure_time = flight.route[0].flight_departure_time;
    const arrival_time = flight.route[flight.route.length - 1].flight_arrival_time;
    const a = moment(`${date?.format('YYYY-MM-DD')}T${departure_time}`);
    const b = moment(`${date?.format('YYYY-MM-DD')}T${arrival_time}`);
    return moment.duration(b.diff(a, 'minutes'), 'minutes').format(`h [${t('hrs')}] mm [${t('min')}]`);
  }, [i18n.language, flight.route, date]);

  return (
    <div className="OutboundScreen-slider">
      <fieldset
        className="js-flightselection-slider ibe_flight_selection_list js-ibe_flight_selection_list is-initialized">
        <legend className="visuallyhidden">{t("OutboundDurationCard.legend")}</legend>
        <ul className="js-flightselection-slider--wrapper ibe_flight_selection_list--wrapper one-item">
          <li className="js-flightselection-slider--slide ibe_flight_selection_list--item" id="sliderItem-u2">
            <span className="ibe_flight_selection">
              <span className="ibe_flight_selection--inner">
                <label className="ibe_flight_selection--content" htmlFor="flightselection-u1-u2">
                  <span className="ibe_flight_selection--number">
                    <strong className="ibe_flight_selection--text">{flightNumbers?.join(' -> ')}</strong><span
                      className="ibe_flight_selection--label" title="" data-textoverflow="true">
                    </span>
                  </span>
                  <span className="ibe_flight_selection--content--details">
                    <span className="ibe_flight_selection--duration">
                      <span className="ibe_flight_selection_duration">
                        <span className="ibe_flight_selection_duration--inner">
                          <span
                            className="ibe_flight_selection_duration-circle ibe_flight_selection_duration-circle-outline">
                          </span>
                          <span className="ibe_flight_selection_duration-circle ibe_flight_selection_duration-circle-anim">
                            {
                              flight.route.length > 1 ? (
                                flight.route.map((_, idx) => <span
                                  className={`ibe_flight_selection_duration-circle-anim-plane transit-point-${idx}`} />)
                              ) : (
                                <span className="ibe_flight_selection_duration-circle-anim-plane" />
                              )
                            }
                          </span>
                        </span>
                        <span className="ibe_flight_selection_duration--content">
                          <span className="ibe_flight_selection_duration--label">
                            {t("OutboundDurationCard.duration", { duration })}
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                  <span className="ibe_flight_selection--content--details">
                    <span className="ibe_flight_selection--location">
                      <span className="ibe_flight_selection--label">{t("OutboundDurationCard.departure")}</span>
                      <strong
                        className="ibe_flight_selection--location--time">{flight?.route[0].flight_departure_time}</strong>
                    </span>
                    <span className="ibe_flight_selection--location ibe_flight_selection--location-right">
                      <span className="ibe_flight_selection--location--inner">
                        <span className="ibe_flight_selection--label">{t("OutboundDurationCard.arrival")}</span>
                        <strong
                          className="ibe_flight_selection--location--time">{flight?.route[flight.route.length - 1].flight_arrival_time}</strong>
                      </span>
                    </span>
                  </span>
                </label>
                <span role="button" className="tooltip">
                  <span aria-hidden="true" className="icon tooltip--icon">&nbsp;</span>
                  <span className="tooltip--title"> Show flight details WK 2 </span></span></span></span>
          </li>
        </ul>
        <div className="ibe_flight_selection_list-navigation js-flightselection-slider--navigation">
          <div
            className="ibe_flight_selection_list-navigation--element js-flightselection-slider--navigation-element"></div>
        </div>
      </fieldset>
    </div>
  )
}

interface OutboundSeatsClassCardProps {
  type?: string,
  isOpened?: boolean,
  onOpened: (value: string | undefined | null) => void
}

function OutboundSeatsClassCard({ type, isOpened, onOpened }: OutboundSeatsClassCardProps) {
  const { t } = useTranslation();
  const flight = useContextSelector(AppContext, (c: AppContextType) => c.booking.flight);
  const classes = useContextSelector(AppContext, (c: AppContextType) => c.serviceClass.classes);
  const currency: Currency = useContextSelector(AppContext, (c: AppContextType) => c.wallet.currency);
  const paxCount = usePaxCount();

  const price = useMemo(() => {
    const flightClasses = type === 'economy' ? classes.find((it) => it.id.includes('standard')) : classes.find((it) => it.id.includes('flex'))
    if (flightClasses) {
      const prices = getCalculatedPrices(flightClasses, currency, paxCount, flight?.route.length);
      return getCorrectPrice(Math.min(prices.adults + prices.childs + prices.infants + prices.tax))
    }
  }, [type, classes, currency, paxCount, flight]);

  const toogleExpand = useCallback(() => {
    console.log('toogleExpand', type, isOpened);
    onOpened(isOpened ? null : type);
  }, [isOpened, type, onOpened]);

  const info = useMemo(() => {
    if (type === 'economy') {
      return {
        desktopImg: `url(${economyDesctopImg})`,
        mobileImg: `url(${economyDesctopImg})`,
        title: t('OutboundSeatsClassCard.economyClass'),
        price: t('OutboundSeatsClassCard.price', { price: `${getCurrencyIcon(currency)} ${price}` }),
        handBaggage: t('OutboundSeatsClassCard.handBaggage', { weight: 8 }),
        freeBaggage: t('OutboundSeatsClassCard.freeBaggage', { weight: 23 }),
        sportBaggage: t('OutboundSeatsClassCard.sportBaggage', { weight: 23 }),
        options: [
          t('OutboundSeatsClassCard.optionFood'),
          t('OutboundSeatsClassCard.optionMovie'),
          t('OutboundSeatsClassCard.optionJournal'),
        ]
      }
    } else {
      return {
        desktopImg: `url(${buisenesDesctopImg})`,
        mobileImg: `url(${buisenesDesctopImg})`,
        title: t('OutboundSeatsClassCard.businessClass'),
        price: t('OutboundSeatsClassCard.price', { price: `${getCurrencyIcon(currency)} ${price}` }),
        handBaggage: t('OutboundSeatsClassCard.handBaggage', { weight: 8 }),
        freeBaggage: t('OutboundSeatsClassCard.freeBaggage', { weight: 32 }),
        options: [
          t('OutboundSeatsClassCard.priorityService'),
          t('OutboundSeatsClassCard.loungeAccess'),
          t('OutboundSeatsClassCard.lieFlatBed'),
          t('OutboundSeatsClassCard.finestMenu'),
          t('OutboundSeatsClassCard.optionJournal'),
        ]
      }
    }
  }, [currency, price, t, type]);

  return (
    <>
      <div onClick={toogleExpand} className="accordion--header" id="accordion--header-10">
        <div className="ibe_class_teaser">
          <div className="ibe_class_teaser--imgcontainer">
            <div className="ibe_class_teaser--img-desktop"
              style={{
                backgroundImage: info.desktopImg
              }}
            ></div>
            <div className="ibe_class_teaser--img-mobile"
              style={{
                backgroundImage: info.mobileImg
              }}
            ></div>
          </div>
          <div className="ibe_class_teaser--content" style={{ height: '390px' }}>
            <div className="ibe_class_teaser--header">
              <h3 className="ibe_class_teaser--title">{info.title}</h3>
              <div className="ibe_class_teaser--header--right">
                <p className="ibe_class_teaser--price">
                  {info.price}
                </p>
                <span className="ico-green_check icon"><span
                  className="visuallyhidden">{t('OutboundSeatsClassCard.selected')}</span></span>
              </div>
            </div>
            <div className="ibe_class_teaser--body">
              <ul className="ibe_class_teaser--info">
                <li className="ibe_class_teaser--info--item">
                  <span
                    className="ico-S-C-0M1 ico-bundlefeature-preferred-dark icon"></span>
                  <span
                    className="ibe_class_teaser--info--item--text">{info.handBaggage}</span></li>
                <li className="ibe_class_teaser--info--item">
                  <span
                    className="ico-S-C-0GO ico-bundlefeature-preferred-dark icon"></span>
                  <span
                    className="ibe_class_teaser--info--item--text">{info.freeBaggage}</span>
                </li>
                {
                  info.sportBaggage && (
                    <li className="ibe_class_teaser--info--item">
                      <span
                        className="ico-S-C-0EG ico-bundlefeature-preferred-dark icon"></span>
                      <span
                        className="ibe_class_teaser--info--item--text">{info.sportBaggage}</span>
                    </li>
                  )
                }
                {(info?.options || []).map(option => {
                  return (
                    <li key={option} className="ibe_class_teaser--info--item">
                      <span
                        className="ico-S-G-0B3 ico-bundlefeature-preferred-dark icon"></span>
                      <span
                        className="ibe_class_teaser--info--item--text">{option}</span>
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className="ibe_class_teaser--footer">
              <div className="ibe_class_teaser--actions ibe_class_selection--action">
                <span aria-hidden="true"
                  className="ew_button ew_button-brown ibe_class_selection--actions--select"><span
                    className="ew_button--inner"> {t('OutboundSeatsClassCard.select')} </span>
                </span>
                <button
                  onClick={toogleExpand}
                  className="ew_button ew_button-gray_light ibe_class_selection--actions--deselect js-ibe-class-selection--trigger">
                  <span className="ew_button--inner"> {t('OutboundSeatsClassCard.cancel')} </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <span className="ibe_class_selection--trigger js-ibe-class-selection--trigger"
          aria-describedby="accordion--header-10" id="ibe_class_selection-10">
          <span className="visuallyhidden">Proceed with Economy Class - from CHF 651.50</span>
        </span>
        <button aria-expanded="false" type="button"
          className="accordion--trigger js-ibe-class-selection--trigger"
          aria-describedby="accordion--header-10">
          <span className="visuallyhidden">Proceed with Economy Class - from CHF 651.50</span>
        </button>
      </div>
      <div className={"accordion--panel js-collapsible--container" + (isOpened && " is-opened")}>
        <div data-seatmap-container=""
          className="l-pane l-pane-align_center l-pane-gap_top-small l-pane-no_hpadding-mobile l-pane-theme_gray">
          <div className="ibe_seatmapcontainer" id="ibe_seatmap-eco_max"><h4
            className="ibe_seatmapcontainer--title">{t('OutboundSeatsClassCard.seatingPlan')}</h4>
            <div aria-hidden="true" className="ibe_seatmap_legend">
              <ul className="ibe_seatmap_legend--list">
                <li className="ibe_seatmap_legend--list--item"><p className="imagetext align-middle"><span
                  className="ico-free-seat-icon icon imagetext--image"></span><span
                    className="imagetext--text">{t('OutboundSeatsClassCard.freeSeats')}</span>
                </p></li>
                <li className="ibe_seatmap_legend--list--item"><p className="imagetext align-middle"><span
                  className="ico-taken-seat-icon icon imagetext--image"></span><span
                    className="imagetext--text">{t('OutboundSeatsClassCard.occupiedSeats')}</span>
                </p></li>
                <li className="ibe_seatmap_legend--list--item"><p className="imagetext align-middle"><span
                  className="ico-choosen-seat-icon icon imagetext--image"></span><span
                    className="imagetext--text">{t('OutboundSeatsClassCard.selectedSeats')}</span>
                </p></li>
                <li className="ibe_seatmap_legend--list--item"><p className="imagetext align-middle"><span
                  className="ico-exit-arrow-right icon imagetext--image"></span><span
                    className="imagetext--text">{t('OutboundSeatsClassCard.emergencyExit')}</span>
                </p></li>
                <li className="ibe_seatmap_legend--list--item"><p className="imagetext align-middle"><span
                  className="ico-lavatory icon imagetext--image"></span><span
                    className="imagetext--text">{t('OutboundSeatsClassCard.toilets')}</span>
                </p></li>
                <li className="ibe_seatmap_legend--list--item"><p className="imagetext align-middle"><span
                  className="ico-galley icon imagetext--image"></span><span
                    className="imagetext--text">{t('OutboundSeatsClassCard.galley')}</span>
                </p></li>
              </ul>
            </div>
          </div>
          <PlaneSeating klass={type} />
        </div>
      </div>
    </>
  )
}

function OutboundSeatsClassCards() {
  const { t } = useTranslation();
  const [opened, setOpened] = useState<string | null | undefined>(null);

  return (
    <div
      className="OutboundSeatsClassCards ibe_class_selection js-slider js-sameheight is-initialized">
      <ul className="OutboundSeatsClassCards-accordion accordion js-slider--wrapper">
        <li className={"accordion--item js-collapsible ibe_class_selection--item " +
          "js-ibe-class-selection--item js-slider--slide " + (opened === "economy" && 'is-opened')}
        >
          <OutboundSeatsClassCard
            type={'economy'}
            isOpened={opened === 'economy'}
            onOpened={setOpened}
          />
        </li>
        <li className={
          "accordion--item js-collapsible ibe_class_selection--item" +
          " js-ibe-class-selection--item js-slider--slide " + (opened === "business" && 'is-opened')
        }
        >
          <OutboundSeatsClassCard
            type={'business'}
            isOpened={opened === 'business'}
            onOpened={setOpened}
          />
        </li>
      </ul>
    </div>
  )
}

function OutboundScreen() {
  const { t } = useTranslation();
  const setSeatRequests = useContextSelector(AppContext, (c: AppContextType) => c.seats.setSeatRequests);
  const bookingDirectFlight = useContextSelector(AppContext, (c: AppContextType) => c.booking);
  const bookingBackFlight = useContextSelector(AppContext, (c: AppContextType) => c.bookingBackFlight);

  const [direction, setDirection] = useState<Direction>(Direction.Forward);

  const {
    date,
    departure,
    arrival,
    counts,
    flight
  } = useMemo(() => {
    if (direction === Direction.Forward) {
      return bookingDirectFlight;
    } else {
      return bookingBackFlight;
    }
  }, [direction, bookingBackFlight, bookingDirectFlight]);

  const handleDirection = useCallback((direction: Direction) => {
    setDirection(direction);
  }, []);


  useEffect(() => {
    setSeatRequests(counts)
  }, [setSeatRequests, counts]);

  return (
    <div className="OutboundScreen">
      <div className={'ibe_summary--inner'} style={{ paddingTop: 20 }}>
        <FlightInformation
          routes={flight?.route as Route[]}
          originCountryName={departure?.country}
          destinationCountryName={arrival?.country}
          date={date}
          counts={{ adults: counts.adults, childs: counts.childs }}
          mode="white"
          type={direction === Direction.Forward ? 'out' : 'in'}
        />

        {
          direction === Direction.Forward && (
            <OutboundInfo
              flight={bookingDirectFlight.flight as FlightV2}
              date={bookingDirectFlight.date}
              counts={{ adults: counts.adults, childs: counts.childs }}
              passengers={bookingDirectFlight.passengers}
              mode="white"
              type={'outbound'}
              showNavigation={false}
              hideSeats
            />
          )
        }
        {
          direction === Direction.Back && (
            <OutboundInfo
              flight={bookingBackFlight.flight as FlightV2}
              date={bookingBackFlight.date}
              counts={{ adults: counts.adults, childs: counts.childs }}
              passengers={bookingBackFlight.passengers}
              mode="white"
              type={'inbound'}
              showNavigation={false}
              hideSeats
            />
          )
        }
      </div>
      <div className='OutboundScreen-content ibe_summary--inner'>
        <h2
          className="ibe_title">{t('OutboundScreen.selectFlight', { date: dayjs(date).format('dddd, MMMM D, YYYY') })}</h2>
        <p className="ibe_title--subtitle">{t('OutboundScreen.flightAvailable')}</p>
        {flight && <OutboundDurationCard flight={flight} date={date} />}
        <OutboundSeatsClassCards />
      </div>

      <BoardingPassDriver nextStep="/options" step={STEPS.outbound} onChangeDirection={handleDirection} />
    </div>
  );
}

export default OutboundScreen;
