import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { TOKEN_KEY } from 'helpers/constants';
import { getValueFromStorage } from './storage';
import config from 'config';

function buildQuery(params: Record<string, any>) {
    const query = new URLSearchParams();

    Object.keys(params).forEach((key) => {
        if (Array.isArray(params[key])) {
            params[key].forEach((p: any) => query.append(`${key}`, p));
        } else {
            query.append(key, params[key]);
        }
    });

    return query.toString();
}

const client: AxiosInstance = axios.create({
    baseURL: config.HTTP_SERVER || config.CHECKIN_SERVER,
    headers: {
        'Content-Type': 'application/json',
    },
});

export async function request(
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    originUrl: string,
    values: Record<string, any> | null | undefined,
    headers: Record<string, any> = {},
    controller?: AbortController | null
) {
    if (!originUrl) {
        throw new Error('invalid_url');
    }

    const url =
        (method === 'GET' || method === 'DELETE') && values
            ? `${originUrl}?${buildQuery(values)}`
            : originUrl;

    const tkn = getValueFromStorage(TOKEN_KEY);

    const config: AxiosRequestConfig = {
        method: method,
        url: url,
        headers: {
            'Content-Type': 'application/json',
            ...tkn ? { 'Authorization': tkn } : {},
            ...Object.keys(headers || {}).reduce((acc, key) => headers[key] ? ({ ...acc, [key]: headers[key] }) : acc, {}),
        },
        data: method !== 'GET' ? values : null,
        signal: controller?.signal,
    };

    try {
        const response = await client(config);
        console.log(`[rest] request to ${method}|${url} succeeded: status-`, response.status);
        return response.data;
    } catch (err: any) {
        console.log('[rest] request failed:', err, `"${err.message}"`);
        if (err.message === 'The user aborted a request.' || err.message === 'canceled') {
            throw new Error('request_aborted');
        }

        if (err.response) {
            const { status, data } = err.response;
            console.log(`[rest] request to ${method}|${url} failed: status-`, status);

            if (status === 504) {
                throw new Error('gateway_timeout');
            }

            if (status === 405 || status >= 500) {
                throw new Error('service_is_temporarily_unavailable');
            }

            if (status === 401 || status === 403) {
                throw { message: 'wrong_token', status };
            }

            if (data.resolvers) {
                throw data;
            } else if (data.detail || data.error || data.message) {
                throw new Error(data.detail || data.error || data.message);
            } else if (data.errors) {
                throw data.errors;
            } else {
                throw new Error(status === 400 ? 'wrong_request' : 'service_is_temporarily_unavailable');
            }
        } else {
            throw new Error('service_is_temporarily_unavailable');
        }
    }
}

export async function get(url: string, values: Record<string, any> | null = null, headers: Record<string, any>, controller?: AbortController | null) {
    return request('GET', url, values, headers || {}, controller);
}

export async function post(url: string, values: Record<string, any> | null = null, headers: Record<string, any>, controller?: AbortController | null) {
    return request('POST', url, values, headers || {}, controller);
}

export async function put(url: string, values: Record<string, any> | null = null, headers: Record<string, any>, controller?: AbortController | null) {
    return request('PUT', url, values, headers || {}, controller);
}

export async function patch(url: string, values: Record<string, any> | null = null, headers: Record<string, any>, controller?: AbortController | null) {
    return request('PATCH', url, values, headers || {}, controller);
}

export async function del(url: string, values: Record<string, any> | null = null, headers: Record<string, any>, controller?: AbortController | null) {
    return request('DELETE', url, values, headers || {}, controller);
}
