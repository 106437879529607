import React from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
    children: any;
    id: string;
    renderIfNoParent?: boolean;
};

function Portal({ id, children, renderIfNoParent }: PortalProps) {
    const [node, setNode] = React.useState<HTMLElement>();

    React.useEffect(() => {
        let node = document.getElementById(id);
        if (node) {
            setNode(node);
        }
    }, [id]);

    if (!node) {
        return renderIfNoParent ? children : null;
    }

    return ReactDOM.createPortal(
        children,
        node
    );
}

export default Portal;
