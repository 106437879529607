import React, { useCallback, useMemo, useState } from 'react'
import { Typography, Checkbox, Flex, Button, Modal, theme } from 'antd';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useTranslation } from 'react-i18next';
import { useContextSelector } from 'use-context-selector';
import { AppContext, AppContextType } from 'AppContext';
import Loader from 'components/Loader';
import Portal from 'components/Portal';
import usePaxCount from 'hooks/usePaxCount';
import { Pax, Ticket } from 'utils/booking';
import { IDS } from 'helpers/constants';
import FlightInformation from 'components/FlightInfrormation';
import PassengersInfo from 'components/PassengersInfo';
import OutboundInfo from 'components/OutboundInfo';
import OptionsInfo from 'components/OptionsInfo';
import { CaretRightOutlined, CheckCircleFilled } from '@ant-design/icons';
import { Route, FlightV2 } from 'utils/flight';

import './PaymentScreen.css';
import FlightInformationTwoDirect from "../../components/FlightInfrormationTwoDirect";

const { useToken } = theme;

const SeatClass = {
    ECONOMY: 'standard',
    BUSINESS: 'flex'
}

export default function PaymentScreen() {
    const { t } = useTranslation();
    const { token } = useToken();
    const navigate = useNavigate();
    const paxCount = usePaxCount();
    const bookingContext = useContextSelector(AppContext, (c: AppContextType) => c.booking);
    const bookingBackContext = useContextSelector(AppContext, (c: AppContextType) => c.bookingBackFlight);
    const clear = useContextSelector(AppContext, (c: AppContextType) => c.clear);
    const lang = useContextSelector(AppContext, (c: AppContextType) => c.language);
    const [conditionsChecked, setConditionsChecked] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const passengerCount = React.useMemo(() => {
        return paxCount.adults + paxCount.childs + paxCount.infants
    }, [paxCount]);

    const travelling = React.useMemo(() => {
        const paxEntries = Object.entries(paxCount)
            .filter(([, value]) => value > 0)
            .map(([key, value]) => `${value} ${key}`);

        return paxEntries.length === 1 ? paxEntries[0] : paxEntries.join(', ');
    }, [paxCount]);

    const travelDetails = useMemo(() => {
        return [...bookingContext.passengers.adults, ...bookingContext.passengers.childs, ...bookingContext.passengers.infants]
    }, [bookingContext]);


    const renderSuccessModal = useCallback((pnr: string) => {
        return (
            Modal.success({
                title: t('PaymentScreen.congratulations'),
                content: (
                    <div>
                        <Typography>{t('PaymentScreen.paymentSuccess', { email: bookingContext.passengers.adults[0].email })}</Typography>
                        <Flex gap='small' align='center' style={{ marginBottom: 10 }} wrap>
                            <Flex gap={5}>
                                <Typography>{t('PaymentScreen.bookingNumber')}:</Typography>
                                <Typography.Text ellipsis style={{ fontWeight: 'bold' }} copyable>{pnr}</Typography.Text>
                            </Flex>
                        </Flex>
                    </div>
                ),
                onOk: () => {
                    clear();
                    setTimeout(() => navigate('/'), 0);
                }
            })
        )
    }, [t, bookingContext.passengers.adults, clear, navigate]);

    const renderErrorModal = useCallback((title: string, content: string) => (
        Modal.error({ title, content })
    ), []);

    const onChangeConditions = useCallback((event: CheckboxChangeEvent) => {
        setConditionsChecked(event.target.checked);
        if (event.target.checked) {
            setTimeout(() => {
                const pay = document.getElementById(IDS.payment)
                pay && pay.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
            }, 100)
        }
    }, []);

    const outboundRoutes = useMemo(() => {
        return (bookingContext.flight?.route || []).map((route: Route) => ({
            airline: route?.airline || '',
            flight_number: route.flight_number || '',
            flight_departure_time: route?.flight_departure_time || '',
            flight_arrival_time: route?.flight_arrival_time || '',
            flight_date: bookingContext.date?.format('YYYY-MM-DD') || '',
            flight_origin: route?.origin || '',
            flight_destination: route?.destination || ''
        }))
    }, [bookingContext]);

    const inboundRoutes = useMemo(() => {
        return (bookingBackContext.flight?.route || []).map((route: Route) => ({
            airline: route?.airline || '',
            flight_number: route.flight_number || '',
            flight_departure_time: route?.flight_departure_time || '',
            flight_arrival_time: route?.flight_arrival_time || '',
            flight_date: bookingBackContext.date?.format('YYYY-MM-DD') || '',
            flight_origin: route?.origin || '',
            flight_destination: route?.destination || ''
        }))
    }, [bookingBackContext]);

    const handleCreateBooking = useCallback(() => {
        const data: Ticket[] = travelDetails.map((item: Pax) => ({
            document_nationality: item.document_nationality || '',
            document_issued_at: item.document_issued_at || '',
            document_expiry_date: item.document_expiry_date || '',
            document_number: item.document_number || '',
            document_type: item.document_type || '',
            zip: item.zip || '',
            city: item.city || '',
            address: item.address || '',
            sex: item.sex || '',
            dob: item.dob || '',
            ln: item.ln || '',
            fn: `${item.fn} ${item.mn ? item.mn : ''}`.trim(),
            seat_number: item.seat_number || '',
            service_class: SeatClass[item.flyClass as 'ECONOMY' | 'BUSINESS'] || 'standard',
            type: item.type,
        }));

        setLoading(true);
        bookingContext.createBooking({
            routes: outboundRoutes.concat(inboundRoutes),
            language: lang.toLocaleUpperCase() as "DE" | "EN",
            tickets: data,
            phone: bookingContext.passengers.adults[0].phone || '',
            email: bookingContext.passengers.adults[0].email || '',
            destination_airport: bookingContext.arrival?.iata ?? ''
        })
            .then((resp) => {
                setLoading(false);
                renderSuccessModal(resp.pnr);
            })
            .catch(err => {
                console.log('[PaymentScreen] createBooking', err);
                setLoading(false);
                renderErrorModal(t('error'), t([err.message, 'error_oops']));
            })
    }, [bookingContext, inboundRoutes, lang, outboundRoutes, renderErrorModal, renderSuccessModal, t, travelDetails]);

    const handleBackToPassengers = useCallback(() => {
        navigate('/passenger-data')
    }, []);//eslint-disable-line

    const isOneWay = useMemo(() => {
        return bookingContext.isOneWay;
    }, [bookingContext]);

    const outbound = useMemo(() => {
        return {
            routes: bookingContext.flight?.route as Route[],
            originCountryName: bookingContext.departure?.country,
            destinationCountryName: bookingContext.arrival?.country,
            date: bookingContext.date
        }
    }, [bookingContext]);

    const inbound = useMemo(() => {
        return {
            routes: bookingBackContext.flight?.route as Route[],
            originCountryName: bookingBackContext.departure?.country,
            destinationCountryName: bookingBackContext.arrival?.country,
            date: bookingBackContext.date
        }
    }, [bookingContext]);

    return (
        <div className='PaymentScreen'>
            <Flex vertical>
                {
                    !isOneWay ? (
                        <FlightInformationTwoDirect
                            outbound={outbound}
                            inbound={inbound}
                            passengers={bookingContext.passengers}
                            counts={{ adults: paxCount.adults, childs: paxCount.childs }}
                            mode="white"
                        />
                    ) : (
                        <FlightInformation
                            {...outbound}
                            counts={{ adults: paxCount.adults, childs: paxCount.childs }}
                            mode="white"
                        />
                    )
                }
                <OutboundInfo
                    flight={bookingContext.flight as FlightV2}
                    date={bookingContext.date}
                    counts={{ adults: paxCount.adults, childs: paxCount.childs }}
                    passengers={bookingContext.passengers}
                    mode="white"
                />

                {
                    !isOneWay ? (
                        <OutboundInfo
                            flight={bookingBackContext.flight as FlightV2}
                            date={bookingBackContext.date}
                            counts={{ adults: paxCount.adults, childs: paxCount.childs }}
                            passengers={bookingBackContext.passengers}
                            mode="white"
                            type={'inbound'}
                        />
                    ) : null
                }
                <OptionsInfo
                    flight={bookingContext.flight as FlightV2}
                    passengers={bookingContext.passengers}
                />

                {
                    !isOneWay ? (
                        <OptionsInfo
                            flight={bookingBackContext.flight as FlightV2}
                            passengers={bookingBackContext.passengers}
                            type={'inbound'}
                        />
                    ) : null
                }
            </Flex>
            <Flex vertical style={{ marginBottom: 20 }}>
                <Flex justify='space-between' align='center'>
                    <Typography.Title level={5}
                        style={{ marginBottom: 0 }}>{t('PassengerScreen.passengers')}</Typography.Title>
                    <Button onClick={handleBackToPassengers} type='link'>{
                        <CaretRightOutlined />}{t('changePassengerData')}</Button>
                </Flex>
                <PassengersInfo
                    passengers={travelDetails}
                />
            </Flex>
            <Typography.Title level={1}>{t('PaymentScreen.yourTrip')}</Typography.Title>
            <Flex align='flex-start' gap={5} style={{ marginBottom: 10 }}>
                <CheckCircleFilled style={{ color: 'green', fontSize: 20, marginTop: 2 }} />
                <Flex vertical align='flex-start'>
                    <Typography.Text style={{
                        fontSize: 16,
                        fontWeight: 'bold'
                    }}>{t('PaymentScreen.passengersCount', { count: passengerCount })}</Typography.Text>
                    <Typography.Text
                        style={{ fontSize: 16 }}>{`${t('PaymentScreen.travelling')} ${travelling}`}</Typography.Text>
                </Flex>
            </Flex>
            <Flex align='flex-start' gap={5} style={{ marginBottom: 20 }}>
                <CheckCircleFilled style={{ color: 'green', fontSize: 20, marginTop: 2 }} />
                <Flex vertical align='flex-start'>
                    <Typography.Text style={{ fontSize: 16, fontWeight: 'bold' }}>
                        {
                            t(
                                'PaymentScreen.flightDescription',
                                {
                                    date: dayjs(bookingContext.date).format('dddd, MMMM D, YYYY'),
                                    from: bookingContext.departure?.name,
                                    to: bookingContext.arrival?.name
                                }
                            )
                        }
                    </Typography.Text>
                </Flex>
            </Flex>
            <Typography.Title level={1}>{t('PaymentScreen.payment_billing_details')}</Typography.Title>
            <Flex vertical style={{ background: 'var(--gray-background)', padding: 15, fontSize: 16 }}>
                <Typography.Paragraph style={{ fontWeight: 'bold', fontSize: 16 }}>
                    {t('PaymentScreen.purchase_terms_agreement')}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ fontSize: 16 }}>
                    {t('PaymentScreen.refund_requests_currency')}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ fontSize: 16 }}>
                    {t('PaymentScreen.dangerous_goods_info')}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ fontSize: 16 }}>
                    {t('PaymentScreen.flammable_materials')}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ fontSize: 16 }}>
                    {t('PaymentScreen.gas_containers')}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ fontSize: 16 }}>
                    {t('PaymentScreen.other_dangerous_goods')}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ fontSize: 16 }}>
                    {t('PaymentScreen.ticket_order_confirmation')}
                </Typography.Paragraph>
                <Checkbox onChange={onChangeConditions}>
                    <Typography style={{ fontWeight: 'bold', fontSize: 16 }}>{t('PaymentScreen.conditions')}</Typography>
                </Checkbox>
            </Flex>
            {
                conditionsChecked &&
                <Portal id={IDS.payment}>
                    <Flex
                        justify='flex-end'
                        align='center'
                        style={{
                            width: '100%',
                            background: token.colorPrimary,
                            minHeight: 70,
                            padding: '0 20px'
                        }}
                    >
                        <Button
                            size='large'
                            onClick={handleCreateBooking}
                        >
                            {t('PaymentScreen.clickToPay')}
                        </Button>
                    </Flex>
                </Portal>
            }
            {
                loading &&
                <Portal id={IDS.loader}>
                    <Loader />
                </Portal>
            }
        </div>
    )
}
