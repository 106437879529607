import React from 'react';
import { Currency, CURRENCY_KEY } from 'utils/currency';
import { setValueToStorage, getValueFromStorage } from 'utils/storage';

export interface WalletContext {
    currency: Currency;
    setCurrency: (value: Currency) => void;
}

export const INIT_WALLET_CONTEXT: WalletContext = {
    currency: Currency.chf,
    setCurrency() {},
};

export function useWalletContextProvider(): WalletContext {
    const [currency, setCurrency] = React.useState<Currency>(Currency.chf);

    React.useEffect(() => {
        const value = getValueFromStorage(CURRENCY_KEY);
        if (value !== null) {
            setCurrency(parseInt(value));
        }
    }, []);

    const handleSetCurrency = React.useCallback((value: Currency) => {
        setValueToStorage(CURRENCY_KEY, value.toString());
        setCurrency(value);
    }, []);

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return React.useMemo(() => ({
        currency,
        setCurrency: handleSetCurrency
    }), [
        currency,
        handleSetCurrency
    ]);
}
