import React, {useMemo} from 'react'
import {Flex, Typography} from 'antd';
import {AppContext, AppContextType} from 'AppContext';
import {useTranslation} from 'react-i18next';
import {useContextSelector} from 'use-context-selector';
import OutboundInfo from 'components/OutboundInfo';
import {FlightV2, Route} from 'utils/flight';
import OptionsInfo from 'components/OptionsInfo';
import FlightInformationTwoDirect from "components/FlightInfrormationTwoDirect";
import FlightInformation from "../../../components/FlightInfrormation";
import usePaxCount from "../../../hooks/usePaxCount";


const {Title, Text} = Typography;

const Header = () => {
    const {
        date,
        counts,
        flight,
        passengers
    } = useContextSelector(AppContext, (c: AppContextType) => c.booking);
    const paxCount = usePaxCount();
    const {t} = useTranslation();

    const bookingContext = useContextSelector(AppContext, (c: AppContextType) => c.booking);
    const bookingBackContext = useContextSelector(AppContext, (c: AppContextType) => c.bookingBackFlight);

    const outbound = useMemo(() => {
        return {
            routes: bookingContext.flight?.route as Route[],
            originCountryName: bookingContext.departure?.country,
            destinationCountryName: bookingContext.arrival?.country,
            date: bookingContext.date
        }
    }, [bookingContext]);

    const inbound = useMemo(() => {
        return {
            routes: bookingBackContext.flight?.route as Route[],
            originCountryName: bookingBackContext.departure?.country,
            destinationCountryName: bookingBackContext.arrival?.country,
            date: bookingBackContext.date
        }
    }, [bookingContext]);

    return (
        <div>
            <Flex vertical>
                {
                    bookingContext.isOneWay ? (
                        <FlightInformation
                            {...outbound}
                            counts={{adults: paxCount.adults, childs: paxCount.childs}}
                            mode="white"
                        />
                    ) : (
                        <FlightInformationTwoDirect
                            outbound={outbound}
                            inbound={inbound}
                            passengers={bookingContext.passengers}
                            counts={counts}
                            mode="white"
                        />
                    )
                }

                <OutboundInfo
                    flight={flight as FlightV2}
                    date={date}
                    counts={{
                        adults: counts.adults,
                        childs: counts.childs
                    }}
                    passengers={passengers}
                    mode='white'
                    type={'outbound'}
                />

                {
                    !bookingContext.isOneWay ? (
                            <OutboundInfo
                                flight={bookingBackContext.flight as FlightV2}
                                date={bookingBackContext.date}
                                counts={{
                                    adults: counts.adults,
                                    childs: counts.childs
                                }}
                                passengers={bookingBackContext.passengers}
                                mode='white'
                                type={'inbound'}
                            />
                        )
                        : null
                }

                <OptionsInfo
                    flight={flight as FlightV2}
                    passengers={passengers}
                />

                {
                    !bookingContext.isOneWay ? (
                        <OptionsInfo
                            flight={bookingBackContext.flight as FlightV2}
                            passengers={bookingBackContext.passengers}
                            type={'inbound'}
                        />
                    ) : null
                }
            </Flex>
            <Title level={3}>{t('PassengerScreen.passengers')}</Title>
            <Text style={{whiteSpace: 'pre-line', fontSize: 16}}>
                {t('PassengerScreen.description')}
            </Text>
        </div>
    )
}

export default Header
